import React from 'react';

import { get } from 'lodash-es';
import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';
import * as FormatUtils from '@asteria/utils-funcs/format';

import {
	useAccuracy,
	usePerformance,
	useTrendsDirection,
} from '../../../hooks';
import { getDataForm, getDiffValue, getPath } from '../../../utils';

import Accuracy from './accuracy';
import Category from './category';
import Performance from './performance';
import Status from './status';
import Total from './total';

/**
 * @typedef { import('../../../types').ChildrenOptions } ChildrenOptions
 * @typedef { import('../../../types').Props } Props
 */

/** @type { React.FC<Pick<ChildrenOptions, 'translationOptions' | 'onAction' | 'onSubmit'> & { type: 'deposit' | 'withdraw', category: string, tag: string }> } */
const Row = React.memo(function Row(props) {
	const {
		type,
		category,
		tag,
		translationOptions: $translationOptions,
		onAction,
	} = props;

	const path = getPath({ type, category, tag });

	const source = get($translationOptions?.data?.source, path);
	const target = get($translationOptions?.data?.target, path);

	const trends = useTrendsDirection(target?.total, source?.total);

	const accuracy = useAccuracy(source, target);
	const performance = usePerformance(source, target);

	const translationOptions = React.useMemo(() => {
		const data = $translationOptions?.data;

		return {
			...$translationOptions,

			postfix: {
				...$translationOptions?.postfix,

				section: 'details',
				'section-type': type,
				'section-category': category,
				'section-tag': tag,

				performance: performance.state,

				trends: trends,
			},

			data: {
				...data,

				category: FormatUtils.formatTag({ type, category, tag }),

				source: getDataForm(data?.source, { type, category, tag }),
				target: getDataForm(data?.target, { type, category, tag }),

				diff: {
					...data?.diff,
					value: getDiffValue({
						sourceTotal: source?.total ?? 0,
						targetTotal: target?.total ?? 0,
					}),
				},
				accuracy: accuracy,
				performance: performance,
			},
		};
	}, [
		$translationOptions,
		accuracy,
		category,
		performance,
		source?.total,
		tag,
		target?.total,
		trends,
		type,
	]);

	return (
		<div
			className={cn('asteria-component__card-section-list-row', {
				[`asteria--type-${type}`]: type,
			})}
		>
			<Category
				type={type}
				category={category}
				tag={tag}
				translationOptions={translationOptions}
				onAction={onAction}
			/>
			<Performance translationOptions={translationOptions} />
			<Status translationOptions={translationOptions} />
			<Accuracy translationOptions={translationOptions} />
			<Total translationOptions={translationOptions} />
		</div>
	);
});

Row.propTypes = {
	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	translationOptions: PropTypes.object,
};

export default Row;
