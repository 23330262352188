import { useQuery } from '@tanstack/react-query';
import { formatISO, subMonths } from 'date-fns';

import { parseDate } from '@asteria/utils-funcs/normalize';

export function useRequest({
	onSubmit,
	startDate,
	endDate,
	version,
	clientType,
}) {
	let key = `client-performance`;

	if (!version || version < 2) {
		key = `${clientType}-performance`;
	}

	return useQuery({
		queryKey: ['card', key, { startDate, endDate }],
		queryFn: async () => {
			return await onSubmit?.('card:fetch', {
				type: key,
				source: {
					startDate: startDate,
					endDate: endDate,
				},
				target: {
					startDate: formatISO(subMonths(parseDate(startDate), 1), {
						representation: 'date',
					}),
					endDate: formatISO(subMonths(parseDate(endDate), 1), {
						representation: 'date',
					}),
				},
			});
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,
	});
}

export function useType(props) {
	const { version, clientType } = props;

	if (version && version >= 2) {
		return 'client-performance';
	}

	return `${clientType}-performance`;
}
