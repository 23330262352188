import React from 'react';

import PropTypes from 'prop-types';

import { getVersion } from '../../utils';
import withConfig from '../../withConfig';

import DefaultView, { ViewContent as DefaultViewContent } from './default';
import FeedbackView, { ViewContent as FeedbackViewContent } from './feedback';
import PromotionView, {
	ViewContent as PromotionViewContent,
} from './promotion';

/**
 * @typedef { import('../../types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const View = React.memo(function Footer(props) {
	const { state } = props;

	if (state?.feedback?.visible) {
		return <FeedbackView {...props} />;
	}

	if (state?.promotion?.visible || state?.promotion?.variant === 'footer') {
		return <PromotionView {...props} />;
	}

	return <DefaultView {...props} />;
});

View.propTypes = { state: PropTypes.object };

export default withConfig(View, (props) => {
	const { version } = props;

	if (props?.state?.card?.footer === false) {
		return false;
	}

	return getVersion(props?.config?.card?.footer, version);
});

export { DefaultViewContent, FeedbackViewContent, PromotionViewContent };
