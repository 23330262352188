import { TranslationService } from '@asteria/language';

export function getKeyPermutations(key = '', delimiter = '.', initial = []) {
	return key
		.toString()
		.split('.')
		.reduce(
			(acc, item) => [
				...acc,
				acc.length > 0
					? [acc[acc.length - 1], item.toLowerCase()].join(delimiter)
					: item,
			],
			initial,
		);
}

export function getName(name, translationOptions) {
	return TranslationService.getV2(
		[
			...getKeyPermutations(`withdraw.${name}`, '.', [
				'cashflow.tooltip',
			]).map((key) => `${key}.label`),
		],
		{
			...translationOptions,
		},
	);
}
