import React from 'react';

import ComponentService from '@asteria/component-tools/contenter/service';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Button from '../button';
import Icon from '../icon';
import Skeleton from '../skeleton';
import { positionClasses } from '../utils';
import * as SizeUtils from '../utils/size';

import {
	TextPropTypes,
	TitlePropTypes,
	TypographyPropTypes,
} from './PropTypes';

import './index.scss';

const TypographySizes = {
	'page-title': 'h1',

	'title-1': 'h2',
	xl: 'h2',
	'x-large': 'h2',
	xlarge: 'h2',

	'title-2': 'h3',
	lg: 'h3',
	large: 'h3',

	'title-3': 'h4',
	base: 'h4',

	'title-4': 'h5',
	sm: 'h5',
	small: 'h5',

	'title-5': 'h6',
	xs: 'h6',
	'x-small': 'h6',
	xsmall: 'h6',

	default: 'p',
	xxs: 'p',
	'xx-small': 'p',
	xxsmall: 'p',
};

/**
 * @type { React.ForwardRefExoticComponent<Partial<typeof TypographyPropTypes & { ref: React.RefObject }>> }
 */
const Typography = React.forwardRef(function Typography(
	{
		children,
		className,
		type = 'text',
		weight,
		align,
		size: $size,
		separator,
		...otherProps
	},
	ref,
) {
	const Component =
		TypographySizes[type === 'title' ? $size : 'default'] ?? 'p';

	const size = SizeUtils.normalize($size || 'p');

	const el = (
		<Component
			className={cn(
				className,
				`asteria-component__typography`,
				`asteria-component__${type}`,
				`asteria-component__${type}--size-${size || 'p'}`,
				{ 'asteria--type-separator': separator },
				{
					[`asteria-component__typography--weight-${weight}`]: weight,
					[`asteria-component__typography--align-${align}`]: align,
					[`asteria-component__${type}--weight-${weight}`]: weight,
					[`asteria-component__${type}--align-${align}`]: align,
				},
			)}
			{...otherProps}
			ref={ref}
		>
			{children}
		</Component>
	);

	return el;
});

Typography.propTypes = TypographyPropTypes;

Typography.defaltProps = {
	size: 'default',
	type: 'text',
	weight: null,
};

/**
 * @type { React.ForwardRefExoticComponent<Partial<typeof TitlePropTypes & { ref: React.RefObject }>> }
 */
const Title = React.forwardRef(function Title(props, ref) {
	const { loading, skeleton, ...rest } = props;

	if (loading) {
		return (
			<Skeleton
				className="asteria--type-title"
				count={loading}
				{...skeleton}
			/>
		);
	}

	return <Typography type="title" {...rest} ref={ref} />;
});

Title.displayName = 'Title';

Title.propTypes = TitlePropTypes;

/**
 * @type { React.ForwardRefExoticComponent<Partial<typeof TextPropTypes & { ref: React.RefObject }>> }
 */
const Text = React.forwardRef(function Text(props, ref) {
	const { loading, skeleton, ...rest } = props;

	if (loading) {
		return (
			<Skeleton
				className="asteria--type-text"
				count={loading}
				{...skeleton}
			/>
		);
	}

	return <Typography type="text" {...rest} ref={ref} />;
});

Text.displayName = 'Text';

Text.propTypes = TextPropTypes;

const TextGroup = React.forwardRef(function Text(props, ref) {
	const { children, className, ...groupProps } = props;
	return (
		<div
			ref={ref}
			className={cn('asteria-component__typography-group', className)}
			{...groupProps}
		>
			{children}
		</div>
	);
});

const UnorderedList = (props) => {
	const { items = [], className, data, onItemCopy } = props;

	const onCopy = React.useCallback(
		({ key, index }) => {
			return () => {
				const value = TranslationService.get(key, key, data);

				navigator.clipboard.writeText(value);

				onItemCopy?.({
					key: key,
					value: value,
					index: index,
					data: data,
				});
			};
		},
		[data, onItemCopy],
	);

	return (
		<ul className={cn('asteria-component__unordered', className)}>
			{items
				.filter(
					(item) =>
						!item?.skipEmpty ||
						TranslationService.getV2(item?.value, {
							default: item?.value,
							data: data,
							...item?.translationOptions,
						}),
				)
				.map((item, i) => (
					<li
						key={i}
						className={cn(
							'asteria-component__unordered__item',
							{
								[`asteria-component__unordered__item--icon`]:
									item?.icon,
								[`asteria-component__unordered__item--grouped`]:
									item?.group,
								[`asteria-component__unordered__item--copy`]:
									item?.copy,
								[`asteria-component__unordered__item--href`]:
									item?.href,
							},
							className,
							item?.className,
						)}
					>
						{item?.icon && !item.copy ? (
							<Icon
								className={cn(
									positionClasses({
										position: item?.iconPosition,
									}),
								)}
								size={item?.iconSize}
								icon={item?.icon}
							/>
						) : null}
						{item?.href && (
							<Button
								label={TranslationService.get(
									item?.value,
									item?.value,
									data,
								)}
								size={item?.size}
								variant="link"
								href={TranslationService.get(
									item?.href,
									item?.href,
									data,
								)}
								target="_blank"
							/>
						)}
						{item?.copy && (
							<Button
								icon={item?.icon}
								label={TranslationService.get(
									item?.value,
									item?.value,
									data,
								)}
								size={item?.size}
								variant="link"
								onClick={onCopy({ key: item?.value, index: i })}
							/>
						)}
						{item.value && !item.href && !item.copy && (
							<Translation
								translationKey={item?.value}
								defaultText={item?.value}
								data={data}
								translationOptions={item?.translationOptions}
								Component={Typography}
								type={item?.type}
								size={item?.size}
							/>
						)}

						{item.grouped &&
							item.grouped.map((grouped, i) => (
								<TextGroup key={i}>
									{grouped.group.map((group, i) => (
										<Translation
											key={i}
											translationKey={group?.value}
											defaultText={group?.value}
											data={data}
											translationOptions={
												group?.translationOptions
											}
											Component={Typography}
											type={group?.type}
											size={group?.size}
										/>
									))}
								</TextGroup>
							))}
					</li>
				))}
		</ul>
	);
};

ComponentService.register('Title', Title, {
	className: { type: 'string' },
	children: { type: 'node' },
	align: { type: 'enum', options: ['left', 'center', 'right', 'justify'] },
	size: {
		type: 'enum',
		options: ['page-title', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs'],
	},
});

ComponentService.register('Text', Text, {
	className: { type: 'string' },
	children: { type: 'node' },
	weight: {
		type: 'enum',
		options: [
			'thin',
			'extralight',
			'light',
			'normal',
			'regular',
			'medium',
			'semibold',
			'bold',
			'black',
		],
	},
	align: { type: 'enum', options: ['left', 'center', 'right', 'justify'] },
	size: { type: 'enum', options: ['xl', 'lg', 'md', 'sm', 'xs', 'xxs'] },
});

ComponentService.register('TextGroup', TextGroup, {
	className: { type: 'string' },
	children: { type: 'node' },
});

ComponentService.register('UnorderedList', UnorderedList, {
	items: {
		type: 'array',
		of: {
			type: 'object',
			options: {
				icon: { type: 'string' },
				group: { type: 'string' },
				copy: { type: 'boolean' },
				href: { type: 'string' },
				className: { type: 'string' },

				iconPosition: { type: 'string' },
				iconSize: { type: 'string' },
				value: { type: 'string' },
				size: { type: 'string' },
				type: { type: 'string' },
				grouped: {
					type: 'array',
					of: {
						type: 'object',
						options: {
							group: {
								type: 'array',
								of: {
									type: 'object',
									options: {
										type: { type: 'string' },
										size: { type: 'string' },
										value: { type: 'string' },
									},
								},
							},
						},
					},
				},
			},
		},
	},
	className: { type: 'string' },
	data: { type: 'object' },
	onItemCopy: { type: 'function' },
});

const TitleMemo = React.memo(Title);
const TextMemo = React.memo(Text);
const TextGroupMemo = React.memo(TextGroup);
const UnorderedListMemo = React.memo(UnorderedList);

export default Typography;
export {
	TitleMemo as Title,
	TextMemo as Text,
	TextGroupMemo as TextGroup,
	UnorderedListMemo as UnorderedList,
};
