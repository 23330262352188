import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { Title } from '@asteria/component-core/typography';

import * as AppStore from '@asteria/datalayer/stores/app';

import { getAbbreviation } from './utils';

import './styles.scss';

const AbbreviationLogo = React.memo(function AbbreviationLogo() {
	const user = useSelector(AppStore.selectors.user, (a, b) => isEqual(a, b));

	const username = React.useMemo(() => {
		return (
			[user?.firstName, user?.lastName].filter(Boolean).join(' ') ||
			user?.username
		);
	}, [user?.firstName, user?.lastName, user?.username]);

	if (!username) {
		return null;
	}

	return (
		<div className="w-8 h-8 m-2 bg-chip-focus-background rounded-md flex items-center justify-center">
			<Title size="xxs">{getAbbreviation(username)}</Title>
		</div>
	);
});

/** @type { React.FC<{ src: string }> } */
const Logo = React.memo(function Logo({ src }) {
	const style = React.useMemo(
		() => ({ backgroundImage: `url(${src})` }),
		[src],
	);

	if (!src) {
		return <AbbreviationLogo />;
	}

	return (
		<div
			style={style}
			className="w-8 m-2 aspect-square bg-contain bg-no-repeat rounded-md"
		/>
	);
});

Logo.propTypes = { src: PropTypes.string };

export default Logo;
