import React from 'react';

import BasicCard from '../basic';

import { useRequest } from './hooks';
import AccountCardContent from './v2/content';
import Loading from './v2/loading';
import AccountCardPromotion from './v2/promotion';

import './styles.scss';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { Props['config'] } */
const CONFIG = {
	onboarding: { required: 'bank', optional: 'erp', loading: 'bank' },
	card: {
		header: {
			trends: { v1: true },
		},
		content: {
			default: {
				view: { v2: (props) => <AccountCardContent {...props} /> },
			},
			promotion: {
				view: { v2: (props) => <AccountCardPromotion {...props} /> },
			},
			importing: {
				view: { v2: (props) => <AccountCardPromotion {...props} /> },
			},
			loading: { v2: (props) => <Loading {...props} /> },
			title: { trends: { v2: true } },
		},
		footer: {
			connect: {
				button: {
					connect: { required: 'bank' },
					postfix: { required: { onboarding: 'erp' } },
				},
			},
		},
	},
};

/** @type { React.FC<Props> } */
const AccountCard = React.memo(function AccountCard(props) {
	const query = useRequest(props);

	return (
		<BasicCard {...props} type="account" query={query} config={CONFIG} />
	);
});

export default AccountCard;
