export const FEEDBACK_SET = 'FEEDBACK_SET';
export const FEEDBACK_BACK = 'FEEDBACK_BACK';
export const FEEDBACK_CLOSE = 'FEEDBACK_CLOSE';
export const FEEDBACK_NEXT = 'FEEDBACK_NEXT';
export const FEEDBACK_RESET = 'FEEDBACK_RESET';

export const PROMOTION_SET = 'PROMOTION_SET';
export const PROMOTION_RESET = 'PROMOTION_RESET';

export const CARD_SET = 'CARD_SET';
export const CARD_RESET = 'CARD_RESET';
