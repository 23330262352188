import React from 'react';

import PropTypes from 'prop-types';

import Wrapper, { Content, Header } from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';

import { TranslationService } from '@asteria/language';

import Popup from './view';

const PopupModal = function PopupModal(props) {
	const { open, onClose } = props;

	return (
		<Modal open={open} onClose={onClose} size="lg">
			<Wrapper>
				<Header onClose={onClose}>
					{TranslationService.getV2('time.selector.modal.title')}
				</Header>
				<Content scroll>
					<Popup {...props} />
				</Content>
			</Wrapper>
		</Modal>
	);
};

PopupModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,

	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default PopupModal;
