import React from 'react';

export function useHealthState(query) {
	const score = query?.data?.source?.score ?? null;

	const health = React.useMemo(() => {
		if (score === null) {
			return 'unknown';
		}

		if (score > 80) {
			return 'perfect';
		}

		if (score > 60) {
			return 'great';
		}

		if (score > 40) {
			return 'good';
		}

		if (score > 20) {
			return 'acceptable';
		}

		return 'bad';
	}, [score]);

	return health;
}

export const useRadialBarData = (query) => {
	const score = query?.data?.source?.score ?? 0;

	const graphData = React.useMemo(
		() => [
			{
				value: score,
				fill: 'var(--color-primary)',
			},
		],
		[score],
	);

	return graphData;
};
