import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';

import * as AppStore from '@asteria/datalayer/stores/app';

export function useName() {
	const user = useSelector(AppStore.selectors.user, (a, b) => isEqual(a, b));
	const company = useSelector(AppStore.selectors.company, (a, b) =>
		isEqual(a, b),
	);

	const username = useMemo(() => {
		return [user?.firstName, user?.lastName].filter(Boolean).join(' ');
	}, [user?.firstName, user?.lastName]);

	const companyName = useMemo(() => {
		return company?.name;
	}, [company?.name]);

	if (username) {
		return username;
	}

	if (companyName) {
		return companyName;
	}

	return null;
}
