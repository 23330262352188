import React from 'react';

import PropTypes from 'prop-types';

import Icon from '@asteria/component-core/icon';
import { TooltipWrapper } from '@asteria/component-core/tooltip';

import { TranslationService } from '@asteria/language';

/**
 * @typedef Props
 * @property { 'up' | 'down' } direction
 * @property { 'sm' | 'md' | 'lg' } size
 * @property { unknown } translationOptions
 */

const ICON_DIRECTION = {
	up: 'arrow-up-right',
	down: 'arrow-down-right',
	equal: 'arrow-straight',
};

/** @type { React.FC<Props> } */
const Trends = React.memo(function Trends(props) {
	const { direction, size, translationOptions } = props;

	return (
		<TooltipWrapper
			tooltip={TranslationService.getV2(
				['card.trends.tooltip'],
				translationOptions,
			)}
		>
			<Icon
				icon={ICON_DIRECTION[direction] ?? ICON_DIRECTION.equal}
				size={size}
			/>
		</TooltipWrapper>
	);
});

Trends.displayName = 'Trends';

Trends.propTypes = {
	direction: PropTypes.oneOf(['up', 'down']),
	size: PropTypes.string,
	translationOptions: PropTypes.object,
};

export default Trends;
