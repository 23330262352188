import {
	eachMonthOfInterval,
	endOfMonth,
	formatISO,
	isBefore,
	isSameMonth,
	startOfMonth,
} from 'date-fns';

import { TransactionService } from '@asteria/backend-utils-services';

export async function apply(options) {
	const { input = [], accessToken = null } = options;

	return TransactionService.transaction.applyForecastChanges(
		{ input: input },
		{ token: accessToken },
	);
}

function fmt(date) {
	return formatISO(date, { representation: 'date' });
}

export async function fetch(options) {
	const { accessToken, scenarioName, date, dataloader } = options;

	const data = await TransactionService.transaction
		.forecastChanges(
			{
				input: { scenarioName: scenarioName, date: date },
				fields: `
					tags {
						withdraw {
							tag {
								_id
								name
								category {
									_id
									name
								}
							}
							weight
							alert
						}
						deposit {
							tag {
								_id
								name
								category {
									_id
									name
								}
							}
							weight
							alert
						}
					}
					nodes {
						date
						types {
							type
							total
							probability
							currency
							categories {
								_id
								details {
									name
									color
								}
								total
								probability
								currency
								tags {
									_id
									details {
										name
										color
									}
									total
									probability
									currency
									transactions {
										_id
										total
										probability
										paymentDate
										currency
										description
										links {
											type
											id
											reference
											invoiceDetails: details {
												... on Invoice {
													clientId
												}
											}
										}
									}
								}
							}
						}
					}
				`,
				dataloader: dataloader,
			},
			{ token: accessToken },
		)
		.catch(() => []);

	return {
		entries:
			data?.nodes?.map?.((object) => ({
				...object,
				status: 'FORECAST',
			})) || [],
		tags: data?.tags,
	};
}

async function fetchHistoricalTransactions(options) {
	const {
		startDate: $startDate,
		endDate: $endDate,
		accessToken,
		status = [],
		dataloader,
	} = options;

	const startDate = startOfMonth(new Date($startDate));
	const endDate = endOfMonth(new Date($endDate));

	const range = eachMonthOfInterval({ start: startDate, end: endDate });

	const data = await TransactionService.transaction
		.history(
			{
				input: range.map((date) => {
					const selector = fmt(date);

					return {
						date: selector,
						status: ['PAID'].concat(status),
					};
				}),
				fields: `
					date
					types {
						type
						total
						currency
						statuses {
							status
							total
							currency

							categories {
								_id
								details {
									name
									color
								}
								total
								currency
								tags {
									_id
									details {
										name
										color
									}
									total
									currency
								}
							}
						}
					}
				`,
				dataloader: dataloader,
			},
			{ token: accessToken },
		)
		.catch(() => []);

	return Object.entries(
		data
			.flatMap(({ date, types }) =>
				types.flatMap(({ type, statuses }) =>
					statuses.map((object) => ({
						...object,
						type: type,
						date: date,
					})),
				),
			)
			.reduce((acc, object) => {
				const { date, status, type } = object;

				if (acc[date] === undefined) {
					acc[date] = {};
				}

				if (acc[date][status] === undefined) {
					acc[date][status] = {};
				}

				acc[date][status][type] = object;

				return acc;
			}, {}),
	).flatMap(([date, statuses]) =>
		Object.entries(statuses).flatMap(([status, types]) => ({
			date: date,
			status: status,
			types: Object.values(types),
		})),
	);
}

export async function fetchHistory(options) {
	const {
		startDate: $startDate,
		endDate: $endDate,
		status,
		accessToken,
		dataloader,
	} = options;

	const startDate = startOfMonth(new Date($startDate));
	const endDate = endOfMonth(new Date($endDate));

	const range = eachMonthOfInterval({ start: startDate, end: endDate });

	const { past, current } = range.reduce(
		(acc, date) => {
			if (isSameMonth(date, new Date())) {
				acc.current = date;
			} else if (isBefore(date, new Date())) {
				acc.past.push(date);
			} else {
				acc.future.push(date);
			}

			return acc;
		},
		{ past: [], current: null, future: [] },
	);

	let forecastChanges = [],
		cashflow = [];

	if (past.length || current) {
		cashflow = await fetchHistoricalTransactions({
			accessToken: accessToken,
			startDate: past[0] ?? current,
			status: status,
			endDate: endDate,
			dataloader: dataloader,
		});
	}

	return { entries: cashflow.concat(forecastChanges) };
}
