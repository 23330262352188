import AsteriaCore from '@asteria/core';

import { GraphQL } from '@asteria/backend-utils-services';

const Dataloaders = new Map();

const defaultCallback = async (...options) => {
	const requests = GraphQL.merge(options);

	let responses = [];

	for (const { data = [], ...request } of requests) {
		const keys = data.flatMap(({ alias }) => alias);

		const response = await AsteriaCore.HTTP.graphql({
			...request,
			signal: request.signal ?? options?.[0]?.signal,
			isBulk: false,
		})
			.then((response) => GraphQL.parse(response, keys))
			.catch((err) => {
				if (err?.data) {
					return GraphQL.parse(err?.data, keys);
				}

				throw err;
			});

		responses.push(response);
	}

	return responses.flat();
};

async function fetch(options) {
	const { uri } = options;

	let dataloader = Dataloaders.get(uri);

	if (!dataloader) {
		dataloader = new AsteriaCore.DataLoader(defaultCallback, { period: 0 });
		Dataloaders.set(uri, dataloader);
	}

	return dataloader.execute(options, { waiting: true }).then((data) => {
		Dataloaders.clear();
		return { response: data };
	});
}

export default fetch;
export { fetch, defaultCallback };
