import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import { Translation, TranslationService } from '@asteria/language';

/**
 * @typedef Props
 * @property { string } className
 * @property { unknown } data
 * @property { unknown } postfix
 * @property { boolean | { title: string, content: unknown[] } } modal
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 * @property { boolean } open
 * @property { (event: MouseEvent) => void } onClose
 */

/** @type { React.FC<Props> } */
const ModalContent = React.memo(function ModalContent(props) {
	const { onClose, data, postfix, modal, onAction } = props;

	const showChat = React.useCallback(
		() => onAction?.('support:chat:open'),
		[onAction],
	);

	return (
		<Wrapper scroll className="asteria-component__under-construction-modal">
			<Header onClose={onClose}>
				{TranslationService.getV2(['modal.card.title', modal?.title], {
					default: modal?.title,
					postfix: { variant: 'under-construction', ...postfix },
					data: data,
				})}
			</Header>
			<Content className="gap-4">
				{TranslationService.getV2(['modal.card.contenter'], {
					data: data,
					postfix: postfix,
				})}
				<div className="flex flex-col gap-2 border border-solid border-border-normal rounded-sm p-4">
					<Translation
						translationKey={
							'modal.card.under-construction.contact.text.0'
						}
						translationOptions={{ data: data, postfix: postfix }}
						Component={Text}
					/>
					<Button
						variant="href"
						size="sm"
						icon="triangle-right"
						href={TranslationService.getV2(
							'faq.contact.button.phone.href',
						)}
						label={TranslationService.getV2(
							'faq.contact.button.phone.label',
						)}
					/>
					<FeatureFlag feature="support-chat">
						<div>
							<Button
								variant="primary"
								label={TranslationService.getV2(
									'faq.contact.button.conversation.label',
								)}
								onClick={showChat}
							/>
						</div>
					</FeatureFlag>
					<Translation
						translationKey={
							'modal.card.under-construction.contact.text.1'
						}
						translationOptions={{ data: data, postfix: postfix }}
						Component={Text}
					/>
				</div>
			</Content>
			<Footer>
				<FooterSection>
					<Button
						variant="secondary"
						label={TranslationService.getV2(['modal.card.action'], {
							postfix: {
								variant: 'under-construction',
								action: 'close',
								...postfix,
							},
							data: data,
						})}
						onClick={onClose}
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

ModalContent.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	data: PropTypes.object,
	postfix: PropTypes.object,
	modal: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			title: PropTypes.string,
			content: PropTypes.arrayOf(PropTypes.object),
		}),
	]),
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

/** @type { React.FC<Props> } */
const UnderConstructionModal = React.memo(function UnderConstructionModal(
	props,
) {
	const { open, onClose } = props;

	return (
		<Modal open={open} onClose={onClose}>
			<ModalContent {...props} />
		</Modal>
	);
});

UnderConstructionModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	data: PropTypes.object,
	postfix: PropTypes.object,
	modal: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			title: PropTypes.string,
			content: PropTypes.arrayOf(PropTypes.object),
		}),
	]),
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default UnderConstructionModal;
