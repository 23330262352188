import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import Form from '@asteria/component-form';

import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import { Translation } from '@asteria/language';

import ForecastActionsGroup from '../components/actionsGroup';
import Help from '../components/help';
import { useForecasterFuture } from '../hooks';

import './styles.scss';

/**
 * @typedef ChildrenOptions
 * @property { boolean } loading
 * @property { 'none' | 'bank' | 'erp' | 'both' } onboarding
 * @property { import('@tanstack/react-query').UseQueryResult } query
 * @property { { postfix: unknown } } translationOptions
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 */

const FORM_RESET_OPTIONS = {
	keepDirtyValues: false,
	keepErrors: false,
	keepDirty: false,
	keepValues: false,
	keepDefaultValues: false,
	keepIsSubmitted: false,
	keepIsSubmitSuccessful: false,
	keepTouched: false,
	keepIsValid: false,
	keepSubmitCount: false,
};

/** @type { React.FC<ChildrenOptions> } */
const ForecastActionsContent = React.memo(function ForecastActionsContent({
	query,
	onboarding,
	translationOptions: $translationOptions,
	onAction,
	onSubmit,
}) {
	const forecasterQuery = useForecasterFuture({ onSubmit });

	const sourceScenarioData = useSelector(
		ScenarioStore.selectors.sourceScenarioData,
	);

	const translationOptions = React.useMemo(
		() => ({
			...$translationOptions,
			data: {
				...$translationOptions?.data,
				scenario: sourceScenarioData,
			},
		}),
		[$translationOptions, sourceScenarioData],
	);

	const processActions = React.useCallback((data = {}) => {
		if (!data?.actions) {
			return [];
		}

		return data?.actions;
	}, []);

	const actionsData = React.useMemo(() => {
		const processedActions = processActions(query?.data);

		const actions = processedActions.reduce((acc, action) => {
			const type = action?.extra?.type;
			if (!type) {
				return acc;
			}

			if (!acc[type]) {
				acc[type] = [];
			}

			acc[type].push(action);

			return acc;
		}, {});

		return actions;
	}, [processActions, query?.data]);

	const values = React.useMemo(
		() => (forecasterQuery.isFetching ? {} : forecasterQuery.data),
		[forecasterQuery.data, forecasterQuery.isFetching],
	);

	if (!actionsData?.deposit?.length && !actionsData?.withdraw?.length) {
		return null;
	}

	return (
		<Form
			className="flex flex-col gap-8"
			values={values}
			resetOptions={FORM_RESET_OPTIONS}
		>
			<ForecastActionsGroup
				key="deposit-actions"
				type="deposit"
				data={actionsData?.deposit}
				translationOptions={translationOptions}
				onAction={onAction}
			/>
			<ForecastActionsGroup
				key="withdraw-actions"
				type="withdraw"
				data={actionsData?.withdraw}
				translationOptions={translationOptions}
				onAction={onAction}
			/>
			{onboarding === 'both' ? (
				<Help translationOptions={translationOptions} />
			) : (
				<Translation
					translationOptions={translationOptions}
					translationKey="card.content.forecast-actions.footer"
					Component={Text}
				/>
			)}
		</Form>
	);
});

ForecastActionsContent.propTypes = {
	loading: PropTypes.bool,
	query: PropTypes.object,
	onboarding: PropTypes.oneOf('none', 'bank', 'erp', 'both'),
	translationOptions: PropTypes.object,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default ForecastActionsContent;
