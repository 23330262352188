import React from 'react';

import PropTypes from 'prop-types';

import Skeleton from '@asteria/component-core/skeleton';
import Table, {
	TableCell,
	TableHeader,
	TableRow,
} from '@asteria/component-core/table';
import { Text } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

/**
 * @typedef { import('../../../basic/types').ChildrenProps } ChildrenProps
 *
 * @typedef Props
 * @property { 'CUSTOMER' | 'SUPPLIER' } clientType
 */

/** @type { React.FC<{ children: React.ReactNode, loading: boolean, animated: boolean }> } */
const ClientTableView = React.memo(function ClientTableView(props) {
	const { children, loading, animated } = props;

	const skeleton = React.useMemo(() => ({ animated }), [animated]);

	return (
		<Table
			className={cn('asteria--type-client-details', {
				'asteria--state-loading': loading,
			})}
		>
			<TableHeader>
				<TableCell className="asteria--type-counter">
					<Translation
						defaultText="#"
						translationKey="card.content.client.table.header.label"
						translationOptions={{ postfix: { field: 'counter' } }}
						Component={Text}
						className="text-content-text-muted"
						size="sm"
						loading={loading}
						skeleton={skeleton}
					/>
				</TableCell>
				<TableCell className="asteria--type-name">
					<Translation
						translationKey="card.content.client.table.header.label"
						translationOptions={{ postfix: { field: 'name' } }}
						Component={Text}
						className="text-content-text-muted"
						size="sm"
						loading={loading}
						skeleton={skeleton}
					/>
				</TableCell>
				<TableCell className="asteria--type-amount">
					<Translation
						translationKey="card.content.client.table.header.label"
						translationOptions={{ postfix: { field: 'amount' } }}
						Component={Text}
						className="text-content-text-muted"
						size="sm"
						loading={loading}
						skeleton={skeleton}
					/>
				</TableCell>
			</TableHeader>
			{loading
				? Array.from({ length: 5 }).map((_, index) => (
						<TableRow key={index}>
							<TableCell className="asteria--type-counter">
								<Skeleton animated={animated} />
							</TableCell>
							<TableCell className="asteria--type-name">
								<Skeleton animated={animated} />
							</TableCell>
							<TableCell className="asteria--type-amount">
								<Skeleton animated={animated} />
							</TableCell>
						</TableRow>
				  ))
				: children}
		</Table>
	);
});

ClientTableView.propTypes = {
	children: PropTypes.node,
	loading: PropTypes.bool,
	animated: PropTypes.bool,
};

export default ClientTableView;
