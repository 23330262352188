import React from 'react';

import PropTypes from 'prop-types';

import Row from './row';
import ClientTableView from './table.view';

/**
 * @typedef { import('../../../basic/types').ChildrenProps } ChildrenProps
 *
 * @typedef Props
 * @property { 'CUSTOMER' | 'SUPPLIER' } clientType
 */

/** @type { React.FC<ChildrenProps & Props> } */
const ClientTable = React.memo(function ClientTable(props) {
	const { translationOptions, loading, importing, state } = props;

	const data = translationOptions?.data;

	const statistics = data?.source?.statistics?.details ?? [];

	const sourceDetails = (data?.source?.statistics?.details ?? []).reduce(
		(acc, { clientId, count }) => ({ ...acc, [clientId]: count }),
		{},
	);

	const targetDetails = (data?.target?.statistics?.details ?? []).reduce(
		(acc, { clientId, count }) => ({ ...acc, [clientId]: count }),
		{},
	);

	const isLoaderAnimated = loading || importing;
	const isLoading = isLoaderAnimated || state?.promotion?.visible;

	return (
		<ClientTableView loading={isLoading} animated={isLoaderAnimated}>
			{statistics.slice(0, 5).map((object, index) => (
				<Row
					key={object?.clientId}
					{...props}
					object={object}
					sourceDetails={sourceDetails}
					targetDetails={targetDetails}
					index={index}
				/>
			))}
		</ClientTableView>
	);
});

ClientTable.propTypes = {
	translationOptions: PropTypes.object,
	loading: PropTypes.bool,
	importing: PropTypes.bool,
	state: PropTypes.object,
};

export default ClientTable;
