import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';
import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import { Text, TextGroup } from '@asteria/component-core/typography';

import { useFormValues } from '@asteria/component-form';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import * as selectors from './selectors';

import './styles.scss';

const ClientSorting = (props) => {
	const { onChange } = props;

	const date = useFormValues({ name: 'date' });

	const range = useSelector(
		(store) => {
			if (date?.startDate) {
				return 'CUSTOM';
			}

			return selectors.sorting(store);
		},
		(a, b) => isEqual(a, b),
	);

	const handleChange = React.useCallback(
		(type) => onChange?.({ sorting: type }),
		[onChange],
	);

	const DropdownItemPostfix = (
		<Group verticalAlign="center" horizontalAlign="center">
			<Icon icon="check" />
		</Group>
	);

	const ToggleProps = React.useMemo(
		() => ({
			icon: 'chevron-down',
			iconActive: 'chevron-up',
			iconPosition: 'last',
			children: (
				<TextGroup>
					<Text size="sm" className="asteria--type-label">
						{TranslationService.get([
							'clients.list.sorting.toggle.label',
						])}
					</Text>
					<Text size="sm" className="asteria--type-value">
						{TranslationService.get([
							'clients.list.sorting.label',
							`clients.list.sorting.${range}.label`,
							'clients.list.sorting.toggle.value',
							`clients.list.sorting.toggle.${range}.value`,
						])}
					</Text>
				</TextGroup>
			),
		}),
		[range],
	);

	return (
		<Dropdown
			className={cn(
				'asteria-component__clients-list__dropdown',
				'asteria--type-sorting',
			)}
			size="sm"
			toggle={ToggleProps}
		>
			{[
				'LAST_12_MONTHS',
				'LAST_6_MONTHS',
				'LAST_3_MONTHS',
				'LIFETIME',
				'THIS_MONTH',
				'THIS_YEAR',
			].map((type) => (
				<DropdownItem
					key={type}
					onClick={handleChange(type)}
					postfix={DropdownItemPostfix}
					active={range === type}
				>
					{TranslationService.get([
						'clients.list.sorting.label',
						`clients.list.sorting.${type}.label`,
					])}
				</DropdownItem>
			))}
		</Dropdown>
	);
};

ClientSorting.displayName = 'ClientSorting';
ClientSorting.propTypes = {
	range: PropTypes.oneOf([
		'LAST_12_MONTHS',
		'LAST_6_MONTHS',
		'LAST_3_MONTHS',
		'LIFETIME',
		'THIS_MONTH',
		'THIS_YEAR',
	]),
	onChange: PropTypes.func,
};

export default ClientSorting;
