import React from 'react';

import PropTypes from 'prop-types';

import BasicCard from '../basic';

import { useRequest, useType } from './hooks';
import ClientPerformanceV1Content from './v1/content';
import ClientPerformanceV2Content from './v2/content';
import Loading from './v2/loading';

import './styles.scss';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { ChildrenProps['config'] } */
const CONFIG = {
	onboarding: { required: 'erp', loading: 'erp' },
	card: {
		header: { trends: { v1: true } },
		content: {
			title: { trends: { v2: true } },
			default: {
				view: {
					v1: ({ DefaultComponent, ...props }) => (
						<>
							<DefaultComponent {...props} />
							<ClientPerformanceV1Content {...props} />
						</>
					),
					v2: (props) => <ClientPerformanceV2Content {...props} />,
				},
			},
			promotion: {
				view: {
					v2: (props) => <ClientPerformanceV2Content {...props} />,
				},
			},
			loading: { v2: (props) => <Loading {...props} /> },
		},
		footer: {
			connect: {
				button: {
					connect: { required: 'erp' },
					postfix: { required: { onboarding: 'bank' } },
				},
			},
		},
	},
};

/** @type { React.FC<Props> } */
const ClientPerformanceCard = function ClientPerformanceCard(props) {
	const query = useRequest(props);
	const type = useType(props);

	return <BasicCard {...props} type={type} query={query} config={CONFIG} />;
};

ClientPerformanceCard.propTypes = {
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default ClientPerformanceCard;
