import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import Chip from '@asteria/component-chip';

import { Translation } from '@asteria/language';

/**
 * @typedef ObjectPropClient
 * @property { string } name
 *
 * @typedef ObjectPropTotal
 * @property { number } actual
 *
 * @typedef ObjectProp
 * @property { ObjectPropClient } client
 * @property { ObjectPropTotal } total
 */

/** @type { React.FC<{ object: ObjectProp, color: string }> } */
const ClientDetailsTooltip = React.memo(function ClientDetailsTooltip(props) {
	const { object, color } = props;

	return (
		<div className="flex justify-between items-center gap-4">
			<Chip
				colors={color}
				label={object?.client?.name}
				variant="simple"
			/>
			<Translation
				defaultText="{{ total.actual | number:false:false:true }}"
				data={object}
				Component={Text}
			/>
		</div>
	);
});

ClientDetailsTooltip.propTypes = {
	color: PropTypes.string,
	object: PropTypes.shape({
		client: PropTypes.shape({ name: PropTypes.string }),
		total: PropTypes.shape({ actual: PropTypes.number }),
	}),
};

export default ClientDetailsTooltip;
