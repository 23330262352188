import { format, parseISO } from 'date-fns';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as GraphStore from '@asteria/datalayer/stores/graph';

import { TranslationService } from '@asteria/language';
import * as TimesizeUtils from '@asteria/utils-funcs/timesize';

const typeToFilter = ({ filter, tags }) => {
	if (!tags || !filter) {
		return null;
	}

	if (filter.type === 'status' && filter.status) {
		const status = filter.status.toUpperCase();
		return {
			type: 'status',
			id: status,
			item: {
				id: status,
				code: status,
				name: TranslationService.get([
					`status.${filter.status}`,
					`selector.status.${filter.status}`,
				]),
			},
		};
	}

	if (filter.type === 'tag') {
		const filterTags = tags.filter((object) => {
			if (
				filter?.category?.name &&
				filter?.category?.name !== object?.category?.name
			) {
				return false;
			}

			if (
				filter?.category?.id &&
				filter?.category?.id !== object?.category?.id
			) {
				return false;
			}

			if (filter?.tag?.name && filter?.tag?.name !== object?.name) {
				return false;
			}

			if (filter?.tag?.id && filter?.tag?.id !== object?.id) {
				return false;
			}

			return true;
		});

		if (!filterTags || filterTags.length === 0) {
			return null;
		}

		return filterTags.map((tag) => ({
			type: 'tag',
			id: tag?._id ?? tag?.id,
			item: tag,
		}));
	}

	return null;
};

export function applyCashflowSettings({ settings = {}, state, dispatch }) {
	if (!settings) {
		return;
	}

	if (settings?.resetFilterType) {
		dispatch(AppStore.resetFilterType());
	}

	if (settings?.filters) {
		const tags = AppStore.selectors.tags(state);
		const filters = settings?.filters
			.flatMap((filter) => {
				// If all ready filter object, then pass it on
				if (filter?.item) {
					return filter;
				}

				// otherwise create filter object from type and id
				return typeToFilter({ filter, tags });
			})
			.filter(Boolean);

		dispatch(AppStore.setFilters(filters));
	}

	if (settings?.selectedDate) {
		dispatch(AppStore.setSelectedDate(settings?.selectedDate));
	}

	if (settings?.graphActiveGroups || settings?.selectedDate) {
		dispatch(
			GraphStore.setActiveGroups(
				settings?.graphActiveGroups ?? settings?.selectedDate,
			),
		);
	}

	if (settings?.currentDate || settings.selectedDate) {
		dispatch(
			AppStore.setCurrentDate(
				settings.currentDate ??
					format(
						TimesizeUtils.addTimeslice(
							parseISO(settings.selectedDate),
							'month',
							-3,
						),
						'yyyy-MM-dd',
					),
			),
		);
	}

	if (settings?.graphOptions) {
		dispatch(GraphStore.setGraphOptions(settings?.graphOptions));
	}

	if (settings?.activeBars) {
		dispatch(GraphStore.setActiveBars(settings?.activeBars));
	}

	if (settings?.graphTypes) {
		dispatch(AppStore.setSelectedType(settings?.graphTypes));
	}

	return;
}
