import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import Contenter from '@asteria/component-tools/contenter';

import { Translation } from '@asteria/language';
import useConfig from '@asteria/utils-hooks/useConfig';

/**
 * @typedef { import('../../basic/types').Props } Props
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const UnderConstructionHeader = React.memo(function UnderConstructionHeader(
	props,
) {
	const { translationOptions } = props;

	const content = useConfig(
		['pages.insights.under-construction.placeholder'],
		{
			deep: true,
		},
	);

	return (
		<div className="flex flex-col gap-4">
			<Contenter content={content} />
			<Translation
				translationKey={['card.content.text']}
				translationOptions={translationOptions}
				size="sm"
				Component={Text}
			/>
		</div>
	);
});

UnderConstructionHeader.propTypes = {
	onAction: PropTypes.func,
	translationOptions: PropTypes.object,
};

export default UnderConstructionHeader;
