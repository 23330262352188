import React from 'react';

import BaseCard from '../../../../base';
import { getVersion } from '../../utils';
import withConfig from '../../withConfig';
import Text from '../components/text';
import Title from '../components/title';

/**
 * @typedef { import('../../types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const ViewContent = React.memo(function ViewContent(props) {
	return (
		<>
			<Title {...props} />
			<Text {...props} />
		</>
	);
});

const ViewContentConfig = withConfig(ViewContent, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.content?.promotion?.view, version);
});

/** @type { React.FC<ChildrenProps> } */
const PromotionView = React.memo(function PromotionView(props) {
	return (
		<BaseCard.Content>
			<ViewContentConfig {...props} />
		</BaseCard.Content>
	);
});

export default withConfig(PromotionView, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.content?.promotion, version);
});

export { ViewContentConfig as ViewContent };
