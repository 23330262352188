import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { Title } from '@asteria/component-core/typography';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { Translation, TranslationService } from '@asteria/language';

/**
 * @typedef ChildrenOptions
 * @property { 'none' | 'erp' | 'bank' | 'both' } onboarding
 * @property { { postfix: unknown } } translationOptions
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 *
 * @typedef Props
 * @property { string } className
 * @property { React.ReactNode | (options: ChildrenOptions) => React.ReactNode } children
 * @property { Partial<{ as: React.ReactNode, props: unknown }> } [wrapper]
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 */

const WAITING_TIMEOUT = 4_000;

function formatIntegrationLoading(object) {
	return {
		id: object?._id ?? object?.id,
		status: object?.status?.state,
		connected: object?.config?.connected,
	};
}

function useIntegrationLoading() {
	const [integrations, setIntegrations] = React.useState([]);
	const excludedIds = React.useRef([]);

	const available = useSelector(
		(store) => {
			const erp = IntegrationStore.selectors
				.integrations(store, {
					type: 'erp',
					filters: [
						{ status: 'IDLE', disabled: false },
						{ status: 'INITIATING', disabled: false },
					],
				})
				.map(formatIntegrationLoading);

			const bank = IntegrationStore.selectors
				.integrations(store, {
					type: 'bank',
					filters: [
						{ status: 'IDLE', disabled: false },
						{ status: 'INITIATING', disabled: false },
					],
				})
				.map(formatIntegrationLoading);

			return [].concat(erp).concat(bank);
		},
		(a, b) => isEqual(a, b),
	);

	React.useEffect(() => {
		const getLoading = (data) => {
			return data.filter((object) => {
				if (object?.status === 'IDLE' && object?.connected === true) {
					excludedIds?.current?.push(object?.id);
				}

				return !excludedIds?.current?.includes(object?.id);
			});
		};

		if (excludedIds?.current?.length === 0) {
			const loadingIntegrations = getLoading(available);

			setIntegrations(loadingIntegrations);
		} else {
			const timeout = setTimeout(() => {
				const loadingIntegrations = getLoading(available);

				setIntegrations(loadingIntegrations);
			}, available.length * WAITING_TIMEOUT);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [available, excludedIds]);

	return integrations;
}

/** @type { React.FC<Pick<ChildrenOptions, 'translationOptions'> & { id: string }> } */
const OnboardingLoadingSingleTitle = React.memo(
	function OnboardingLoadingSingleTitle({ id, translationOptions }) {
		const { state, object: integration } = useSelector(
			(store) => {
				const object = IntegrationStore.selectors.integration(
					store,
					id,
				);

				let state = null;

				if (object?.status?.state === 'IDLE') {
					if (object?.config?.connected) {
						state = 'connected';
					} else {
						state = 'created';
					}
				} else {
					state = 'loading';
				}

				return { state, object };
			},
			(a, b) => isEqual(a, b),
		);

		const integrationName = TranslationService.get([
			'integration.title',
			`integration.title.${integration?.key}`,
			`integration.title.${integration?.type}.${integration?.key}`,
			`integration.${integration?.key}.title`,
			`integration.${integration?.type}.${integration?.key}.title`,
		]);

		if (!integration) {
			return null;
		}

		return (
			<Translation
				translationKey={[
					'card.content.title',
					'card.content.title.onboarding-loading',
				]}
				translationOptions={{
					...translationOptions,
					postfix: {
						...translationOptions?.postfix,
						status: state,
						'integration-key': integration?.key,
						'integration-type': integration?.type,
					},
					data: {
						...translationOptions?.data,
						integration: integration,
						integrationName: integrationName,
					},
				}}
				showWhenEmpty={false}
				Component={Title}
			/>
		);
	},
);

OnboardingLoadingSingleTitle.propTypes = {
	translationOptions: PropTypes.object,
	id: PropTypes.string,
};

/** @type { React.FC<Pick<ChildrenOptions, 'translationOptions'> & { integrations: unknown[] }> } */
const OnboardingLoadingTitle = React.memo(function OnboardingLoadingTitle({
	integrations,
	translationOptions,
}) {
	const [index, setIndex] = React.useState(0);

	React.useEffect(() => {
		setIndex(0);

		const timeout = setInterval(() => {
			setIndex((index) => {
				if (index >= integrations.length - 1) {
					return 0;
				}

				return index + 1;
			});
		}, WAITING_TIMEOUT);

		return () => {
			clearInterval(timeout);
		};
	}, [integrations.length]);

	if (!integrations.length) {
		return null;
	}

	return (
		<OnboardingLoadingSingleTitle
			id={integrations?.[index]?.id}
			translationOptions={translationOptions}
		/>
	);
});

OnboardingLoadingTitle.propTypes = {
	translationOptions: PropTypes.object,
	integrations: PropTypes.arrayOf(PropTypes.object),
};

export default OnboardingLoadingTitle;
export { useIntegrationLoading };
