import React from 'react';

import PropTypes from 'prop-types';

import { useDateVariant } from '../../hooks';
import BasicCard from '../basic';

import { useRequest } from './hooks';
import ForecastCardContent from './v1/content';

import './styles.scss';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { ChildrenProps['config'] } */
const CONFIG = {
	onboarding: { required: ['erp', 'bank'], optional: 'both' },
	card: {
		content: {
			default: {
				view: (props) => <ForecastCardContent {...props} />,
			},
		},
	},
};

/** @type { React.FC<Props> } */
const ForecastCard = function ForecastCard(props) {
	const query = useRequest(props);

	const variant = useDateVariant(props);

	if (variant === 'past') {
		return null;
	}

	return (
		<BasicCard {...props} type="forecast" query={query} config={CONFIG} />
	);
};

ForecastCard.propTypes = { onAction: PropTypes.func, onSubmit: PropTypes.func };

export default ForecastCard;
