import React from 'react';

import PropTypes from 'prop-types';
import { PolarAngleAxis, RadialBar, RadialBarChart } from 'recharts';

import Skeleton from '@asteria/component-core/skeleton';

import { cn } from '@asteria/utils-funcs/classes';

import { Content } from '../../basic';

/**
 * @typedef { import('../../basic/types').Props } Props
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const PromotionContent = React.memo(function PromotionContent(props) {
	const { loading, importing } = props;

	return (
		<div className="flex flex-col gap-4">
			<div className="flex items-center flex-col justify-center">
				<RadialBarChart
					width={170}
					height={90}
					cy={80}
					innerRadius={55}
					outerRadius={80}
					data={[{ value: 0, fill: 'var(--color-primary)' }]}
					startAngle={180}
					endAngle={0}
					className={cn({ 'animate-pulse': loading || importing })}
				>
					<PolarAngleAxis
						type="number"
						domain={[0, 100]}
						tick={false}
						axisLine={false}
					/>
					<RadialBar
						minAngle="0"
						dataKey="value"
						cornerRadius="10"
						background={{
							fill: 'var(--color-graph-radial-background)',
						}}
					/>
				</RadialBarChart>
				<Skeleton className="w-20" />
			</div>

			{importing ? (
				<Content.Loading {...props} />
			) : (
				<Content.Text {...props} className="text-center" />
			)}
		</div>
	);
});

PromotionContent.propTypes = {
	loading: PropTypes.bool,
	importing: PropTypes.bool,
};

export default PromotionContent;
