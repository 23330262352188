import React from 'react';

import PropTypes from 'prop-types';

import BasicCard from '../basic';

import { useRequest } from './hooks';

import './styles.scss';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { Props['config'] } */
const CONFIG = {
	onboarding: { required: 'erp', optional: 'bank' },
	card: {
		footer: {
			connect: {
				button: {
					connect: { required: 'erp' },
					postfix: { required: { onboarding: 'bank' } },
				},
			},
		},
	},
};

/** @type { React.FC<Props> } */
const SalaryCard = React.memo(function SalaryCard(props) {
	const query = useRequest(props);

	return (
		<BasicCard {...props} type="salaries" query={query} config={CONFIG} />
	);
});

SalaryCard.displayName = 'SalaryCard';

SalaryCard.propTypes = { className: PropTypes.string };

export default SalaryCard;
