import * as constants from './constants';

export function feedback(state, action) {
	switch (action?.type) {
		case constants.FEEDBACK_SET:
			return {
				...state,
				feedback: { ...state.feedback, ...action?.payload },
			};

		case constants.FEEDBACK_RESET:
			return { ...state, feedback: action?.payload };

		case constants.FEEDBACK_BACK: {
			if (state?.feedback?.step) {
				return {
					...state,
					feedback: {
						...state?.feedback,
						step: state?.feedback?.step - 1,
					},
				};
			}

			return { ...state, feedback: { visible: false } };
		}

		case constants.FEEDBACK_CLOSE:
			return { ...state, feedback: { visible: false } };

		case constants.FEEDBACK_NEXT:
			return {
				...state,
				feedback: {
					...state?.feedback,
					step: state?.feedback?.step + 1,
				},
			};

		default:
			return state;
	}
}

export function promotion(state, action) {
	switch (action?.type) {
		case constants.PROMOTION_SET:
			return {
				...state,
				promotion: { ...state.promotion, ...action?.payload },
			};

		case constants.PROMOTION_RESET:
			return { ...state, promotion: action?.payload };

		default:
			return state;
	}
}

export function card(state, action) {
	switch (action?.type) {
		case constants.CARD_SET:
			return {
				...state,
				card: { ...state.card, ...action?.payload },
			};
		case constants.CARD_RESET:
			return { ...state, card: action?.payload };

		default:
			return state;
	}
}
