import React from 'react';

import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { Text, Title } from '@asteria/component-core/typography';

import { hooks } from '@asteria/component-card';
import { useOnboardingState } from '@asteria/component-card/hooks';
import Notifications from '@asteria/component-notifications';
import { FeatureFlag } from '@asteria/component-tools/featureflag';
import { TourButton } from '@asteria/component-tour';

import { Translation } from '@asteria/language';
import useConfig from '@asteria/utils-hooks/useConfig';

import { useName } from './hooks';
import Logo from './logo';

import './styles.scss';

/**
 * @typedef Props
 * @property { <TResponse>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 * @property { string } startDate
 * @property { string } endDate
 */

/** @type { React.FC<Props> } */
const StreamlineTitle = React.memo(function StreamlineTitle({
	onAction,
	onSubmit,
	startDate,
	endDate,
}) {
	const onboarding = useOnboardingState();
	const query = useQuery({
		queryKey: ['streamline', 'title', { startDate, endDate }],
		queryFn: async () => {
			return await onSubmit?.('card:fetch', {
				type: 'account',
				source: {
					startDate: startDate,
					endDate: endDate,
				},
			});
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		enabled: !!startDate && !!endDate,

		select: (response) => response?.source,
	});

	const variant = hooks.useDateVariant({ startDate, endDate });

	let total = query?.data?.PAID?.total ?? 0;

	if (variant === 'future') {
		total = query?.data?.FORECAST?.total ?? 0;
	}

	const state = total > 0 ? 'positive' : total === 0 ? 'zero' : 'negative';

	const logo = useConfig('widget', {
		callback: React.useCallback((config) => config?.navigation?.logo, []),
	});

	const name = useName();

	return (
		<div className="flex justify-between gap-2">
			<div className="flex flex-col gap-1">
				<div className="flex flex-row gap-1 items-center">
					<Translation
						translationKey="view.streamline.title"
						translationOptions={{
							postfix: { company: !!name },
							data: {
								name: name,
								postfix: {
									connected: onboarding,
								},
							},
						}}
						Component={Title}
					/>
				</div>
				<Translation
					translationKey="view.streamline.title.sub"
					Component={Text}
					size="xl"
					loading={query.isFetching && !query.isRefetching}
					translationOptions={{
						postfix: {
							variant: variant,
							state: state,
							connected: onboarding,
						},
					}}
				/>
			</div>
			<div className="flex gap-2">
				<FeatureFlag feature="widget-notifications">
					<FeatureFlag feature="widget-notifications-streamline">
						<Notifications onAction={onAction} />
					</FeatureFlag>
				</FeatureFlag>
				<FeatureFlag feature="page-header-tour">
					<TourButton />
				</FeatureFlag>
			</div>
		</div>
	);
});

StreamlineTitle.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
};

export default StreamlineTitle;
