import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { TranslationService } from '@asteria/language';

import Action from './action';

/**
 * @typedef ForecastActionsGroup
 * @property { 'deposit' | 'withdraw' } type
 * @property { unknown } data
 * @property { unknown } translationOptions
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 */

/** @type { React.FC<ForecastActionsGroup> } */
const ForecastActionsGroup = React.memo(function ForecastActionsGroup({
	type,
	data,
	translationOptions,
	onAction,
}) {
	const redirectToForecast = React.useCallback(() => {
		onAction?.('card:action', {
			type: 'forecast-actions',
			data: {
				type: type,
			},
		});
	}, [onAction, type]);

	const buttonTranslationOptions = React.useMemo(() => {
		return {
			...translationOptions,
			postfix: { ...translationOptions.postfix, type: type },
		};
	}, [translationOptions, type]);

	if (!data?.length) {
		return null;
	}

	return (
		<div className="flex flex-col gap-2">
			<Button
				className="asteria--type-redirect"
				variant="link"
				label={TranslationService.getV2(
					['card.content.forecast-actions.group.button'],
					buttonTranslationOptions,
				)}
				onClick={redirectToForecast}
			/>
			{data?.map((object) => (
				<Action key={object?._id} data={object} onAction={onAction} />
			))}
		</div>
	);
});

ForecastActionsGroup.propTypes = {
	type: PropTypes.oneOf('deposit' | 'withdraw'),
	data: PropTypes.object,
	translationOptions: PropTypes.object,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default ForecastActionsGroup;
