import React from 'react';

import { Navigate, useSearchParams } from 'react-router-dom';

import PropTypes from 'prop-types';

/** @type { React.FC<{ to: string }> } */
const Redirect = React.memo(function Redirect({ to }) {
	const [search] = useSearchParams();

	return <Navigate to={[to, search].join('?')} replace />;
});

Redirect.displayName = 'Redirect';
Redirect.propTypes = { to: PropTypes.string };

export default Redirect;
