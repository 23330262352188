import React from 'react';

import PropTypes from 'prop-types';
import {
	Cell,
	Pie,
	PieChart,
	ResponsiveContainer,
	Sector,
	Tooltip,
} from 'recharts';

import { TooltipView } from '@asteria/component-core/tooltip';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const PieCell = (props) => {
	const { entry } = props;
	return <Cell className={entry.className} />;
};

PieCell.propTypes = {
	entry: PropTypes.shape({ className: PropTypes.string }),
};

const PieChartToolTip = (props) => {
	const { active, payload: [piePiece] = [] } = props;
	if (!piePiece?.payload?.payload?.tooltip) {
		return null;
	}

	return (
		<TooltipView
			className={cn(
				{ 'asteria-state-active': active },
				`asteria-component__tooltip--position-right`,
			)}
			target
		>
			{piePiece.payload.payload.tooltip}
		</TooltipView>
	);
};

PieChartToolTip.propTypes = {
	active: PropTypes.bool,
	payload: PropTypes.any,
};

const PieGraph = React.memo(function PieGraph(props) {
	const {
		data,
		dataKey = 'value',
		innerRadius = 50,
		activeIndex = null,
	} = props;

	return (
		<ResponsiveContainer
			className={cn('asteria__component-chart')}
			width="100%"
			height="100%"
		>
			<PieChart className="asteria__component-chart__pie" height={200}>
				<Tooltip content={<PieChartToolTip />} />
				<Pie
					data={data}
					dataKey={dataKey}
					nameKey="label"
					startAngle={90}
					endAngle={-270}
					innerRadius={innerRadius}
					strokeWidth={5}
					activeIndex={activeIndex}
					activeShape={(props) => <Sector {...props} />}
					inactiveShape={(props) => (
						<Sector {...props} opacity="0.3" />
					)}
				>
					{data.map((entry, index) => (
						<PieCell key={index} index={index} entry={entry} />
					))}
				</Pie>
			</PieChart>
		</ResponsiveContainer>
	);
});

PieGraph.propTypes = {
	data: PropTypes.array,
	dataKey: PropTypes.string,
	innerRadius: PropTypes.number,
	activeIndex: PropTypes.number,
};

export default PieGraph;
