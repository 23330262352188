import React from 'react';

import { get } from 'lodash-es';
import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import Row from './row';

/**
 * @typedef { import('../../types').ChildrenOptions } ChildrenOptions
 * @typedef { import('../../types').Props } Props
 */

/** @type { React.FC<Pick<ChildrenOptions, 'translationOptions' | 'onAction' | 'onSubmit'> & { type: 'deposit' | 'withdraw' }> } */
const List = React.memo(function List(props) {
	const { type, translationOptions, onAction, onSubmit } = props;

	const form = get(translationOptions?.data?.target, [type]) ?? {};

	const categories = Object.entries(
		Object.entries(form)
			.flatMap(([status, form]) =>
				Object.entries(form?.categories ?? {}).flatMap(
					([category, form]) =>
						Object.keys(form?.tags ?? {}).map((tag) => ({
							status,
							category,
							tag,
						})),
				),
			)
			.reduce(
				(acc, { status, category, tag }) => ({
					...acc,
					[category]: {
						...acc?.[category],
						[tag]: []
							.concat(acc?.[category]?.[tag] ?? [])
							.concat(status),
					},
				}),
				{},
			),
	).flatMap(([category, form]) =>
		Object.keys(form).map((tag) => ({ category, tag })),
	);

	return (
		<div
			className={cn(
				'flex flex-col gap-2',
				'asteria-component__card-section-list',
				{ [`asteria--type-${type}`]: type },
			)}
		>
			{categories.map(({ category, tag }, index) => (
				<Row
					key={[type, category, tag].join('-')}
					type={type}
					category={category}
					tag={tag}
					translationOptions={translationOptions}
					onAction={onAction}
					onSubmit={onSubmit}
					index={index}
				/>
			))}
		</div>
	);
});

List.propTypes = {
	type: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	translationOptions: PropTypes.object,
};

export default List;
