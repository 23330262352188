import React from 'react';

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { FooterSection } from '@asteria/component-core/wrapper';

import { TranslationService } from '@asteria/language';

import { getVersion } from '../../utils';
import withConfig from '../../withConfig';

/**
 * @typedef { import('../../types').ChildrenProps } ChildrenProps
 */

function useButtonConfig(props) {
	const { version, config } = props;

	return getVersion(config?.card?.footer?.discard?.button, version);
}

/** @type { React.FC<ChildrenProps> } */
const ActionDiscard = React.memo(function ActionDiscard(props) {
	const { type, onAction, translationOptions } = props;

	const config = useButtonConfig(props);

	const click = useMutation({
		mutationFn: () => onAction?.('card:dismiss', { type }),
	});

	if (config === false) {
		return null;
	}

	const analyticsKey =
		config?.analyticsKey ?? type
			? `card.${type}.footer.action.dismiss`
			: 'card.footer.action.dismiss';

	return (
		<FooterSection position="first">
			<Button
				variant={config?.variant ?? 'link'}
				size={config?.size ?? 'sm'}
				label={
					config?.label ??
					TranslationService.getV2(['card.footer.action'], {
						...translationOptions,
						postfix: {
							action: 'dismiss',
							...translationOptions.postfix,
						},
					})
				}
				icon={config?.icon}
				iconPosition={config?.iconPosition}
				analyticsKey={analyticsKey}
				analytics={translationOptions?.data}
				onClick={click.mutate}
			/>
		</FooterSection>
	);
});

ActionDiscard.propTypes = {
	type: PropTypes.string,
	onAction: PropTypes.func,
	translationOptions: PropTypes.object,
	config: PropTypes.object,
};

export default withConfig(ActionDiscard, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.footer?.discard, version);
});
