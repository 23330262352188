import React from 'react';

import PropTypes from 'prop-types';

import Skeleton from '@asteria/component-core/skeleton';

import { Content } from '../../basic';

import Chart from './chart';

/**
 * @typedef { import('../../basic/types').Props } Props
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const AccountCardContent = React.memo(function AccountCardContent(props) {
	const { loading, importing } = props;

	return (
		<div className="flex flex-col gap-4">
			<Skeleton className="w-40" animated={loading || importing} />

			<Chart {...props} />
			<Content.Loading {...props} />

			<Content.Title
				{...props}
				translationKey="card.content.title.promotion"
				config={{ card: { content: { title: { trends: false } } } }}
			/>
			<Content.Text
				{...props}
				translationKey="card.content.text.promotion"
			/>
		</div>
	);
});

AccountCardContent.propTypes = {
	loading: PropTypes.bool,
	importing: PropTypes.bool,
	state: PropTypes.object,
};

export default AccountCardContent;
