import React from 'react';

import PropTypes from 'prop-types';

import GraphLegend from '@asteria/component-graph/legends';

import { TranslationService } from '@asteria/language';

/**
 * @param { unknown[] } points
 */
function useLegend(points) {
	const hasPaidValue = (points ?? []).some(({ value }) => value);
	const hasForecastValue = (points ?? []).some(({ forecast }) => forecast);
	const hasCreditValue = (points ?? []).some(({ credit }) => credit);

	return React.useMemo(
		() =>
			[
				hasPaidValue
					? {
							part: 'account',
							type: ['account', 'paid'],
							title: TranslationService.get(
								'graph.account.legend.history',
							),
					  }
					: null,
				hasForecastValue
					? {
							part: 'account',
							type: ['account', 'forecast'],
							title: TranslationService.get(
								'graph.account.legend.forecast',
							),
					  }
					: null,
				hasForecastValue
					? {
							part: 'account',
							type: 'spread',
							title: TranslationService.get(
								'graph.account.legend.spread',
							),
					  }
					: null,
				hasCreditValue
					? {
							part: 'account',
							type: 'credit',
							title: TranslationService.get(
								'graph.account.legend.credit',
							),
					  }
					: null,
			].filter(Boolean),
		[hasCreditValue, hasForecastValue, hasPaidValue],
	);
}

/** @type { React.FC<{ points: unknown[] }> } */
const Legend = React.memo(function Legend(props) {
	const { points } = props;

	const data = useLegend(points);

	if (!data.length) {
		return null;
	}

	return (
		<div className="flex items-center justify-center pt-4">
			<GraphLegend data={data} />
		</div>
	);
});

Legend.propTypes = { points: PropTypes.arrayOf(PropTypes.object) };

export default Legend;
