import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import { COLORS } from '../../constants';

import ChartPart from './part';

/**
 * @typedef { import('../../../basic/types').ChildrenProps } ChildrenProps
 *
 * @typedef Props
 * @property { 'CUSTOMER' | 'SUPPLIER' } clientType
 */

/** @type { React.FC<{ placeholder: boolean, animated: boolean, active: string, values: { impact: number }[] }> } */
const ChartView = React.memo(function ChartView(props) {
	const { placeholder, animated, active, values } = props;

	const total = values.reduce(
		(acc, object) => acc + (object?.impact ?? 0),
		0,
	);

	return (
		<div
			className={cn(
				'flex gap-px',
				'asteria-component__card-section',
				'asteria--type-chart',
				'asteria-component__card-chart',
				{ 'asteria--state-highlight': !!active },
			)}
		>
			{placeholder ? (
				<div
					className={cn(
						'h-4 w-full bg-skeleton-normal-background rounded',
						{ 'animate-pulse': animated },
					)}
				/>
			) : (
				<>
					{values.map((object, index, arr) => (
						<ChartPart
							key={object?.clientId}
							first={index === 0}
							last={index === arr.length - 1 && total >= 100}
							object={object}
							active={object?.clientId === active}
							color={COLORS[index]}
						/>
					))}

					{total < 100 ? (
						<div
							className={cn(
								'h-4 bg-tags-deposit-misc-background rounded-r',
							)}
							style={{ width: `${100 - total}%` }}
						/>
					) : null}
				</>
			)}
		</div>
	);
});

ChartView.propTypes = {
	placeholder: PropTypes.bool,
	animated: PropTypes.bool,
	active: PropTypes.string,
	values: PropTypes.arrayOf(PropTypes.object),
};

export default ChartView;
