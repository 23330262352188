import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Wrapper from '@asteria/component-core/wrapper';
import Content from '@asteria/component-core/wrapper/content';
import Footer, { FooterSection } from '@asteria/component-core/wrapper/footer';
import Header from '@asteria/component-core/wrapper/header';

import Modal from '@asteria/component-modal';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import FlexItemContext from './flex-item-context';

import './styles.scss';

const FlexItem = React.memo((props) => {
	const { className, show: $show, size, animated, children, ...rest } = props;

	const [show, setShow] = React.useState(true);
	const [modalIsOpen, setModalIsOpen] = React.useState(false);

	const animationRef = React.useRef(null);

	const [closeAnimation, setCloseAnimation] = React.useState(false);
	const [showAnimation, setShowAnimation] = React.useState(false);

	const handleClose = React.useCallback(() => {
		if (animated) {
			setCloseAnimation(true);

			setTimeout(() => {
				setShow(false);
				setCloseAnimation(false);
			}, 500);
		} else {
			setShow(false);
		}
	}, [animated]);

	const handleShow = React.useCallback(() => {
		if (animated) {
			setShowAnimation(true);
			setShow(true);

			setTimeout(() => {
				setShowAnimation(false);
			}, 500);
		} else {
			setShow(true);
		}
	}, [animated]);

	React.useEffect(() => {
		if ($show === true && animationRef.current) {
			handleShow();
		}

		if ($show === false && animationRef.current) {
			handleClose();
		}

		if (animationRef.current === null) {
			setShow($show);

			animationRef.current = true;
		}
	}, [$show, handleClose, handleShow]);

	const showModal = React.useCallback(() => {
		setModalIsOpen(true);
	}, []);

	const closeModal = React.useCallback(() => {
		setModalIsOpen(false);
	}, []);

	const style = React.useMemo(() => ({ '--size': size }), [size]);

	const context = React.useMemo(
		() => ({
			hide: handleClose,
			show: handleShow,
			showModal: showModal,
			closeModal: closeModal,
		}),
		[handleClose, closeModal, handleShow, showModal],
	);

	if (show === false) {
		return null;
	}

	return (
		<FlexItemContext.Provider value={context}>
			<div
				className={cn(
					'asteria-component__flex-item-wrapper',
					className,
					{
						'asteria--animation-close': closeAnimation,
						'asteria--animation-show': showAnimation,
					},
				)}
				style={style}
				{...rest}
			>
				<div className="asteria-component__flex-item-content">
					{children}
				</div>
			</div>
			<Modal
				open={modalIsOpen}
				onClose={closeModal}
				className="asteria-component__flex__item__modal"
			>
				<Wrapper>
					<Header onClose={closeModal}>
						{TranslationService.get(['flex.item.modal.title'])}
					</Header>
					<Content className="asteria-component__flex__item__modal__content">
						{children}
					</Content>
					<Footer>
						<FooterSection position="last">
							<Button
								variant="secondary"
								label={TranslationService.get([
									'button.close',
									'flex.item.modal.close',
								])}
								onClick={closeModal}
							/>
						</FooterSection>
					</Footer>
				</Wrapper>
			</Modal>
		</FlexItemContext.Provider>
	);
});

FlexItem.displayName = 'FlexItem';

FlexItem.propTypes = {
	className: PropTypes.string,
	show: PropTypes.bool,
	animated: PropTypes.bool,
	size: PropTypes.number,
	children: PropTypes.node,
};

export default FlexItem;
