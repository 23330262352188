import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import * as AppStore from '@asteria/datalayer/stores/app';

import Analytics from '@asteria/utils-analytics';

/** @type { React.FC<React.PropsWithChildren<{ analytics: boolean }>> } */
const AnalyticsWrapper = React.memo(function AnalyticsWrapper(props) {
	const { children, analytics } = props;

	const accessToken = useSelector(AppStore.selectors.accessToken);

	React.useEffect(() => {
		Analytics.accessToken = accessToken;
		Analytics.mode = analytics ? 'api' : 'console';
		Analytics.init();
		Analytics.event('load', {});
	}, [accessToken, analytics]);

	return <>{children}</>;
});

AnalyticsWrapper.propTypes = {
	children: PropTypes.node,
	analytics: PropTypes.bool,
};

export default AnalyticsWrapper;
