import React from 'react';

import PropTypes from 'prop-types';

import { useDateVariant, useOnboardingState } from '../../hooks';
import BasicCard from '../basic';

import { useRemoveMutation, useRequest } from './hooks';
import ForecastActionsContentV1 from './v1/content';
import ForecastActionsContentV2 from './v2/content';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { Props['config'] } */
const CONFIG = {
	onboarding: { required: ['erp', 'bank'], optional: 'both' },
	card: {
		content: {
			default: {
				view: {
					v1: (props) => <ForecastActionsContentV1 {...props} />,
					v2: (props) => <ForecastActionsContentV2 {...props} />,
				},
			},
		},
	},
};

/** @type { React.FC<Props> } */
const ForecastActionsCard = React.memo(function ForecastActionsCard(props) {
	const { startDate, endDate, onAction, onSubmit } = props;

	const query = useRequest({ onSubmit, startDate, endDate });

	const removeMutation = useRemoveMutation({ onSubmit });

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'forecast:actions:delete') {
				return removeMutation.mutate(data);
			}

			return onAction?.(action, data);
		},
		[onAction, removeMutation],
	);

	const onboarding = useOnboardingState();
	const variant = useDateVariant({ startDate, endDate });

	if (variant === 'past') {
		return null;
	}

	if (onboarding === 'none') {
		return null;
	}

	if (!query?.data?.actions?.length) {
		return null;
	}

	return (
		<BasicCard
			{...props}
			onAction={handleAction}
			type="forecast-actions"
			config={CONFIG}
			query={query}
		/>
	);
});

ForecastActionsCard.displayName = 'ForecastActionsCard';

ForecastActionsCard.propTypes = {
	startDate: PropTypes.string,
	endDate: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default ForecastActionsCard;
