import React from 'react';

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

/**
 * @typedef { import('../../basic/types').Props } Props
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const UnderConstructionHeader = React.memo(function UnderConstructionHeader({
	DefaultComponent,
	...props
}) {
	const { onAction, translationOptions } = props;

	const close = useMutation({
		mutationFn: async () =>
			onAction?.('card:close', {
				variant: 'under-construction',
				data: translationOptions?.data,
			}),
	});

	return <DefaultComponent {...props} onClose={close.mutate} />;
});

UnderConstructionHeader.propTypes = {
	DefaultComponent: PropTypes.node,
	onAction: PropTypes.func,
	translationOptions: PropTypes.object,
};

export default UnderConstructionHeader;
