import React from 'react';

import PropTypes from 'prop-types';

import TrendIcon from '../../../../../components/trends';
import { ClientPerformanceContext } from '../../../context';
import { getTrendDirection } from '../../../utils';

import ClientTableRowView from './row.view';

/**
 * @typedef { import('../../../../basic/types').ChildrenProps } ChildrenProps
 *
 * @typedef Props
 * @property { 'CUSTOMER' | 'SUPPLIER' } clientType
 * @property { unknown } object
 * @property { unknown } sourceDetails
 * @property { unknown } targetDetails
 * @property { number } index
 */

/** @type { React.FC<ChildrenProps & Props> } */
const ClientTableRow = React.memo(function ClientTableRow(props) {
	const {
		translationOptions,
		clientType,
		query,

		object,
		sourceDetails,
		targetDetails,
		index,

		onAction,
	} = props;

	const { active } = React.useContext(ClientPerformanceContext);

	const onMouseEnter = React.useCallback(
		() => active?.set?.(object?.clientId),
		[object?.clientId],
	);

	const onMouseLeave = React.useCallback(() => active?.set?.(null), []);

	const showClient = React.useCallback(
		() =>
			onAction?.('card:action', {
				type: `${clientType}-details`.toLowerCase(),
				client: object,
			}),
		[clientType, object, onAction],
	);

	const postfix = translationOptions?.postfix;
	const data = translationOptions?.data;

	const sourceCount = sourceDetails?.[object?.clientId];
	const targetCount = targetDetails?.[object?.clientId];

	const trends = getTrendDirection(
		{ source: sourceDetails, target: targetDetails },
		object?.clientId,
	);

	return (
		<ClientTableRowView
			index={index}
			postfix={postfix}
			object={object}
			onChipClick={showClient}
			onChipMouseEnter={onMouseEnter}
			onChipMouseLeave={onMouseLeave}
			trends={
				<TrendIcon
					size="xs"
					direction={trends}
					query={query}
					postfix={{
						...postfix,
						type: 'client-table',
						trends: trends,
					}}
					data={{
						...data,
						trends: {
							count: {
								source: sourceCount,
								target: targetCount,
							},
							percentage: targetCount
								? (Math.abs(sourceCount - targetCount) /
										targetCount) *
								  100
								: 100,
						},
					}}
				/>
			}
			clientType={clientType}
		/>
	);
});

ClientTableRow.propTypes = {
	translationOptions: PropTypes.object,
	clientType: PropTypes.string,
	query: PropTypes.object,

	object: PropTypes.object,
	sourceDetails: PropTypes.object,
	targetDetails: PropTypes.object,
	index: PropTypes.number,

	onAction: PropTypes.func,
};

export default ClientTableRow;
