import { useQuery } from '@tanstack/react-query';
import { formatISO, subYears } from 'date-fns';

import { parseDate } from '@asteria/utils-funcs/normalize';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

/** @param { Props } props */
export function useRequest({ onSubmit, startDate, endDate }) {
	return useQuery({
		queryKey: ['card', 'salaries', { startDate, endDate }],
		queryFn: async () => {
			const response = await onSubmit?.('card:fetch', {
				type: 'salaries',
				source: {
					startDate: startDate,
					endDate: endDate,
				},
				target: {
					startDate: formatISO(subYears(parseDate(startDate), 1), {
						representation: 'date',
					}),
					endDate: formatISO(subYears(parseDate(endDate), 1), {
						representation: 'date',
					}),
				},
			});

			return response;
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		enabled: !!startDate && !!endDate,
	});
}
