import React from 'react';

import BaseCard from '../../../../base';
import { getVersion } from '../../utils';
import withConfig from '../../withConfig';
import Postfix from '../components/postfix';
import Title from '../components/title';

/**
 * @typedef { import('../../types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const ViewContent = React.memo(function ViewContent(props) {
	return <Title {...props} />;
});

const ViewContentConfig = withConfig(ViewContent, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.header?.promotion?.view, version);
});

/** @type { React.FC<ChildrenProps> } */
const PromotionView = React.memo(function PromotionView(props) {
	const postfix = React.useMemo(
		() => ({ children: { after: <Postfix {...props} /> } }),
		[props],
	);

	return (
		<BaseCard.Header postfix={postfix}>
			<ViewContentConfig {...props} />
		</BaseCard.Header>
	);
});

export default withConfig(PromotionView, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.header?.promotion, version);
});

export { ViewContentConfig as ViewContent };
