import React from 'react';
import ReactDOM from 'react-dom';

import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { QueryClientProvider, useQuery } from '@tanstack/react-query';
import cssVars from 'css-vars-ponyfill';
import { enableES5 } from 'immer';
import PropTypes from 'prop-types';

import { AuthService } from '@asteria/backend-utils-services';

import AuthView from '@asteria/view-auth';

import { useFeature } from '@asteria/component-tools/featureflag';

import store, { reset } from '@asteria/datalayer';

import { TranslationService } from '@asteria/language';
import '@asteria/utils-cache';
import * as Configuration from '@asteria/utils-configuration';
import { decode } from '@asteria/utils-funcs/jwt';
import '@asteria/utils-graphql';
import BaseWidget, {
	AuthValidate,
	ErrorBoundary,
	Redirect,
	TranslationContext,
	queryClient,
} from '@asteria/widget-base';

import { LayoutContext } from './context';
import Layout from './layout';
import AccountsPage from './pages/accounts';
import CashflowPage from './pages/cashflow';
import ClientsPage from './pages/clients';
import FinancialPage from './pages/financial';
import ForecasterPage from './pages/forecaster';
import OnboardingPage from './pages/onboarding';
import OverviewPage from './pages/overview';
import SettingsPage from './pages/settings';
import StatementPage from './pages/statement';
import StreamlinePage from './pages/streamline';
import SwedbankPage from './pages/swedbank';
import WelcomePage from './pages/welcome';
import * as selectors from './selectors';

import '@asteria/themes/base/main.scss';

Configuration.apply();
cssVars({});
enableES5();

const getPartnerIdFromToken = (token) => decode(token)?.partnerId;

/** @type { React.FC<{ type: string }> } */
const BehaviorValidation = React.memo(function BehaviorValidation(props) {
	const { children, type } = props;

	const hasWelcomeFeature = useFeature('widget-welcome-page');
	const hasForecaster = useFeature('forecaster');
	const hasStreamlinedFeature = useFeature('streamlined-first-page');

	const isStreamlinedShownRef = React.useRef(null);
	const isWelcomeShown = useSelector(selectors.isWelcomeShown);
	const isForecasterShown = useSelector(selectors.isForecasterShown);

	if (type !== 'welcome') {
		if (hasWelcomeFeature) {
			if (!isWelcomeShown) {
				return <Redirect to="/welcome" />;
			}
		}

		if (type !== 'forecaster') {
			if (hasForecaster) {
				if (!isForecasterShown) {
					return <Redirect to="/forecaster" />;
				}
			}
		}

		if (!isStreamlinedShownRef?.current) {
			isStreamlinedShownRef.current = true;

			if (type === 'cashflow') {
				if (hasStreamlinedFeature) {
					return <Redirect to="/insights" />;
				}
			}
		}
	}

	return children;
});

BehaviorValidation.displayName = 'RedirectWrapper';
BehaviorValidation.propTypes = {
	children: PropTypes.node,
	type: PropTypes.string,
};

const AuthPage = React.memo(function AuthPage({ partnerId, logo, homepage }) {
	const { onSubmit, onAction } = React.useContext(LayoutContext);
	const { language } = React.useContext(TranslationContext);

	const hideSignupCode = useFeature('auth-hide-signup-code');
	const signup = useFeature('asteria-auth-signup');
	// eslint-disable-next-line spellcheck/spell-checker
	const passwordReset = useFeature('login.forgotpassword');
	const languages = useFeature('multi-language');

	return (
		<AuthView
			key={language}
			partnerId={partnerId}
			logo={logo}
			homepage={homepage}
			languages={languages}
			passwordReset={passwordReset}
			signup={signup}
			signupCode={!hideSignupCode}
			onAction={onAction}
			onSubmit={onSubmit}
		/>
	);
});

AuthPage.displayName = 'AuthPage';
AuthPage.propTypes = {
	partnerId: PropTypes.string,
	logo: PropTypes.node,
	homepage: PropTypes.bool,
};

function onUserLoad(data) {
	TranslationService.updateGlobals({ user: data });
}

function onCompanyLoad(data) {
	TranslationService.updateGlobals({ company: data });
}

function onIntegrationsLoad(data) {
	TranslationService.updateGlobals({ integrations: data });
}

const DATALAYER = {
	onUserLoad: onUserLoad,
	onCompanyLoad: onCompanyLoad,
	onIntegrationsLoad: onIntegrationsLoad,
};

const AsteriaWidgetContent = React.memo(function AsteriaWidgetContent(props) {
	const {
		accessToken: externalAccessToken,
		languageCode = 'sv',
		demo = false,
		analytics = true,
		partnerId,
		theme = 'swedbank',
		environment = 'production',
		callback,
		router,
		debug,
		auth,
	} = props;

	const { data: accessToken, isFetching } = useQuery({
		queryKey: ['widget', 'full', 'demo'],
		queryFn: async () => {
			if (!demo) {
				return externalAccessToken ?? null;
			}

			if (environment === 'stage') {
				Configuration.set('https://stage-api.asteria.ai/');
			} else if (environment === 'development') {
				Configuration.set('https://dev-api.asteria.ai/');
			}

			if (location && location?.hostname?.includes('swedbank.net')) {
				Configuration.set('https://stage-api.asteria.ai/');
			}

			return AuthService.auth
				.login({
					useTokenFromContext: false,
					username: 'demo.swedbank@asteria.ai',
					password: 'testar',
					partnerId,
				})
				.then((response) => response?.data?.accessToken ?? null)
				.catch(() => null);
		},
		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});

	const routing = React.useMemo(
		() => ({
			type: router?.type,
			routes: (
				<Route
					path="/"
					element={
						<AuthValidate
							otherwise={
								<Layout
									callback={callback}
									debug={debug}
									auth
								/>
							}
						>
							<Layout callback={callback} debug={debug} />
						</AuthValidate>
					}
					errorElement={<ErrorBoundary />}
				>
					<Route
						index
						element={
							<AuthValidate>
								<BehaviorValidation type="cashflow">
									<CashflowPage />
								</BehaviorValidation>
							</AuthValidate>
						}
					/>
					<Route
						path="forecaster/*"
						element={
							<AuthValidate>
								<BehaviorValidation type="forecaster">
									<ForecasterPage />
								</BehaviorValidation>
							</AuthValidate>
						}
					/>
					<Route
						path="financial/*"
						element={
							<AuthValidate>
								<BehaviorValidation type="financial">
									<FinancialPage />
								</BehaviorValidation>
							</AuthValidate>
						}
					/>
					<Route
						path="onboarding/*"
						element={
							<AuthValidate>
								<BehaviorValidation type="onboarding">
									<OnboardingPage />
								</BehaviorValidation>
							</AuthValidate>
						}
					/>
					<Route
						path="settings/*"
						element={
							<AuthValidate>
								<BehaviorValidation type="settings">
									<SettingsPage />
								</BehaviorValidation>
							</AuthValidate>
						}
					/>
					<Route
						path="accounts/*"
						element={
							<AuthValidate>
								<BehaviorValidation type="accounts">
									<AccountsPage />
								</BehaviorValidation>
							</AuthValidate>
						}
					/>
					<Route
						path="overview/*"
						element={
							<AuthValidate>
								<BehaviorValidation type="overview">
									<OverviewPage />
								</BehaviorValidation>
							</AuthValidate>
						}
					/>
					<Route
						path="statement/*"
						element={
							<AuthValidate>
								<BehaviorValidation type="statement">
									<StatementPage />
								</BehaviorValidation>
							</AuthValidate>
						}
					/>
					<Route
						path="clients/*"
						element={
							<AuthValidate>
								<BehaviorValidation type="clients">
									<ClientsPage />
								</BehaviorValidation>
							</AuthValidate>
						}
					/>
					<Route
						path="welcome/*"
						element={
							<AuthValidate
								otherwise={<Redirect to="/auth/welcome" />}
							>
								<BehaviorValidation type="welcome">
									<WelcomePage />
								</BehaviorValidation>
							</AuthValidate>
						}
					/>
					<Route
						path="insights/*"
						element={
							<AuthValidate>
								<BehaviorValidation type="streamline">
									<StreamlinePage />
								</BehaviorValidation>
							</AuthValidate>
						}
					/>
					<Route
						path="swedbank/*"
						element={
							<AuthValidate>
								<BehaviorValidation type="swedbank">
									<SwedbankPage />
								</BehaviorValidation>
							</AuthValidate>
						}
					/>
					<Route
						path="recover/*"
						element={<Redirect to="/auth/recover" />}
					/>
					<Route
						path="auth/*"
						element={
							<AuthPage {...(auth ?? {})} partnerId={partnerId} />
						}
					/>
				</Route>
			),
		}),
		[auth, callback, debug, partnerId, router?.type],
	);

	if (isFetching) {
		return null;
	}

	Configuration.setURLByToken(accessToken);

	return (
		<BaseWidget
			accessToken={accessToken}
			partnerId={partnerId ?? getPartnerIdFromToken(accessToken)}
			languageCode={languageCode}
			analytics={analytics}
			theme={theme}
			callback={callback}
			datalayer={DATALAYER}
			routing={routing}
		/>
	);
});

AsteriaWidgetContent.propTypes = {
	accessToken: PropTypes.string,
	languageCode: PropTypes.string,
	demo: PropTypes.bool,
	analytics: PropTypes.bool,
	partnerId: PropTypes.string,
	theme: PropTypes.string,
	environment: PropTypes.string,

	callback: PropTypes.func,
	router: PropTypes.shape({
		type: PropTypes.oneOf(['memory', 'browser', 'hash']),
	}),
	debug: PropTypes.bool,
	auth: PropTypes.shape({ logo: PropTypes.node, homepage: PropTypes.bool }),
};

const AsteriaWidget = React.memo(function AsteriaWidget(props) {
	return (
		<QueryClientProvider client={queryClient}>
			<AsteriaWidgetContent {...props} />
		</QueryClientProvider>
	);
});

AsteriaWidget.propTypes = {
	accessToken: PropTypes.string,
	languageCode: PropTypes.string,
	demo: PropTypes.bool,
	analytics: PropTypes.bool,
	partnerId: PropTypes.string,
	theme: PropTypes.string,
	environment: PropTypes.string,

	callback: PropTypes.func,
	router: PropTypes.shape({
		type: PropTypes.oneOf(['memory', 'browser', 'hash']),
	}),
	debug: PropTypes.bool,
	auth: PropTypes.shape({ logo: PropTypes.node, homepage: PropTypes.bool }),
};

const create = async (
	element,
	{
		accessToken,
		partnerId,
		languageCode = 'sv',
		demo = false,
		analytics = true,
		callback,
		theme,
		environment = 'production',
	} = {},
) => {
	ReactDOM.render(
		<AsteriaWidget
			accessToken={accessToken}
			languageCode={languageCode}
			demo={demo}
			analytics={analytics}
			partnerId={partnerId}
			callback={callback}
			theme={theme}
			environment={environment}
		/>,
		element,
	);

	return true;
};

const createErp = async (element, config) =>
	create(element, { ...config, route: '/integrations/status' });

const cleanup = (element) => {
	reset(store.dispatch);
	try {
		queryClient.clear();
	} catch (e) {
		// DO NOTHING!!!!
	}

	ReactDOM.unmountComponentAtNode(element);
};

export default create;
export { AsteriaWidget, cleanup, create, createErp };
