import React from 'react';

import PropTypes from 'prop-types';

import {
	Text,
	TextGroup,
	UnorderedList,
} from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

import '../styles.scss';

const HealthExtra = React.memo(function HealthExtra(props) {
	const { onboarding, query, translationOptions } = props;

	const actions = query?.data?.source?.actions ?? [];

	if (onboarding === 'none') {
		return null;
	}

	return (
		<>
			<TextGroup>
				<Translation
					translationKey="card.content.extra.0"
					translationOptions={translationOptions}
					Component={Text}
				/>
				<Translation
					translationKey="card.content.extra.1"
					translationOptions={translationOptions}
					Component={Text}
				/>
			</TextGroup>
			{actions.length ? (
				<UnorderedList
					items={actions.map((action) => ({
						skipEmpty: true,

						value: 'card.content.bullet',
						translationOptions: {
							...translationOptions,
							postfix: {
								...translationOptions?.postfix,
								action: action?.type,
								...action?.postfix,
							},
							data: {
								...translationOptions?.data,
								action: action?.data,
								score: action?.score,
							},
						},
					}))}
				/>
			) : null}
		</>
	);
});

HealthExtra.displayName = 'HealthExtra';

HealthExtra.propTypes = {
	onboarding: PropTypes.string,
	query: PropTypes.object,
	translationOptions: PropTypes.object,
};

export default HealthExtra;
