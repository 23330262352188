import React from 'react';

import PropTypes from 'prop-types';

import BasicCard from '../basic';

import { useCardState } from './hooks';
import UnderConstructionFooterV1 from './v1/footer';
import UnderConstructionHeaderV1 from './v1/header';
import UnderConstructionContent from './v2/content';
import UnderConstructionFooterV2 from './v2/footer';
import UnderConstructionHeaderV2 from './v2/header';

import './styles.scss';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { Props['config'] } */
const CONFIG = {
	onboarding: { required: ['none', 'erp', 'bank'] },
	card: {
		header: {
			default: {
				view: {
					v1: (props) => <UnderConstructionHeaderV1 {...props} />,
					v2: (props) => <UnderConstructionHeaderV2 {...props} />,
				},
			},
			postfix: false,
		},
		content: {
			default: {
				view: {
					v2: (props) => <UnderConstructionContent {...props} />,
				},
			},
		},
		footer: {
			v1: (props) => <UnderConstructionFooterV1 {...props} />,
			v2: (props) => <UnderConstructionFooterV2 {...props} />,
		},
	},
};

/** @type { React.FC<Props> } */
const UnderConstructionCard = React.memo(function UnderConstructionCard(props) {
	const { onAction } = props;

	const [visible, setVisible] = React.useState(true);

	const handleAction = React.useCallback(
		async (...args) => {
			const [action] = args;

			if (action === 'card:close') {
				await onAction?.(...args);

				setVisible(false);

				return;
			}

			return onAction?.(...args);
		},
		[onAction],
	);

	return (
		<BasicCard
			{...props}
			type="under-construction"
			config={CONFIG}
			onAction={handleAction}
			visible={visible}
			useCardState={useCardState}
		/>
	);
});

UnderConstructionCard.displayName = 'UnderConstructionCard';

UnderConstructionCard.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default UnderConstructionCard;
