import React from 'react';

import PropTypes from 'prop-types';

import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { stateClasses } from '@asteria/component-core/utils';

import { cn } from '@asteria/utils-funcs/classes';

import ClientDetailsTooltip from '../tooltip-details';

/** @type { React.FC<{ first: boolean, last: boolean, object: unknown, active: boolean, color: string }> } */
const ChartPart = function ChartPart(props) {
	const { first, last, object, active, color } = props;

	const style = React.useMemo(
		() => ({ width: `${object?.impact}%` }),
		[object?.impact],
	);

	return (
		<TooltipWrapper
			tooltip={<ClientDetailsTooltip object={object} color={color} />}
		>
			<div
				className={cn(
					'h-4',
					{ 'rounded-l': first, 'rounded-r': last },
					'bg-tags-children-children-babysitting-background',
					'bg-tags-health-health-pharmacy-background',
					'bg-tags-insurance-insurance-accident-background',
					'bg-tags-office-office-electricity-background',
					'bg-tags-shopping-shopping-flowers-background',
					`bg-tags-${color}-background`,
					'asteria-component__card-chart-bar',
					stateClasses({
						active: active,
					}),
				)}
				style={style}
			/>
		</TooltipWrapper>
	);
};

ChartPart.propTypes = {
	first: PropTypes.bool,
	last: PropTypes.bool,
	object: PropTypes.object,
	active: PropTypes.bool,
	color: PropTypes.string,
};

export default ChartPart;
