import React from 'react';

import PropTypes from 'prop-types';

import BaseCard from '../../../../base';
import { useChildren } from '../../hooks';
import { getVersion } from '../../utils';
import withConfig from '../../withConfig';

/**
 * @typedef { import('../../types').ChildrenProps } ChildrenProps
 */

/** @param { ChildrenProps } props  */
const ViewContent = React.memo(function ViewContent() {
	return null;
});

const ViewContentConfig = withConfig(ViewContent, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.footer?.default?.view, version);
});

/** @type { React.FC<ChildrenProps> } */
const DefaultView = React.memo(function DefaultView({ children, ...props }) {
	const content = useChildren({ children, ...props }, (props) => (
		<ViewContentConfig {...props} />
	));

	if (children) {
		return <BaseCard.Footer>{content}</BaseCard.Footer>;
	}

	return null;
});

DefaultView.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default withConfig(DefaultView, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.footer?.default, version);
});

export { ViewContentConfig as ViewContent };
