import React from 'react';

import PropTypes from 'prop-types';

import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text, Title } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

import TrendIcon from '../../../../components/trends';
import { useTrendDirection } from '../../../../hooks';
import { Content } from '../../../basic';
import { useHealthState } from '../../hooks';

import HealthRadialBar from './radialbar';

import '../../styles.scss';

const HealthTrends = React.memo(function HealthTrends(props) {
	const { query, translationOptions } = props;

	const actions = query?.data?.source?.actions ?? [];

	const companyHealth = useHealthState(query);

	const direction = useTrendDirection({
		card: 'health',
		query: query,
	});

	return (
		<>
			<div className="flex justify-center">
				<div className="flex flex-col items-center justify-center">
					<TooltipWrapper
						tooltip={
							<Translation
								className="text-center"
								translationKey={['card.content.chart.tooltip']}
								translationOptions={{
									...translationOptions,
									postfix: {
										...translationOptions?.postfix,
										health: companyHealth,
										actions: !!actions?.length,
									},
								}}
								Component={Text}
							/>
						}
					>
						<div>
							<HealthRadialBar
								query={query}
								translationOptions={translationOptions}
							/>
						</div>
					</TooltipWrapper>

					<div className="flex flex-row items-center justify-center gap-4">
						<Translation
							translationKey="card.content.title"
							translationOptions={{
								...translationOptions,
								postfix: {
									...translationOptions.postfix,
									health: companyHealth,
								},
							}}
							Component={Title}
							size="sm"
						/>
						<TrendIcon
							direction={direction}
							query={query}
							postfix={translationOptions?.postfix}
							data={translationOptions?.data}
							size="sm"
						/>
					</div>
				</div>
			</div>
			{translationOptions?.postfix?.onboarding === 'both' ? (
				<Content.Text {...props} className="text-center" />
			) : null}
		</>
	);
});

HealthTrends.displayName = 'HealthTrends';

HealthTrends.propTypes = {
	query: PropTypes.object,
	translationOptions: PropTypes.object,
};

export default HealthTrends;
