import React from 'react';

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { FooterSection } from '@asteria/component-core/wrapper';

import { TranslationService } from '@asteria/language';

import BaseCard from '../../../../base';
import { getVersion } from '../../utils';
import withConfig from '../../withConfig';

/**
 * @typedef { import('../../types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const ViewContent = React.memo(function ViewContent(props) {
	const {
		state,
		type: from,
		translationOptions,
		onAction,
		submitting,
		onboarding,
	} = props;

	const step = state?.feedback?.step;
	const variant = state?.feedback?.variant;

	const card = {
		title: TranslationService.getV2(['card.title'], translationOptions),
	};

	const back = useMutation({
		mutationFn: () =>
			onAction?.('card:feedback:back', {
				type: from,
				onboarding,
				variant,
			}),
	});

	const close = useMutation({
		mutationFn: () =>
			onAction?.('card:feedback:close', {
				type: from,
				onboarding,
				variant,
			}),
	});

	if (step === 0) {
		return (
			<>
				<FooterSection position="first">
					<Button
						variant="secondary"
						analyticsKey="card.feedback.action.back"
						label={TranslationService.getV2(['card.action'], {
							postfix: {
								variant: 'feedback',
								action: 'back',
								from: from,
								step: step + 1,
								feedback: variant,
							},
							data: { card: card },
						})}
						onClick={back.mutate}
					/>
				</FooterSection>
				<FooterSection position="last">
					<Button
						variant="primary"
						analyticsKey="card.feedback.action.send"
						label={TranslationService.getV2(['card.action'], {
							postfix: {
								variant: 'feedback',
								action: 'send',
								from: from,
								step: step + 1,
								feedback: variant,
							},
							data: { card: card },
						})}
						type="submit"
						loading={submitting}
						disabled={submitting}
					/>
				</FooterSection>
			</>
		);
	}

	return (
		<>
			<FooterSection position="first">
				<Button
					variant="secondary"
					analyticsKey="card.feedback.action.back"
					label={TranslationService.getV2(['card.action'], {
						postfix: {
							variant: 'feedback',
							action: 'back',
							from: from,
							step: step + 1,
							feedback: variant,
						},
						data: { card: card },
					})}
					onClick={back.mutate}
				/>
			</FooterSection>
			<FooterSection position="last">
				<Button
					variant="primary"
					onClick={close.mutate}
					analyticsKey="card.feedback.action.close"
					label={TranslationService.getV2(['card.action'], {
						postfix: {
							variant: 'feedback',
							action: 'close',
							from: from,
							step: step + 1,
							feedback: variant,
						},
						data: { card: card },
					})}
				/>
			</FooterSection>
		</>
	);
});

ViewContent.propTypes = {
	state: PropTypes.object,
	type: PropTypes.string,
	translationOptions: PropTypes.object,
	onAction: PropTypes.func,
	submitting: PropTypes.bool,
	onboarding: PropTypes.string,
};

const ViewContentConfig = withConfig(ViewContent, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.footer?.feedback?.view, version);
});

/** @type { React.FC<ChildrenProps> } */
const FeedbackView = React.memo(function FeedbackView(props) {
	return (
		<BaseCard.Footer>
			<ViewContentConfig {...props} />
		</BaseCard.Footer>
	);
});

export default withConfig(FeedbackView, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.footer?.feedback, version);
});

export { ViewContentConfig as ViewContent };
