import React from 'react';

import { useFeature } from '@asteria/component-tools/featureflag';

import BaseCard from '../../../../base';
import { getVersion } from '../../utils';
import withConfig from '../../withConfig';
import ActionConnect from '../components/action-connect';
import ActionDiscard from '../components/action-discard';

/**
 * @typedef { import('../../types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const ViewContent = React.memo(function ViewContent(props) {
	return (
		<>
			<ActionDiscard {...props} />
			<ActionConnect {...props} />
		</>
	);
});

const ViewContentConfig = withConfig(ViewContent, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.footer?.promotion?.view, version);
});

/** @type { React.FC<ChildrenProps> } */
const PromotionView = React.memo(function PromotionView(props) {
	const hasFloatingIntegrationsActionbar = useFeature(
		'streamlined.floating.integrations',
	);

	if (hasFloatingIntegrationsActionbar) {
		return null;
	}

	return (
		<BaseCard.Footer>
			<ViewContentConfig {...props} />
		</BaseCard.Footer>
	);
});

export default withConfig(PromotionView, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.footer?.promotion, version);
});

export { ViewContentConfig as ViewContent };
