import React from 'react';

import PropTypes from 'prop-types';

import TrendIcon from '../../../../components/trends';
import { getVersion } from '../../utils';
import withConfig from '../../withConfig';

/**
 * @typedef { import('../../types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const Trends = React.memo(function Trends(props) {
	const { translationOptions, query, direction } = props;

	return (
		<TrendIcon
			direction={direction}
			query={query}
			postfix={translationOptions?.postfix}
			data={translationOptions?.data}
		/>
	);
});

Trends.propTypes = {
	translationOptions: PropTypes.object,
	query: PropTypes.object,
	direction: PropTypes.string,
};

export default withConfig(Trends, (props) => {
	const { version } = props;

	if (props?.loading || props?.state?.promotion?.visible) {
		return false;
	}

	return getVersion(props?.config?.card?.header?.trends, version, false);
});
