import React from 'react';

import PropTypes from 'prop-types';
import { PolarAngleAxis, RadialBar, RadialBarChart } from 'recharts';

import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useHealthState } from '../../hooks';

import './styles.scss';

/**
 * @typedef { import('./types').Props } Props
 */

const Chart = React.memo(function Chart(props) {
	const { score } = props;

	const graphData = React.useMemo(
		() => [{ value: score, fill: 'var(--color-primary)' }],
		[score],
	);

	return (
		<RadialBarChart
			width={48}
			height={24}
			cy={24}
			innerRadius={16}
			outerRadius={24}
			data={graphData}
			startAngle={180}
			endAngle={0}
		>
			<PolarAngleAxis
				type="number"
				domain={[0, 100]}
				tick={false}
				axisLine={false}
			/>
			<RadialBar
				minAngle="0"
				dataKey="value"
				cornerRadius="16"
				background
			/>
		</RadialBarChart>
	);
});

Chart.propTypes = { score: PropTypes.number };

/** @type { React.FC<Props> } */
const Health = React.memo(function Health(props) {
	const {
		className,
		score,
		extra,
		loading,
		direction = 'vertical',
		label,
	} = props;

	const state = useHealthState(score);

	const translationOptions = React.useMemo(
		() => ({
			postfix: { health: state, ...extra?.postfix },
			data: { score, ...extra?.data },
		}),
		[extra?.data, extra?.postfix, score, state],
	);

	return (
		<TooltipWrapper
			tooltip={
				<Translation
					translationKey="time.selector.health.tooltip"
					translationOptions={translationOptions}
					Component={Text}
				/>
			}
		>
			<div
				className={cn(
					'flex gap-1 items-center',
					{
						'flex-col justify-center': direction === 'vertical',
						'flex-row': direction === 'horizontal',
					},
					'asteria-component__time-selector-health',
					{ [`asteria--state-${state}`]: state },
					className,
				)}
			>
				<Chart {...props} />
				<Translation
					translationKey="time.selector.health"
					translationOptions={translationOptions}
					Component={label?.as ?? Text}
					loading={loading}
					{...label?.props}
				/>
			</div>
		</TooltipWrapper>
	);
});

Health.displayName = 'Health';

Health.propTypes = {
	className: PropTypes.string,
	direction: PropTypes.string,
	extra: PropTypes.object,
	label: PropTypes.object,
	loading: PropTypes.bool,
	score: PropTypes.number,
};

export default Health;
