import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import ActionBar, {
	ActionBarSectionActions,
	ActionBarSectionContent,
} from '@asteria/component-actionbar';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useOnboardingState } from './hooks';

import './styles.scss';

const AddIntegrationActionbar = (props) => {
	const { className, onAction } = props;
	const addedIntegrations = useOnboardingState();

	// Check if it has been dismissed
	const hasBeenDismissed = useSelector((store) => {
		const flags = AppStore.selectors.user(store)?.settings?.flags;
		const dismissedErpOnboarding = flags?.dismissedErpOnboarding;
		const dismissedBankOnboarding = flags?.dismissedBankOnboarding;
		const dismissedAllOnboarding = flags?.dismissedAllOnboarding;

		return {
			erp: dismissedErpOnboarding,
			bank: dismissedBankOnboarding,
			both: dismissedAllOnboarding,
		};
	});

	let missingIntegration = addedIntegrations;
	if (addedIntegrations === 'erp' || hasBeenDismissed.erp) {
		missingIntegration = 'bank';
	} else if (addedIntegrations === 'bank' || hasBeenDismissed.bank) {
		missingIntegration = 'erp';
	}

	const openAddIntegration = React.useCallback(() => {
		onAction?.('card:connect', {
			form: {
				type: missingIntegration === 'none' ? null : missingIntegration,
			},
		});
	}, [onAction, missingIntegration]);

	const openSkipIntegration = React.useCallback(() => {
		onAction?.('modal:feedback:open', {
			type: missingIntegration,
			onSubmit: () => {
				let key = 'dismissedAllOnboarding';

				if (missingIntegration === 'erp') {
					key = 'dismissedErpOnboarding';
				} else if (missingIntegration === 'bank') {
					key = 'dismissedBankOnboarding';
				}

				onAction?.('user:settings:flag:set', {
					flag: key,
					value: true,
				});

				onAction?.('modal:feedback:close');
			},
		});
	}, [onAction, missingIntegration]);

	useEffect(() => {
		const targetEl = document.querySelector('.asteria-wrapper');

		if (!targetEl) {
			return;
		}

		if (missingIntegration !== 'both') {
			targetEl.classList.add(
				'asteria-component__actionbar-add-integrations--active',
			);
		}

		return () => {
			targetEl.classList.remove(
				'asteria-component__actionbar-add-integrations--active',
			);
		};
	}, [missingIntegration]);

	if (missingIntegration === 'erp' && hasBeenDismissed.erp) {
		return null;
	}

	if (missingIntegration === 'bank' && hasBeenDismissed.bank) {
		return null;
	}

	if (missingIntegration === 'both' || hasBeenDismissed.both) {
		return null;
	}

	return (
		<ActionBar
			className={cn(
				'asteria-component__actionbar-add-integrations',
				className,
			)}
		>
			<ActionBarSectionContent
				title={TranslationService.getV2(
					'integrations.add.actionbar.title',
					{
						postfix: {
							type: missingIntegration,
						},
					},
				)}
				content={TranslationService.getV2(
					'integrations.add.actionbar.content',
					{
						postfix: {
							type: missingIntegration,
						},
					},
				)}
			/>
			<ActionBarSectionActions
				actions={[
					{
						onClick: openSkipIntegration,
						label: TranslationService.getV2(
							'integrations.add.actionbar.action.skip',
							{
								postfix: {
									type: missingIntegration,
								},
							},
						),
						variant: 'link',
						size: 'sm',
					},
					{
						onClick: openAddIntegration,
						label: TranslationService.getV2(
							'integrations.add.actionbar.action.add',
							{
								postfix: {
									type: missingIntegration,
								},
							},
						),
						variant: 'primary',
					},
				]}
			/>
		</ActionBar>
	);
};

AddIntegrationActionbar.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default AddIntegrationActionbar;
