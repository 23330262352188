import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import {
	HelpModal as WelcomeHelpModal,
	WelcomeModal,
} from '@asteria/view-welcome';

import { AccountsListModal } from '@asteria/component-accounts';
import ClientOverview from '@asteria/component-client-overview';
import ConversationModal from '@asteria/component-conversation';
import { CurrencyModal } from '@asteria/component-currency';
import ClientEditModal from '@asteria/component-forecaster/modals/client/edit';
import ClientRemoveModal from '@asteria/component-forecaster/modals/client/remove';
import ScenarioAboutModal from '@asteria/component-forecaster/modals/scenario/about';
import ScenarioClearModal from '@asteria/component-forecaster/modals/scenario/clear';
import ScenarioCreateModal from '@asteria/component-forecaster/modals/scenario/create';
import ScenarioEditModal from '@asteria/component-forecaster/modals/scenario/edit';
import ScenarioRemoveModal from '@asteria/component-forecaster/modals/scenario/remove';
import ScenarioResetModal from '@asteria/component-forecaster/modals/scenario/reset';
import ForecasterSettingsModal from '@asteria/component-forecaster/modals/settings';
import TagEditModal from '@asteria/component-forecaster/modals/tag/edit';
import TagRemoveModal from '@asteria/component-forecaster/modals/tag/remove';
import {
	CashflowGraphSettingsModal,
	SpreadModal,
} from '@asteria/component-graph';
import { GuideModal } from '@asteria/component-guide';
import {
	IntegrationConnectModal,
	IntegrationFlowModal,
	IntegrationListModal,
	MissingIntegrationModal,
} from '@asteria/component-integrations-v2';
import { SettingsModal } from '@asteria/component-settings';
import { StatementModal } from '@asteria/component-statement';
import { GuideModal as SupportModal } from '@asteria/component-support';
import { TransactionSettingsModal } from '@asteria/component-transactions/modals/TransactionSettings';

import * as ModalStore from '@asteria/datalayer/stores/modals';
import FeedbackModal from '@asteria/component-feedback/FormModal';

const ModalCollection = (props) => {
	const current = useSelector(ModalStore.selectors.current);

	const type = current?.type;

	const options = React.useMemo(
		() => ({ ...props, ...(current?.data ?? {}) }),
		[current?.data, props],
	);

	if (!type) {
		return null;
	}

	return (
		<>
			<ScenarioAboutModal
				open={type === ModalStore.MODAL_WINDOWS.ScenarioAbout}
				{...options}
			/>
			<ScenarioCreateModal
				open={type === ModalStore.MODAL_WINDOWS.ScenarioCreate}
				{...options}
			/>
			<ScenarioEditModal
				open={type === ModalStore.MODAL_WINDOWS.ScenarioEdit}
				{...options}
			/>
			<ScenarioRemoveModal
				open={type === ModalStore.MODAL_WINDOWS.ScenarioRemove}
				{...options}
			/>
			<ScenarioResetModal
				open={type === ModalStore.MODAL_WINDOWS.ScenarioReset}
				{...options}
			/>

			<ForecasterSettingsModal
				open={type === ModalStore.MODAL_WINDOWS.ForecasterSettings}
				{...options}
			/>
			<SettingsModal
				open={type === ModalStore.MODAL_WINDOWS.Settings}
				{...options}
			/>
			<SupportModal
				open={type === ModalStore.MODAL_WINDOWS.Support}
				{...options}
			/>
			<AccountsListModal
				open={type === ModalStore.MODAL_WINDOWS.BankAccounts}
				{...options}
			/>
			<TagEditModal
				open={type === ModalStore.MODAL_WINDOWS.TagEdit}
				{...options}
			/>
			<TagRemoveModal
				open={type === ModalStore.MODAL_WINDOWS.TagRemove}
				{...options}
			/>
			<ClientEditModal
				open={type === ModalStore.MODAL_WINDOWS.ClientEdit}
				{...options}
			/>
			<ClientRemoveModal
				open={type === ModalStore.MODAL_WINDOWS.ClientRemove}
				{...options}
			/>
			<GuideModal
				open={type === ModalStore.MODAL_WINDOWS.Guide}
				{...options}
			/>
			<CurrencyModal
				open={type === ModalStore.MODAL_WINDOWS.Currency}
				{...options}
			/>

			<ClientOverview
				open={type === ModalStore.MODAL_WINDOWS.ClientOverview}
				{...options}
			/>
			<ScenarioClearModal
				open={type === ModalStore.MODAL_WINDOWS.ScenarioClear}
				{...options}
			/>

			<SpreadModal
				open={type === ModalStore.MODAL_WINDOWS.GraphSpread}
				{...options}
			/>

			<MissingIntegrationModal
				open={type === ModalStore.MODAL_WINDOWS.IntegrationMissing}
				{...options}
			/>
			<IntegrationFlowModal
				open={type === ModalStore.MODAL_WINDOWS.IntegrationFlow}
				{...options}
			/>
			<IntegrationListModal
				open={type === ModalStore.MODAL_WINDOWS.IntegrationList}
				{...options}
			/>
			<IntegrationConnectModal
				open={type === ModalStore.MODAL_WINDOWS.IntegrationConnect}
				{...options}
			/>

			<TransactionSettingsModal
				open={type === ModalStore.MODAL_WINDOWS.TransactionsSettings}
				{...options}
			/>
			<WelcomeModal
				open={type === ModalStore.MODAL_WINDOWS.ViewWelcome}
				{...options}
			/>
			<WelcomeHelpModal
				open={type === ModalStore.MODAL_WINDOWS.WelcomeHelp}
				{...options}
			/>

			<StatementModal
				open={type === ModalStore.MODAL_WINDOWS.Statement}
				{...options}
			/>
			<CashflowGraphSettingsModal
				open={type === ModalStore.MODAL_WINDOWS.CashflowGraphSettings}
				{...options}
			/>
			<ConversationModal
				open={type === ModalStore.MODAL_WINDOWS.Conversation}
				{...options}
			/>
			<FeedbackModal
				open={type === ModalStore.MODAL_WINDOWS.Feedback}
				{...options}
			/>
		</>
	);
};

ModalCollection.displayName = 'ModalCollection';
ModalCollection.propTypes = {
	onAction: PropTypes.func,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default React.memo(ModalCollection);
