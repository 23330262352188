import { useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';

import { useFormValues } from '@asteria/component-form';

import * as AppStore from '@asteria/datalayer/stores/app';

import * as selectors from './selectors';

export function useClients({ query }) {
	return useSelector(
		(store) => {
			const type = selectors.type(store);
			const clients = AppStore.selectors.clients(store, { type: type });
			const statistics = (query?.data ?? []).reduce(
				(acc, object) => ({
					...acc,
					[object?.id]: object?.revenue?.total,
				}),
				{},
			);

			return clients
				.map((object) => {
					const id = object?._id ?? object?.id;
					return { ...object, revenue: statistics?.[id] ?? 0 };
				})
				.sort((a, b) => {
					const revenue = b?.revenue - a?.revenue;

					if (revenue) {
						return revenue;
					}

					return a?.name?.localeCompare(b?.name);
				});
		},
		(a, b) => isEqual(a, b),
	);
}

export function useStatistics({ onSubmit }) {
	const range = useSelector(selectors.sorting, (a, b) => isEqual(a, b));

	const date = useFormValues({ name: 'date' });

	const query = useQuery({
		queryKey: ['clients', range, date, 'statistics'],
		queryFn: async () => {
			const filters = { range: range };

			if (date?.startDate) {
				delete filters.range;

				filters.dates = {
					invoiceDue: { gte: date?.startDate, lte: date?.endDate },
				};
			}

			return onSubmit?.('clients:statistics', filters);
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});

	return query;
}

export function useDateVariantPostfix(date) {
	if (date?.startDate && date?.endDate) {
		return 'full';
	}

	if (date?.startDate) {
		return 'start';
	}

	return 'none';
}
