import React from 'react';

import PropTypes from 'prop-types';

import { getVersion } from '../../utils';
import withConfig from '../../withConfig';

import DefaultView, { ViewContent as DefaultViewContent } from './default';
import FeedbackView, { ViewContent as FeedbackViewContent } from './feedback';
import ImportingView, {
	ViewContent as ImportingViewContent,
} from './importing';
import PromotionView, {
	ViewContent as PromotionViewContent,
} from './promotion';

/**
 * @typedef { import('../../types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const View = React.memo(function Content(props) {
	const { state, importing } = props;

	if (state?.feedback?.visible) {
		return <FeedbackView {...props} />;
	}

	if (state?.promotion?.visible) {
		return <PromotionView {...props} />;
	}

	if (importing) {
		return <ImportingView {...props} />;
	}

	return <DefaultView {...props} />;
});

View.propTypes = {
	state: PropTypes.object,
	importing: PropTypes.bool,
};

export default withConfig(View, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.content, version);
});

export {
	DefaultViewContent,
	FeedbackViewContent,
	PromotionViewContent,
	ImportingViewContent,
};
