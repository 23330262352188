import axios from 'axios';

import AsteriaCore from '@asteria/core';

import { AuthService } from '@asteria/backend-utils-services';

import { clearTokens, getStorage, getTokens, setTokens } from './utils';

const Dataloaders = new Map();

const instance = axios.create();

async function refresh(...args) {
	const storage = getStorage();
	const { refreshToken } = getTokens(storage);

	const response = await AuthService.auth
		.refreshToken({ token: refreshToken })
		.then(({ data }) => data)
		.catch(() => false);

	if (!response?.accessToken) {
		clearTokens(storage);

		const url = new URL('', window.location.origin);
		url.search = window.location.search;

		window.location.replace(url.toString());

		return args.map((response) =>
			Promise.reject(response?.data?.errors?.[0]),
		);
	}

	setTokens(storage, response);
	const { accessToken } = getTokens(storage);

	return Promise.all(
		args.map((response) =>
			instance({
				...response?.config,
				headers: {
					...response?.config?.headers,
					Authorization: `Bearer ${accessToken}`,
				},
			}),
		),
	);
}

async function retry(config) {
	await new Promise((resolve) => setTimeout(resolve, 1_000));

	return instance(config);
}

instance.interceptors.response.use(
	async (response) => {
		const url = response?.config?.url;
		const data = response?.data?.data;
		const errors = response?.data?.errors;

		const code = errors?.[0]?.extensions?.code;

		if (errors?.length) {
			if (code === 'TOO_MANY_REQUESTS') {
				return retry(response.config);
			}

			if (code === 'UNAUTHENTICATED') {
				let dataloader = Dataloaders.get(url);

				const storage = getStorage();
				const { refreshToken } = getTokens(storage);

				if (!refreshToken) {
					throw errors[0];
				}

				if (!dataloader) {
					dataloader = new AsteriaCore.DataLoader(refresh, {
						period: 1_000,
					});

					Dataloaders.set(url, dataloader);
				}

				return dataloader
					.execute(response, { waiting: true })
					.then((data) => {
						Dataloaders.clear();
						return data;
					});
			}

			const err = new Error();
			Object.assign(err, errors[0]);
			err.errors = errors;
			err.data = data;

			throw err;
		}

		return data;
	},
	(error) => {
		if (error?.response?.data?.message === 'Too many requests') {
			return retry(error.config);
		}

		return Promise.reject(error);
	},
);

export default instance;
