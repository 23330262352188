import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

import { Content } from '../../basic';

/**
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const CardContent = React.memo(function CardContent(props) {
	const { translationOptions, loading, importing } = props;

	return (
		<>
			<Content.Title
				{...props}
				loading={loading || importing}
				showLoading
			/>

			{!importing ? (
				<Translation
					translationKey="card.content.text.accuracy"
					translationOptions={translationOptions}
					showWhenEmpty={false}
					Component={Text}
					loading={loading}
				/>
			) : null}

			<Content.Text
				{...props}
				loading={loading || importing}
				showLoading
			/>
		</>
	);
});

CardContent.displayName = 'CardContent';

CardContent.propTypes = {
	translationOptions: PropTypes.object,
	loading: PropTypes.bool,
	importing: PropTypes.bool,
};

export default CardContent;
