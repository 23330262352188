export function getTrendDirection(data, clientId) {
	const source = data?.source?.[clientId] ?? 0;
	const target = data?.target?.[clientId] ?? 0;

	if (source === target) {
		return 'equal';
	}

	if (source > target) {
		return 'up';
	}

	return 'down';
}
