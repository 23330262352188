import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

export function useOnboardingState(connect) {
	const { erp, bank } = useSelector(
		(store) => {
			const erp =
				!!IntegrationStore.selectors
					.integrations(store, {
						type: 'erp',
					})
					.filter(({ config: { connected } }) => connected).length >
					0 || connect?.onboarding === 'erp';

			const bank =
				!!IntegrationStore.selectors
					.integrations(store, {
						type: 'bank',
					})
					.filter(({ config: { connected } }) => connected).length >
					0 || connect?.onboarding === 'bank';

			return { erp, bank };
		},
		(a, b) => isEqual(a, b),
	);

	if (!erp && !bank) {
		return 'none';
	}

	if (erp && bank) {
		return 'both';
	}

	if (erp) {
		return 'erp';
	}

	return 'bank';
}
