import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

import { STATUSES } from './constants';
import InvoiceCardExtraSectionButton from './extraSectionButton';

import './styles.scss';

/**
 * @typedef ExtraSectionProps
 * @property { string } section
 * @property { unknown } data
 * @property { string } startDate
 * @property { string } endDate
 * @property { boolean } show
 * @property { { postfix: unknown, data: unknown } } translationOptions
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 */

/** @type { React.FC<ExtraSectionProps> } */
const InvoiceCardExtraSection = React.memo(function InvoiceCardExtraSection({
	section,
	data,
	show,
	startDate,
	endDate,
	translationOptions,
	onAction,
}) {
	if (!show) {
		return null;
	}

	return (
		<div className="flex flex-col">
			<Translation
				translationKey={['card.section.title']}
				translationOptions={{
					...translationOptions,
					postfix: {
						...translationOptions?.postfix,
						section: section,
					},
				}}
				Component={Text}
			/>
			{STATUSES.filter(
				(key) => !!data?.[key]?.count && key !== 'summary',
			).map((status) => (
				<InvoiceCardExtraSectionButton
					key={status}
					section={section}
					status={status}
					startDate={startDate}
					endDate={endDate}
					translationOptions={translationOptions}
					onAction={onAction}
				/>
			))}
		</div>
	);
});

InvoiceCardExtraSection.propTypes = {
	section: PropTypes.string,
	data: PropTypes.object,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	show: PropTypes.bool,
	translationOptions: PropTypes.object,
	onAction: PropTypes.func,
};

export default InvoiceCardExtraSection;
