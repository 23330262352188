import { get } from 'lodash-es';

import { STATUSES } from './constants';

export function getPath({ type, status = 'FORECAST', category, tag, field }) {
	const path = [type, status];

	if (category) {
		path.push('categories', category);
	}

	if (tag) {
		path.push('tags', tag);
	}

	if (field) {
		path.push(field);
	}

	return path;
}

export function getDataForm(form, { type, category, tag }) {
	return {
		...form,
		value: get(form, getPath({ type, category, tag })),
		status: STATUSES.reduce(
			(acc, status) => ({
				...acc,
				[status]: get(form, getPath({ type, status, category, tag })),
			}),
			{},
		),
	};
}

export function getDiffValue({ sourceTotal, targetTotal }) {
	const diffValue = targetTotal - sourceTotal;
	const diffPercentage = (diffValue / sourceTotal) * 100;

	return {
		amount: diffValue,
		percentage: diffPercentage,
	};
}
