import React from 'react';

import PropTypes from 'prop-types';

import { TableCell, TableRow } from '@asteria/component-core/table';
import Tooltip, { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import Chip from '@asteria/component-chip';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { COLORS } from '../../../constants';
import ClientDetailsTooltip from '../../tooltip-details';

/**
 * @typedef Props
 * @property { number } index
 * @property { unknown } postfix
 * @property { unknown } object
 * @property { React.MouseEventHandler } onChipClick
 * @property { React.MouseEventHandler } onChipMouseEnter
 * @property { React.MouseEventHandler } onChipMouseLeave
 * @property { React.ReactNode } trends
 * @property { string } clientType
 */

/** @type { React.FC<Props> } */
const ClientTableRowView = React.memo(function ClientTableRowView(props) {
	const {
		index,
		postfix,
		object,
		onChipClick,
		onChipMouseEnter,
		onChipMouseLeave,
		trends,
		clientType,
	} = props;

	return (
		<TableRow>
			<TableCell className="asteria--type-counter">
				<Translation
					translationKey="card.content.client.table"
					translationOptions={{
						data: { value: index + 1 },
						postfix: {
							value: 'index',
							variant: postfix?.variant,
						},
					}}
					Component={Text}
					size="sm"
				/>
			</TableCell>
			<TableCell className="asteria--type-name">
				<div>
					<TooltipWrapper
						tooltip={
							<ClientDetailsTooltip
								color={COLORS[index]}
								object={object}
							/>
						}
					>
						<Chip
							label={object?.client?.name}
							colors={COLORS[index]}
							variant="flat"
							onClick={onChipClick}
							onMouseEnter={onChipMouseEnter}
							onMouseLeave={onChipMouseLeave}
						/>
					</TooltipWrapper>
					<div
						className={cn(
							'flex items-center justify-start gap-2 px-4',
							'asteria-component__card-section',
							'asteria--variant-extra',
						)}
					>
						{trends}

						<Tooltip>
							<Text
								className={cn(
									'text-end',
									'asteria--variant-impact',
								)}
								size="sm"
							>
								{TranslationService.getV2(
									['card.content.client.table'],
									{
										postfix: {
											value: 'impact',
											variant: `${clientType}-performance`,
										},
										data: object,
									},
								)}
							</Text>
							<Translation
								translationKey="card.content.client.table"
								translationOptions={{
									data: object,
									postfix: {
										value: 'impact',
										type: 'tooltip',
										variant: `${clientType}-performance`,
									},
								}}
								Component={Text}
								size="sm"
							/>
						</Tooltip>
						<Tooltip>
							<Text
								className={cn(
									'text-end',
									'asteria--variant-accuracy',
								)}
								size="sm"
							>
								{TranslationService.getV2(
									['card.content.client.table'],
									{
										postfix: {
											value: 'accuracy',
											variant: `${clientType}-performance`,
										},
										data: object,
									},
								)}
							</Text>
							<Translation
								className="text-end"
								translationKey="card.content.client.table"
								translationOptions={{
									data: object,
									postfix: {
										value: 'accuracy',
										variant: `${clientType}-performance`,
										type: 'tooltip',
									},
								}}
								Component={Text}
								size="sm"
							/>
						</Tooltip>
					</div>
				</div>
			</TableCell>
			<TableCell className="asteria--type-amount">
				<TextGroup>
					<Translation
						translationKey="card.content.client.table"
						translationOptions={{
							data: object,
							postfix: {
								value: 'actual',
								variant: `${clientType}-performance`,
							},
						}}
						Component={Title}
						size="xxs"
					/>
					{(object?.total?.forecasted ?? null) !== null ? (
						<Translation
							translationKey="card.content.client.table"
							translationOptions={{
								data: object,
								postfix: {
									value: 'forecasted',
									variant: `${clientType}-performance`,
								},
							}}
							Component={Text}
							size="sm"
						/>
					) : null}
				</TextGroup>
			</TableCell>
		</TableRow>
	);
});

ClientTableRowView.propTypes = {
	index: PropTypes.number,
	postfix: PropTypes.object,
	object: PropTypes.object,
	onChipClick: PropTypes.func,
	onChipMouseEnter: PropTypes.func,
	onChipMouseLeave: PropTypes.func,
	trends: PropTypes.node,
	clientType: PropTypes.string,
};

export default ClientTableRowView;
