import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { CompleteScreen } from '@asteria/component-alert';

import { Translation, TranslationService } from '@asteria/language';

/**
 * @typedef { import('../../../types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const FeedbackSuccess = React.memo(function FeedbackSuccess(props) {
	const { type: from, translationOptions } = props;

	const card = {
		title: TranslationService.getV2(['card.title'], translationOptions),
	};

	return (
		<div className="flex flex-col gap-4">
			<CompleteScreen
				level="success"
				icon="check"
				iconPosition="first"
				title={TranslationService.getV2(['card.content.title'], {
					postfix: {
						variant: 'feedback',
						from: from,
						step: 'success',
					},
				})}
			>
				<div className="p-4">
					<Translation
						className="text-center"
						translationKey="card.content.text"
						translationOptions={{
							postfix: {
								variant: 'feedback',
								from: from,
								step: 'success',
							},
							data: { card: card },
						}}
						Component={Text}
					/>
				</div>
			</CompleteScreen>
		</div>
	);
});

FeedbackSuccess.propTypes = {
	type: PropTypes.string,
	translationOptions: PropTypes.object,
};

export default FeedbackSuccess;
