import React from 'react';

import PropTypes from 'prop-types';

import { TextGroup } from '@asteria/component-core/typography';

import { Content } from '../../basic';

/**
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 *
 * @typedef Props
 * @property { 'CUSTOMER' | 'SUPPLIER' } clientType
 */

/** @type { React.FC<ChildrenProps & Props> } */
const ClientPerformanceText = React.memo(function ClientPerformanceText(props) {
	const { loading, importing, state } = props;

	const skeleton = React.useMemo(
		() => ({ animated: loading || importing }),
		[importing, loading],
	);

	const subtitleConfig = React.useMemo(
		() => ({
			card: { content: { subtitle: !state?.promotion?.visible } },
		}),
		[state?.promotion?.visible],
	);

	const titleConfig = React.useMemo(
		() => ({ card: { content: { title: { trends: true } } } }),
		[],
	);

	return (
		<TextGroup className="flex flex-col gap-1">
			<Content.Subtitle
				{...props}
				config={subtitleConfig}
				showLoading
				loading={loading || importing}
				skeleton={skeleton}
			/>
			<Content.Title
				{...props}
				config={titleConfig}
				showLoading
				loading={loading || importing}
				skeleton={skeleton}
			/>
			<Content.Text
				{...props}
				showLoading
				loading={loading || importing}
				skeleton={skeleton}
			/>
		</TextGroup>
	);
});

ClientPerformanceText.propTypes = {
	loading: PropTypes.bool,
	importing: PropTypes.bool,
	state: PropTypes.object,
};

export default ClientPerformanceText;
