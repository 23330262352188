import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { FooterSection } from '@asteria/component-core/wrapper';

import { TranslationService } from '@asteria/language';
import { useToggleState } from '@asteria/utils-funcs/state';

import Card from '../../../base';

import UnderConstructionModal from './modal';

/**
 * @typedef { import('../../basic/types').Props } Props
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const UnderConstructionFooter = React.memo(function UnderConstructionFooter(
	props,
) {
	const { modal, translationOptions, onAction, onSubmit } = props;

	const [detailsModalState, detailsModalActions] = useToggleState();

	if (!modal) {
		return null;
	}

	return (
		<>
			<UnderConstructionModal
				open={detailsModalState}
				onClose={detailsModalActions.close}
				data={translationOptions?.data}
				postfix={translationOptions?.postfix}
				modal={modal}
				onAction={onAction}
				onSubmit={onSubmit}
			/>
			<Card.Footer>
				<FooterSection>
					<Button
						variant="primary"
						label={TranslationService.getV2(
							['card.footer.action'],
							{
								...translationOptions,
								postfix: {
									variant: 'under-construction',
									action: 'learn-more',
									...translationOptions?.postfix,
								},
							},
						)}
						size="sm"
						analyticsKey="card.under-construction.footer.action.learn-more"
						onClick={detailsModalActions.open}
					/>
				</FooterSection>
			</Card.Footer>
		</>
	);
});

UnderConstructionFooter.propTypes = {
	modal: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	translationOptions: PropTypes.object,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default UnderConstructionFooter;
