import { get } from 'lodash-es';

import { TranslationService } from '@asteria/language';

import { STATUSES } from '../constants';

import * as form from './form';

export function getDataForm(data, { type, category, tag }) {
	return {
		...data,
		value: get(data, form.utils.getPath({ type, category, tag })),
		status: STATUSES.reduce(
			(acc, status) => ({
				...acc,
				[status]: get(
					data,
					form.utils.getPath({ type, status, category, tag }),
				),
			}),
			{},
		),
	};
}

export function getDiffValue({ sourceTotal, targetTotal }) {
	const diffValue = targetTotal - sourceTotal;
	const diffPercentage = (diffValue / sourceTotal) * 100;

	return {
		amount: diffValue,
		percentage: diffPercentage,
	};
}

export function getHelpText(translationOptions) {
	const target = translationOptions?.data?.target;

	return (
		!!target?.status?.PAID?.total &&
		TranslationService.getV2(
			['card.extra.section.hint'],
			translationOptions,
		)
	);
}

export { form };
