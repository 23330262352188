import React from 'react';

import PropTypes from 'prop-types';

import BasicCard from '../basic';

import { useRequest } from './hooks';
import ForecastStatusContent from './v1/content';

import './styles.scss';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { ChildrenProps['config'] } */
const CONFIG = {
	onboarding: { required: ['erp', 'bank'], optional: 'both' },
	card: {
		content: {
			default: {
				view: (props) => <ForecastStatusContent {...props} />,
			},
		},
	},
};

function usePromotionState(props) {
	const { useDefaultValue, query } = props;

	const defaultValue = useDefaultValue?.(props);

	const exists = Object.values(query?.data?.target).some((statuses) =>
		Object.values(statuses).some(({ total }) => total),
	);

	return React.useMemo(() => {
		if (query?.isFetching) {
			return defaultValue;
		}

		if (!exists) {
			return { visible: true, variant: 'card' };
		}

		return { visible: false, variant: null };
	}, [defaultValue, exists, query?.isFetching]);
}

/** @type { React.FC<Props> } */
const ForecastStatusCard = React.memo(function ForecastStatusCard(props) {
	const query = useRequest(props);

	return (
		<BasicCard
			{...props}
			type="forecast-status"
			query={query}
			config={CONFIG}
			usePromotionState={usePromotionState}
		/>
	);
});

ForecastStatusCard.displayName = 'ForecastStatusCard';

ForecastStatusCard.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default ForecastStatusCard;
