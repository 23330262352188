import React from 'react';

import Content from './content';
// import Footer from './footer';
import Header from './header';

export function compare(type, node) {
	if (Object.is(node?.type, React.Fragment)) {
		return (node?.props?.children ?? []).some((node) =>
			compare(type, node),
		);
	}

	if (type === 'header') {
		return Object.is(node?.type, Header);
	}

	if (type === 'content') {
		return Object.is(node?.type, Content);
	}

	// if (type === 'footer') {
	// 	return Object.is(node?.type, Footer);
	// }

	return false;
}

export function getVersion(value, version, defaultValue) {
	if (typeof value === 'object') {
		const hasVersionKey = Object.keys(value).some((key) =>
			key.startsWith('v'),
		);

		if (hasVersionKey) {
			return value?.[`v${version}`] ?? defaultValue;
		}
	}

	return value ?? defaultValue;
}
