import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { useCardPinning } from '../../../../hooks';
import { getVersion } from '../../utils';
import withConfig from '../../withConfig';

/**
 * @typedef { import('../../types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const PinButton = React.memo(function PinButton({ onAction, type }) {
	const pinned = useCardPinning({ type: type });

	const onClick = React.useCallback(
		() => onAction?.('card:pin', { type }),
		[onAction, type],
	);

	return (
		<Button
			size="sm"
			icon={pinned ? 'pin-straight' : 'pin-rotated'}
			onClick={onClick}
			analyticsKey={`card.${type}.pin`}
		/>
	);
});

PinButton.propTypes = { onAction: PropTypes.func, type: PropTypes.string };

export default withConfig(PinButton, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.header?.postfix?.pin, version);
});
