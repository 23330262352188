import { useMemo } from 'react';

import { get } from 'lodash-es';

import { useFormValues } from '@asteria/component-form';

import * as FormatUtils from '@asteria/utils-funcs/format';

import { useTrendsDirection } from '../../../hooks';
import { getDataForm, getDiffValue } from '../../../utils';
import { getPath } from '../../../utils/form/utils';

export function useTranslationOptions(base, { type, category, tag }) {
	const path = getPath({ type, category, tag });

	const source = get(base?.data?.source, path);
	const target = get(base?.data?.target, path);

	const [total, probability] = useFormValues({
		name: [
			path.concat('total').join('.'),
			path.concat('probability').join('.'),
		],
	});

	const trends = useTrendsDirection(total, source?.total);

	return useMemo(() => {
		const data = base?.data;

		return {
			source: source,
			target: target,
			form: { total, probability },
			trends: trends,
			options: {
				...base,
				postfix: {
					...base?.postfix,
					section: 'details',
					'section-type': type,
					'section-category': category,
					'section-tag': tag,

					trends: trends,
				},
				data: {
					...data,

					category:
						category && tag
							? FormatUtils.formatTag({ type, category, tag })
							: null,

					source: getDataForm(data?.source, { type }),
					target: getDataForm(data?.target, { type }),
					form: { ...data?.form, value: { total, probability } },

					diff: {
						...data?.diff,
						value: getDiffValue({
							sourceTotal: source?.total ?? 0,
							targetTotal: total ?? 0,
						}),
					},
				},
			},
		};
	}, [base, category, probability, source, tag, target, total, trends, type]);
}
