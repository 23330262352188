import React from 'react';

import PropTypes from 'prop-types';

import TrendIcon from '../../../components/trends';

import InvoiceExtraActions from './extraActions';
import InvoiceCardExtraSection from './extraSection';
import InvoicesContentSummary from './summary';

import './styles.scss';

/**
 * @typedef Props
 * @property { string } className
 * @property { string } startDate
 * @property { string } endDate
 * @property { React.ReactNode | (options: ChildrenOptions) => React.ReactNode } children
 * @property { Partial<{ as: React.ReactNode, props: unknown }> } [wrapper]
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 */

/** @type { React.FC<ChildrenOptions> } */
const InvoicesExtra = React.memo(function InvoicesExtra(props) {
	const {
		query,
		translationOptions,
		loading,
		startDate,
		endDate,
		onAction,
		onSubmit,
	} = props;

	return (
		<div className="flex flex-row gap-4">
			<div className="flex flex-col gap-2">
				<TrendIcon {...props} />
				<InvoicesContentSummary {...props} />
			</div>

			<div className="flex flex-col gap-4">
				<InvoiceCardExtraSection
					section="customers"
					startDate={startDate}
					endDate={endDate}
					data={query?.data?.source?.customers}
					show={!!query?.data?.source?.customers?.summary?.count}
					translationOptions={translationOptions}
					onAction={onAction}
					onSubmit={onSubmit}
				/>
				<InvoiceCardExtraSection
					section="suppliers"
					startDate={startDate}
					endDate={endDate}
					data={query?.data?.source?.suppliers}
					show={!!query?.data?.source?.suppliers?.summary?.count}
					translationOptions={translationOptions}
					onAction={onAction}
					onSubmit={onSubmit}
				/>
				<InvoiceExtraActions
					query={query}
					translationOptions={translationOptions}
					loading={loading}
				/>
			</div>
		</div>
	);
});

InvoicesExtra.propTypes = {
	query: PropTypes.object,
	translationOptions: PropTypes.object,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	loading: PropTypes.bool,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default InvoicesExtra;
