import React from 'react';

import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';
import { Content as BaseContent } from '@asteria/component-core/wrapper';

import { FeedbackRating } from '@asteria/component-feedback';
import {
	Wrapper as FormWrapper,
	Input,
	useFormValues,
} from '@asteria/component-form';

import { Translation, TranslationService } from '@asteria/language';

/**
 * @typedef { import('../../../types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const FeedbackDismissForm = React.memo(function FeedbackDismissForm(props) {
	const { type: from, translationOptions, state } = props;

	const { setValue } = useFormContext();

	const ratingValue = useFormValues({ name: 'feedback.rating' });

	const onRatingChange = React.useCallback(
		(value) => setValue('feedback.rating', value),
		[setValue],
	);

	const card = {
		title: TranslationService.getV2(['card.title'], translationOptions),
	};

	return (
		<FormWrapper>
			<BaseContent>
				<Translation
					translationKey="card.content.text"
					translationOptions={{
						postfix: {
							variant: 'feedback',
							name: 'title',
							from: from,
							feedback: 'dismiss',
						},
						data: { card: card },
					}}
					Component={Text}
				/>
				<Input
					name="feedback.title"
					placeholder={TranslationService.getV2(
						['card.input.placeholder'],
						{
							postfix: {
								variant: 'feedback',
								name: 'title',
								from: from,
								feedback: 'dismiss',
							},
							data: { card: card },
						},
					)}
				/>
				<Input
					name="feedback.description"
					type="textarea"
					placeholder={TranslationService.getV2(
						['card.input.placeholder'],
						{
							postfix: {
								variant: 'feedback',
								name: 'description',
								from: from,
								feedback: 'dismiss',
							},
							data: { card: card },
						},
					)}
				/>
				{state?.feedback?.rating ? (
					<FeedbackRating
						value={ratingValue}
						minText={TranslationService.getV2(['label'], {
							postfix: {
								variant: 'min',
								from: from,
								step: 'feedback',
							},
							data: { card: card },
						})}
						maxText={TranslationService.getV2(['label'], {
							postfix: {
								variant: 'max',
								from: from,
								feedback: 'dismiss',
							},
							data: { card: card },
						})}
						onChange={onRatingChange}
					/>
				) : null}
			</BaseContent>
		</FormWrapper>
	);
});

FeedbackDismissForm.propTypes = {
	type: PropTypes.string,
	translationOptions: PropTypes.object,
	state: PropTypes.object,
};

export default FeedbackDismissForm;
