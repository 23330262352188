import React from 'react';

import PropTypes from 'prop-types';

import { Text, TextGroup } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

import './styles.scss';

/** @type { React.FC<Pick<ChildrenOptions, 'query' | 'translationOptions' | 'loading'>> } */
const InvoiceExtraActions = React.memo(function InvoiceExtraActions({
	query,
	translationOptions,
	loading,
}) {
	const actions = query?.data?.health?.actions ?? [];

	if (!actions?.length) {
		return null;
	}

	return (
		<TextGroup>
			{actions.map((action) => (
				<Translation
					key={action?.type}
					translationKey="card.section.hint"
					translationOptions={{
						...translationOptions,
						postfix: {
							...translationOptions?.postfix,
							action: action?.type,
							...action?.postfix,
						},
						data: {
							...translationOptions?.data,
							action: action?.data,
							score: action?.score,
						},
					}}
					Component={Text}
					loading={loading}
				/>
			))}
		</TextGroup>
	);
});

InvoiceExtraActions.propTypes = {
	query: PropTypes.object,
	translationOptions: PropTypes.object,
	loading: PropTypes.bool,
};

export default InvoiceExtraActions;
