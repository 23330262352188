import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';
import Contenter from '@asteria/component-tools/contenter';

import { Translation, TranslationService } from '@asteria/language';

/**
 * @typedef Props
 * @property { string } className
 * @property { unknown } data
 * @property { unknown } postfix
 * @property { boolean | { title: string, content: unknown[] } } modal
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 * @property { boolean } open
 * @property { (event: MouseEvent) => void } onClose
 */

/** @type { React.FC<Props> } */
const ModalContent = React.memo(function ModalContent(props) {
	const { onClose, data, postfix, modal, onAction, onSubmit } = props;

	const actions = React.useMemo(
		() => ({ onAction, onSubmit }),
		[onAction, onSubmit],
	);

	return (
		<Wrapper scroll>
			<Header onClose={onClose}>
				{TranslationService.getV2(['modal.card.title', modal?.title], {
					default: modal?.title,
					postfix: { variant: 'under-construction', ...postfix },
					data: data,
				})}
			</Header>
			<Content>
				{modal?.content?.length ? (
					<Contenter
						content={modal?.content}
						data={data}
						actions={actions}
					/>
				) : (
					<Translation
						translationKey="modal.card.content"
						translationOptions={{
							postfix: {
								variant: 'under-construction',
								...postfix,
							},
							data: data,
						}}
						Component={Text}
					/>
				)}
			</Content>
			<Footer>
				<FooterSection>
					<Button
						variant="secondary"
						label={TranslationService.getV2(['modal.card.action'], {
							postfix: {
								variant: 'under-construction',
								action: 'close',
								...postfix,
							},
							data: data,
						})}
						onClick={onClose}
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

ModalContent.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	data: PropTypes.object,
	postfix: PropTypes.object,
	modal: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			title: PropTypes.string,
			content: PropTypes.arrayOf(PropTypes.object),
		}),
	]),
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

/** @type { React.FC<Props> } */
const UnderConstructionModal = React.memo(function UnderConstructionModal(
	props,
) {
	const { open, onClose } = props;

	return (
		<Modal open={open} onClose={onClose}>
			<ModalContent {...props} />
		</Modal>
	);
});

UnderConstructionModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	data: PropTypes.object,
	postfix: PropTypes.object,
	modal: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			title: PropTypes.string,
			content: PropTypes.arrayOf(PropTypes.object),
		}),
	]),
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default UnderConstructionModal;
