import { LanguageService } from '@asteria/backend-utils-services';

export async function fetch({
	accessToken,
	language,
	partnerId,
	themeId,
	signal,
}) {
	return LanguageService.translation
		.fetchOne(
			{
				code: language,
				partnerId: partnerId,
				themeId: themeId || partnerId,
				signal: signal,
				fields: `translations`,
			},
			{ token: accessToken },
		)
		.then(({ translations }) => translations)
		.catch(() => ({}));
}
