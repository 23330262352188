import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import { Content } from '../../basic';

import Chart from './chart';

/**
 * @typedef { import('../../basic/types').Props } Props
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const AccountCardContent = React.memo(function AccountCardContent(props) {
	const { loading, importing, state } = props;

	return (
		<div className="flex flex-col gap-4">
			<Content.Title
				{...props}
				showLoading
				loading={loading || importing || state?.promotion?.visible}
				skeleton={{
					className: cn('w-40 h-8 items-center justify-center'),
					animated: loading || importing,
				}}
			/>
			<Chart {...props} />
			<Content.Text
				{...props}
				showLoading
				loading={loading || importing || state?.promotion?.visible}
				skeleton={{
					className: cn('w-full items-center justify-center'),
					animated: loading || importing,
					count: 3,
				}}
			/>
			<Content.Loading {...props} />
		</div>
	);
});

AccountCardContent.propTypes = {
	loading: PropTypes.bool,
	importing: PropTypes.bool,
	state: PropTypes.object,
};

export default AccountCardContent;
