import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

/**
 * @typedef { import('./types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const FeedbackButtons = React.memo(function FeedbackButtons(props) {
	const { onAction, state } = props;

	const thumbsUp = React.useCallback(
		() => onAction?.('card:rate', { rating: 5 }),
		[onAction],
	);

	const thumbsDown = React.useCallback(
		() => onAction?.('card:rate', { rating: 1 }),
		[onAction],
	);

	if (state?.feedback?.visible) {
		return null;
	}

	return (
		<div
			className={cn(
				'w-full justify-center flex',
				'asteria-component__card-section',
				'asteria--variant-card-thumbs',
			)}
		>
			<Group
				direction="horizontal"
				horizontalAlign="center"
				verticalAlign="center"
				flex
			>
				<Button
					icon="thumbs-up"
					size="sm"
					iconSize="md"
					onClick={thumbsUp}
				/>
				<Button
					icon="thumbs-down"
					size="sm"
					iconSize="md"
					onClick={thumbsDown}
				/>
			</Group>
		</div>
	);
});

FeedbackButtons.displayName = 'FeedbackButtons';

FeedbackButtons.propTypes = {
	onAction: PropTypes.func,
	state: PropTypes.object,
};

export default FeedbackButtons;
