import React from 'react';

import PropTypes from 'prop-types';

import { Text as BaseText } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

import { getVersion } from '../../utils';
import withConfig from '../../withConfig';

/**
 * @typedef { import('../../types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps & { showLoading?: boolean, translationKey?: string | string[] }> } */
const Text = React.memo(function Text(props) {
	const {
		className,
		translationOptions,
		state,
		loading,
		showLoading,
		skeleton,
		translationKey: $translationKey,
	} = props;

	const translationKey = React.useMemo(() => {
		if ($translationKey) {
			return $translationKey;
		}

		let key = 'card.content.text';

		if (state?.promotion?.visible) {
			key = ['card.content.text', 'card.content.text.promotion'];
		}

		if (state?.feedback?.visible) {
			key = ['card.content.text', 'card.content.text.feedback'];
		}

		return key;
	}, [$translationKey, state?.feedback?.visible, state?.promotion?.visible]);

	return (
		<Translation
			translationKey={translationKey}
			translationOptions={translationOptions}
			showWhenEmpty={false}
			Component={BaseText}
			size="sm"
			loading={showLoading && loading}
			skeleton={skeleton}
			className={className}
		/>
	);
});

Text.propTypes = {
	className: PropTypes.string,
	translationOptions: PropTypes.object,
	state: PropTypes.object,
	loading: PropTypes.bool,
	showLoading: PropTypes.bool,
	skeleton: PropTypes.object,
	translationKey: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
};

export default withConfig(Text, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.content?.text, version);
});
