import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { useSelector, useStore } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { createSelector } from 'reselect';

import PropTypes from 'prop-types';

import ComponentService from '@asteria/component-tools/contenter/service';

import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import {
	findParentByClassname,
	findScrollingParent,
} from '@asteria/utils-funcs/node';

// import Button from '../button';
import AsteriaContext from '../context';
import { Text } from '../typography';
import { animationListener } from '../utils';
import ElementAnchor from '../utils/elementAnchor';

import './index.scss';

const selectors = {
	isHidden: createSelector(
		(store) => store?.app?.user?.settings?.flags,
		(_, key) => key,
		(flags, key) => {
			if (typeof key === 'string') {
				return flags?.tooltip?.[key] ?? false;
			}

			return false;
		},
	),
};

const TooltipContent = (props) => {
	const { className, children } = props;

	return (
		<div className={cn('asteria-component__tooltip--content', className)}>
			{children}
		</div>
	);
};

TooltipContent.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

TooltipContent.displayName = 'TooltipContent';

const TooltipView = React.forwardRef(function TooltipView(props, ref) {
	const { className, target, size, align, content, children } = props;

	return (
		<div ref={ref} className={cn('asteria-component__tooltip', className)}>
			{/* {onClose && <Button icon="close" onClick={onClose} />} */}
			{target ? (
				typeof children === 'string' ? (
					<Text size={size} align={align}>
						{children}
					</Text>
				) : (
					children
				)
			) : typeof content === 'string' ? (
				<Text size={size} align={align}>
					{content}
				</Text>
			) : (
				content
			)}
			<div
				data-popper-arrow
				className="asteria-component__tooltip__arrow"
			/>
		</div>
	);
});

TooltipView.propTypes = {
	className: PropTypes.string,
	target: PropTypes.string,
	size: PropTypes.string,
	align: PropTypes.string,
	children: PropTypes.node,
	content: PropTypes.string,
};

const Tooltip = (props) => {
	const {
		className,
		label,
		children,
		open = false,
		custom,
		onOpen,
		onClose,
		variant,
		onMouseEnter = onOpen,
		onMouseLeave = onClose,
		target,
		targetRef,
		analyticsKey,
		placement = 'top',
		offset,
		size,
		align,

		once,
	} = props;

	const { onAction } = React.useContext(AsteriaContext);

	const ref = useRef(null);
	const scrollingRef = useRef(null);
	const tooltipRef = useRef(null);
	const [openState, setOpenState] = useState(open);
	const [isHidden, setHidden] = React.useState(false);

	const store = useStore();

	const isTooltipHidden = useSelector((store) =>
		selectors.isHidden(store, once),
	);

	React.useEffect(() => {
		function callback(event) {
			if (event?.keyCode === 8 && event?.ctrlKey) {
				handleClose?.(event);
			}
		}

		if (localStorage.getItem('AsteriaHoldTooltip')) {
			window.addEventListener('keydown', callback);

			return () => {
				window.removeEventListener('keydown', callback);
			};
		}
	}, []);

	React.useEffect(() => {
		setHidden(isTooltipHidden);
	}, [isTooltipHidden]);

	React.useEffect(() => {
		const isTooltipHidden = selectors.isHidden(store.getState(), once);

		setHidden(isTooltipHidden);
	}, [once, store]);

	useEffect(() => {
		setOpenState(open);
	}, [open]);

	let handleClose;

	const handleParentScroll = React.useCallback(
		(event) => handleClose?.(event),
		[handleClose],
	);

	const handleOpen = useCallback(
		(event) => {
			// event.stopPropagation();
			// event.preventDefault();

			const node = findScrollingParent(event?.target?.parentNode);

			if (node) {
				scrollingRef.current = node;

				scrollingRef.current.addEventListener(
					'scroll',
					handleParentScroll,
				);
			}

			setOpenState(true);
			onMouseEnter?.(event);
		},
		[handleParentScroll, onMouseEnter],
	);

	handleClose = useCallback(
		(event) => {
			// event.stopPropagation();
			// event.preventDefault();

			if (localStorage.getItem('AsteriaHoldTooltip')) {
				if (event?.ctrlKey && event?.keyCode !== 8) {
					return false;
				}
			}

			if (scrollingRef.current) {
				scrollingRef.current.removeEventListener(
					'scroll',
					handleParentScroll,
				);

				scrollingRef.current = null;
			}

			if (!open) {
				setOpenState(false);
				onMouseLeave?.(event);
			}

			if (once) {
				setHidden(true);

				if (typeof once === 'string') {
					onAction?.('tooltip:hide', once);
				}

				if (open) {
					onMouseLeave?.(event);
				}
			}
		},
		[handleParentScroll, onAction, onMouseLeave, once, open],
	);

	const elements = React.Children.toArray(children);

	const TargetElement = elements?.[0];
	const content =
		elements.length > 1 ? children[1] : <Text size={size}>{label}</Text>;

	const childProps = useMemo(() => {
		const onMouseEnter = TargetElement?.props?.onMouseEnter;
		const onMouseLeave = TargetElement?.props?.onMouseLeave;
		const onMouseMove = TargetElement?.props?.onMouseMove;
		const onMouseUp = TargetElement?.props?.onMouseUp;
		const className = TargetElement?.props?.className;

		return {
			className: cn(className, 'asteria-component__tooltip-target'),
			onMouseEnter: (event) => {
				const node = findParentByClassname(
					event.target,
					'asteria-component__tooltip-target',
				);

				if (node?.isSameNode?.((targetRef ?? ref)?.current)) {
					handleOpen(event);
					return onMouseEnter?.(event);
				}
			},
			onMouseMove: (event) => {
				const node = findParentByClassname(
					event.target,
					'asteria-component__tooltip-target',
				);

				if (!node?.isSameNode?.((targetRef ?? ref)?.current)) {
					if (openState) {
						handleClose?.(event);
						onMouseLeave?.(event);
					}
				} else {
					if (!openState) {
						handleOpen(event);
						onMouseEnter?.(event);
					}
				}

				return onMouseMove?.(event);
			},
			onMouseUp: (event) => {
				handleClose?.(event);
				return onMouseUp?.(event);
			},
			onMouseLeave: (event) => {
				handleClose?.(event);
				return onMouseLeave?.(event);
			},
			ref: targetRef ?? ref,
		};
	}, [
		targetRef,
		openState,
		handleOpen,
		TargetElement?.props?.onMouseEnter,
		TargetElement?.props?.onMouseLeave,
		TargetElement?.props?.onMouseMove,
		TargetElement?.props?.onMouseUp,
		TargetElement?.props?.className,
		handleClose,
	]);

	useEffect(() => {
		if (openState && analyticsKey) {
			Analytics.event('tooltip', {
				label: label,
				analyticsKey: analyticsKey,
			});
		}
	}, [analyticsKey, label, openState]);

	if ((elements.length === 1 && !label && !target) || isHidden) {
		return TargetElement;
	}

	if (!target && (!content || content.length === 0 || !TargetElement)) {
		return null;
	}

	return (
		<>
			{!target && React.cloneElement(TargetElement, childProps)}
			<CSSTransition
				in={openState}
				appear
				unmountOnExit
				classNames="my-node"
				addEndListener={animationListener}
			>
				<ElementAnchor
					element={target || (targetRef ?? ref)}
					placement={placement}
					className="asteria-component__tooltip-anchor"
					offset={offset}
				>
					<TooltipView
						ref={tooltipRef}
						className={cn(
							className,
							'asteria-component__tooltip',
							`asteria-component__tooltip--position-${placement}`,
							{
								[`asteria-component__tooltip--variant-${variant}`]:
									variant,
							},
							{ 'asteria-state-active': openState },
							{ 'asteria--type-custom': custom },
						)}
						target={target}
						content={content}
						align={align}
						size={size}
					>
						{children}
					</TooltipView>
				</ElementAnchor>
			</CSSTransition>
		</>
	);
};

Tooltip.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	open: PropTypes.bool,
	onOpen: PropTypes.func,
	onClose: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	label: PropTypes.string,
	target: PropTypes.any,
	targetRef: PropTypes.any,
	analyticsKey: PropTypes.string,
	placement: PropTypes.oneOf([
		'top',
		'top-start',
		'top-end',
		'bottom',
		'bottom-start',
		'bottom-end',
		'right',
		'right-start',
		'right-end',
		'left',
		'left-start',
		'left-end',
		'auto',
		'auto-start',
		'auto-end',

		'mouse',
	]),
	variant: PropTypes.oneOf(['default', 'alt']),
	once: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	align: PropTypes.string,
	custom: PropTypes.bool,
};

Tooltip.defaultProps = {
	className: null,
	label: null,
	open: false,
	onOpen: undefined,
	onClose: undefined,
	placement: 'top',
};

Tooltip.displayName = 'Tooltip';

const TooltipWrapper = (props) => {
	const { tooltip, children, ...tooltipProps } = props;

	if (tooltip) {
		return (
			<Tooltip {...tooltipProps}>
				{children}
				{tooltip}
			</Tooltip>
		);
	}

	return children;
};

TooltipWrapper.propTypes = {
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	children: PropTypes.node,
	...Tooltip.propTypes,
};

ComponentService.register('Tooltip', {
	className: { type: 'string' },
	children: { type: 'node' },
	open: { type: 'boolean' },
	onOpen: { type: 'function' },
	onClose: { type: 'function' },
	onMouseEnter: { type: 'function' },
	onMouseLeave: { type: 'function' },
	label: { type: 'string' },
	analyticsKey: { type: 'string' },
	placement: {
		type: 'enum',
		options: [
			'top',
			'top-start',
			'top-end',
			'bottom',
			'bottom-start',
			'bottom-end',
			'right',
			'right-start',
			'right-end',
			'left',
			'left-start',
			'left-end',
			'auto',
			'auto-start',
			'auto-end',

			'mouse',
		],
	},
	variant: { type: 'enum', options: ['default', 'alt'] },
	once: { type: 'boolean' },
	align: { type: 'string' },
	custom: { type: 'boolean' },
});

ComponentService.register('TooltipWrapper', TooltipWrapper, {
	tooltip: { type: 'node' },
	children: { type: 'node' },

	className: { type: 'string' },
	open: { type: 'boolean' },
	onOpen: { type: 'function' },
	onClose: { type: 'function' },
	onMouseEnter: { type: 'function' },
	onMouseLeave: { type: 'function' },
	label: { type: 'string' },
	analyticsKey: { type: 'string' },
	placement: {
		type: 'enum',
		options: [
			'top',
			'top-start',
			'top-end',
			'bottom',
			'bottom-start',
			'bottom-end',
			'right',
			'right-start',
			'right-end',
			'left',
			'left-start',
			'left-end',
			'auto',
			'auto-start',
			'auto-end',

			'mouse',
		],
	},
	variant: { type: 'enum', options: ['default', 'alt'] },
	once: { type: 'boolean' },
	align: { type: 'string' },
	custom: { type: 'boolean' },
});

export default Tooltip;
export { TooltipContent, TooltipWrapper, TooltipView };
