import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import Chip from '@asteria/component-chip';

import { TranslationService } from '@asteria/language';
import useContextSelector from '@asteria/utils-hooks/useContextSelector';

import { ActionContext } from '../../basic/context';

function StatusLine(props) {
	const { value, index, translationOptions, clientType, setActive } = props;

	const onAction = useContextSelector(
		ActionContext,
		({ onAction }) => onAction,
	);

	const onMouseEnter = React.useCallback(() => {
		setActive(index);
	}, [index, setActive]);

	const onMouseLeave = React.useCallback(() => {
		setActive(null);
	}, [setActive]);

	const onClick = React.useCallback(async () => {
		const resp = await onAction?.('card:action', {
			type: 'invoices',
			section: clientType ? clientType : 'both',
			status: value.id.toUpperCase(),
			startDate: translationOptions?.data?.startDate,
		});

		if (resp) {
			return resp;
		}

		const date = translationOptions?.data?.startDate;
		const status = value.id.toUpperCase();

		const filters = [
			{
				type: 'status',
				status: status,
			},
		];

		if (clientType) {
			// as we are using plural for of supplier / suppliers, we remove last letter to make it singular
			filters.push({
				type: 'tag',
				category: { name: '$invoices' },
				tag: { name: `$${clientType.slice(0, -1).toLowerCase()}` },
			});
		}

		onAction?.('go', {
			path: '/',
			state: {
				settings: {
					type: clientType,
					filters: filters,
					selectedDate: date,
				},
			},
		});
	}, [onAction, clientType, value, translationOptions]);

	return (
		<div
			key={value.id}
			className="flex flex-row justify-between items-center"
		>
			<Chip
				colors={value.id}
				onClick={onClick}
				label={TranslationService.getV2('card.content.tag.label', {
					...translationOptions,
					data: value,
					postfix: {
						...translationOptions?.postfix,
						client_type: clientType,
						tag: value.id,
					},
				})}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				variant="flat"
			/>
			<Text>
				{TranslationService.getV2('card.content.tag.value', {
					...translationOptions,
					data: value,
					postfix: {
						...translationOptions?.postfix,
						client_type: clientType,
						tag: value.id,
					},
				})}
			</Text>
		</div>
	);
}

StatusLine.propTypes = {
	value: PropTypes.shape({
		id: PropTypes.string,
	}),
	index: PropTypes.number,
	translationOptions: PropTypes.shape({
		postfix: PropTypes.object,
		data: PropTypes.shape({
			startDate: PropTypes.date,
		}),
	}),
	clientType: PropTypes.string,
	setActive: PropTypes.func,
};

export default StatusLine;
