import React from 'react';

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { Title as BaseTitle } from '@asteria/component-core/typography';

import { useFormValues } from '@asteria/component-form';

import { Translation, TranslationService } from '@asteria/language';

import BaseCard from '../../../../base';
import { getVersion } from '../../utils';
import withConfig from '../../withConfig';

/**
 * @typedef { import('../../types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const ViewContent = React.memo(function ViewContent(props) {
	const { type, state, translationOptions } = props;

	return (
		<Translation
			translationKey="card.title"
			translationOptions={{
				postfix: {
					variant: 'feedback',
					from: type,
					step: state?.feedback?.step + 1,
					feedback: state?.feedback?.variant,
				},
				data: {
					card: TranslationService.getV2(
						['card.title'],
						translationOptions,
					),
				},
			}}
			Component={BaseTitle}
		/>
	);
});

ViewContent.propTypes = {
	type: PropTypes.string,
	state: PropTypes.object,
	translationOptions: PropTypes.object,
};

const ViewContentConfig = withConfig(ViewContent, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.header?.feedback?.view, version);
});

/** @type { React.FC<ChildrenProps> } */
const FeedbackView = React.memo(function FeedbackView(props) {
	const { onAction, onSubmit, state } = props;

	const form = useFormValues();

	const back = useMutation({
		mutationFn: () => onAction?.('card:feedback:back'),
	});

	const close = useMutation({
		mutationFn: () => {
			if (state?.feedback?.variant === 'rate') {
				return onAction?.('card:feedback:back');
			}

			return onSubmit?.('card:feedback:close', {
				...form,
				feedback: { ...form?.feedback, dismiss: true },
			});
		},
	});

	return (
		<BaseCard.Header
			verticalAlign="center"
			onBack={back.mutate}
			onClose={close.mutate}
		>
			<ViewContentConfig {...props} />
		</BaseCard.Header>
	);
});

FeedbackView.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	state: PropTypes.object,
};

export default withConfig(FeedbackView, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.header?.feedback, version);
});

export { ViewContentConfig as ViewContent };
