import React from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Teaser from '@asteria/component-core/teaser';

import { useFeature } from '@asteria/component-tools/featureflag';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const IntegrationsTeaser = React.memo(function IntegrationsTeaser({
	className,
	onAction,
	onSubmit,
}) {
	const hasBankFeature = useFeature('bank-integrations');
	const { state } = useLocation();

	const { erp, bank } = useSelector(
		(store) => {
			const erp = !!IntegrationStore.selectors.integrations(store, {
				type: 'erp',
				connected: true,
			}).length;

			const bank = !!IntegrationStore.selectors.integrations(store, {
				type: 'bank',
				connected: true,
			}).length;

			return { erp, bank: !hasBankFeature || bank };
		},
		(a, b) => isEqual(a, b),
	);

	const actions = React.useMemo(
		() => ({ onAction: onAction, onSubmit: onSubmit }),
		[onAction, onSubmit],
	);

	const translationData = React.useMemo(() => {
		if (state?.data?.type) {
			return {
				postfix: {
					['redirect']: true,
					type: state?.data?.type,
				},
				data: {
					title: state?.data?.title,
				},
			};
		}

		return {};
	}, [state?.data?.title, state?.data?.type]);

	if (erp && bank) {
		return null;
	}

	let path = 'pages.onboarding.teaser.bank';

	if (!erp && !bank) {
		path = 'pages.onboarding.teaser.none';
	} else if (erp) {
		path = 'pages.onboarding.teaser.erp';
	}

	return (
		<Teaser
			className={cn('asteria-component__onboarding-teaser', className)}
			translationData={translationData}
			actions={actions}
			path={path}
			onAction={onAction}
		/>
	);
});

IntegrationsTeaser.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default IntegrationsTeaser;
