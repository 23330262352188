import { useEffect, useMemo, useReducer } from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';

import { useFeature } from '@asteria/component-tools/featureflag';

import * as AppStore from '@asteria/datalayer/stores/app';

import { useOnboardingState, useOnboardingStatus } from '../../hooks';

import * as actions from './actions';

/**
 * @typedef { import('./types').Props } Props
 * @typedef { import('./types').State } State
 */

export function useExperimentalHidden({ experimental }) {
	const hasExperimentalFeature = useFeature('streamline-experimental');

	return experimental && !hasExperimentalFeature;
}

/**
 * @param { Props } props
 */
export function useDefaultCardState(props) {
	const { type } = props;

	const onboarding = useOnboardingState();

	return useSelector(
		(store) => {
			const settings = AppStore.selectors.user(store)?.settings;
			const flags = settings?.flags?.['card:close'];
			const value = flags?.[type]?.[onboarding];

			let visible = true,
				footer = true;

			if (onboarding === 'none') {
				visible = !value;
			} else {
				footer = !value;
			}

			return { visible, footer };
		},
		(a, b) => isEqual(a, b),
	);
}

/**
 * @param { Props } props
 */
export function useDefaultRequiredIntegrations(props) {
	const config = props?.config?.onboarding?.required ?? 'none';

	const onboarding = useOnboardingState();

	if (onboarding === 'both') {
		return false;
	}

	return ![].concat(config).some((key) => onboarding === key);
}

/**
 * @param { Props } props
 */
export function useDefaultOptionalIntegrations(props) {
	const config = props?.config?.onboarding?.optional ?? ['erp', 'bank'];

	const onboarding = useOnboardingState();

	if (onboarding === 'both') {
		return false;
	}

	return ![].concat(config).some((key) => onboarding === key);
}

/**
 * @param { Props } props
 */
export function useDefaultPromotionState(props) {
	const {
		useRequiredIntegrations = useDefaultRequiredIntegrations,
		useOptionalIntegrations = useDefaultOptionalIntegrations,
	} = props;

	const hasRequired = useRequiredIntegrations(props);
	const hasOptional = useOptionalIntegrations(props);

	return useMemo(() => {
		let variant = null;

		if (hasRequired) {
			variant = 'card';
		} else if (hasOptional) {
			variant = 'footer';
		}

		return {
			visible: hasRequired,
			variant: variant,
		};
	}, [hasOptional, hasRequired]);
}

/**
 * @param { Props } props
 */
export function useDefaultFeedbackState() {
	return useMemo(() => ({ visible: false }), []);
}

/**
 * @param { Props } props
 * @returns {{ state: State, dispatch: (action: unknown) => void }}
 */
export function useState(props) {
	const {
		useCardState = useDefaultCardState,
		usePromotionState = useDefaultPromotionState,
		useFeedbackState = useDefaultFeedbackState,
	} = props;

	const promotionState = usePromotionState({
		...props,
		useDefaultValue: useDefaultPromotionState,
	});

	const cardState = useCardState({
		...props,
		useDefaultValue: useDefaultCardState,
	});

	const feedbackState = useFeedbackState({
		...props,
		useDefaultValue: useDefaultFeedbackState,
	});

	const [state, dispatch] = useReducer(
		(state, action) => {
			return [
				actions.reducer.feedback,
				actions.reducer.promotion,
				actions.reducer.card,
			].reduce((state, fn) => fn(state, action), state);
		},
		{ card: cardState, promotion: promotionState, feedback: feedbackState },
	);

	useEffect(() => {
		dispatch({
			type: actions.constants.PROMOTION_RESET,
			payload: promotionState,
		});
	}, [promotionState]);

	useEffect(() => {
		dispatch({
			type: actions.constants.FEEDBACK_RESET,
			payload: feedbackState,
		});
	}, [feedbackState]);

	useEffect(() => {
		dispatch({
			type: actions.constants.CARD_RESET,
			payload: cardState,
		});
	}, [cardState]);

	return { state, dispatch };
}

export function useChildren({ children, ...props }, otherwise) {
	if (children) {
		if (typeof children === 'function') {
			return children({
				...props,
				DefaultComponent: (props) => {
					if (typeof otherwise === 'function') {
						return otherwise(props);
					}

					return otherwise;
				},
			});
		}

		return children;
	}

	if (typeof otherwise === 'function') {
		return otherwise(props);
	}

	return otherwise;
}

/**
 * @param { Props } props
 */
export function useImporting(props) {
	const config = props?.config?.onboarding?.loading;

	const status = useOnboardingStatus();
	const statusERP = useOnboardingStatus({ type: 'erp' });
	const statusBank = useOnboardingStatus({ type: 'bank' });

	if (!config) {
		return status === 'loading';
	}

	if (config === 'bank') {
		return statusBank === 'loading';
	}

	return statusERP === 'loading';
}
