import React from 'react';

import PropTypes from 'prop-types';

import { Title as BaseTitle } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

import { getVersion } from '../../utils';
import withConfig from '../../withConfig';

/**
 * @typedef { import('../../types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const Title = React.memo(function Title(props) {
	const { translationOptions, state } = props;

	const translationKey = React.useMemo(() => {
		let key = 'card.title';

		if (state?.promotion?.visible) {
			key = ['card.title', 'card.title.promotion'];
		}

		if (state?.feedback?.visible) {
			key = ['card.title', 'card.title.feedback'];
		}

		return key;
	}, [state?.feedback?.visible, state?.promotion?.visible]);

	return (
		<Translation
			translationKey={translationKey}
			translationOptions={translationOptions}
			Component={BaseTitle}
		/>
	);
});

Title.propTypes = {
	translationOptions: PropTypes.object,
	state: PropTypes.object,
};

export default withConfig(Title, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.header?.title, version);
});
