import { useMemo } from 'react';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

/** @param { Props } props */
export function useCardState(props) {
	const { useDefaultValue, visible } = props;

	const defaultValue = useDefaultValue?.(props);

	return useMemo(() => {
		if (!visible) {
			return { visible };
		}

		return defaultValue;
	}, [defaultValue, visible]);
}
