import React from 'react';

import PropTypes from 'prop-types';

import { DataPropTypes } from './constants';
import TooltipView from './tooltip.view';

/**
 * @typedef { import('./types').DataProp } DataProp
 */

/** @type { React.FC<{ payload: { payload: { data: DataProp } }[] }> } */
const TooltipProvider = React.memo(function TooltipProvider(props) {
	const { payload } = props;

	const data = React.useMemo(
		() => payload.find((object) => object?.payload?.data)?.payload?.data,
		[payload],
	);

	return <TooltipView data={data} />;
});

TooltipProvider.propTypes = {
	payload: PropTypes.arrayOf(PropTypes.shape(DataPropTypes)),
};

export default TooltipProvider;
