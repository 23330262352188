import React from 'react';

import PropTypes from 'prop-types';

import OnboardingErrorTitle, { useIntegrationError } from './error';
import OnboardingLoadingTitle, { useIntegrationLoading } from './loading';
import OnboardingOutdatedTitle, { useIntegrationOutdated } from './outdated';

/**
 * @typedef { import('../../basic/types').Props } Props
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const OnboardingTitle = React.memo(function OnboardingTitle({
	DefaultComponent,
	...props
}) {
	const integrations = useIntegrationLoading();
	const integrationsError = useIntegrationError();
	const integrationsOutdated = useIntegrationOutdated();

	if (
		integrations.length ||
		integrationsError.length ||
		integrationsOutdated.length
	) {
		return [
			<OnboardingLoadingTitle
				key="onboarding-loading-title"
				integrations={integrations}
				{...props}
			/>,
			<OnboardingErrorTitle
				key="onboarding-error-title"
				integrations={integrationsError}
				{...props}
			/>,
			<OnboardingOutdatedTitle
				key="onboarding-outdated-title"
				integrations={integrationsOutdated}
				visible={!integrationsError.length}
				{...props}
			/>,
		];
	}

	return <DefaultComponent {...props} />;
});

OnboardingTitle.propTypes = { DefaultComponent: PropTypes.node };

export default OnboardingTitle;
