import React from 'react';

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { FooterSection } from '@asteria/component-core/wrapper';

import { TranslationService } from '@asteria/language';

import { useOnboardingState } from '../../../../hooks';
import { getVersion } from '../../utils';
import withConfig from '../../withConfig';

/**
 * @typedef { import('../../types').ChildrenProps } ChildrenProps
 */

function useButtonConfig(props) {
	const { version, config } = props;

	return getVersion(config?.card?.footer?.connect?.button, version);
}

/**
 * @param { ChildrenProps } props
 * @returns { null | 'bank' | 'erp' }
 */
function useConnectType(props) {
	const config = useButtonConfig(props);
	const state = useOnboardingState();

	const isRequired = props?.state?.promotion?.variant === 'card';
	const isOptional = props?.state?.promotion?.variant === 'footer';

	if (config?.connect) {
		if (typeof config?.connect === 'string') {
			return config?.connect;
		}

		if (isRequired && config?.connect?.required) {
			return config?.connect?.required;
		}

		if (isOptional && config?.connect?.optional) {
			return config?.connect?.optional;
		}
	}

	if (state === 'none') {
		return null;
	}

	if (state === 'erp') {
		return 'bank';
	}

	return 'erp';
}

/** @type { React.FC<ChildrenProps> } */
const ActionConnect = React.memo(function ActionConnect(props) {
	const { type, onAction, translationOptions, state } = props;

	const config = useButtonConfig(props);

	const isRequired = state?.promotion?.variant === 'card';
	const isOptional = state?.promotion?.variant === 'footer';

	const connectType = useConnectType(props);

	const click = useMutation({
		mutationFn: () =>
			onAction?.('card:connect', {
				form: { from: type, type: connectType },
			}),
	});

	if (config === false) {
		return null;
	}

	const analyticsKey =
		config?.analyticsKey ?? type
			? `card.${type}.footer.action.connect`
			: 'card.footer.action.connect';

	let postfix = config?.postfix,
		data = config?.data;

	if (isRequired) {
		if (postfix?.required) {
			postfix = postfix?.required;
		}

		if (data?.required) {
			data = data?.required;
		}
	}

	if (isOptional) {
		if (postfix?.optional) {
			postfix = postfix?.optional;
		}

		if (data?.optional) {
			data = data?.optional;
		}
	}

	return (
		<FooterSection position="last">
			<Button
				variant={config?.variant ?? 'secondary'}
				size={config?.size ?? 'sm'}
				label={
					config?.label ??
					TranslationService.getV2(['card.footer.action'], {
						...translationOptions,
						postfix: {
							action: 'connect',
							...translationOptions?.postfix,
							...postfix,
						},
						data: { ...data },
					})
				}
				icon={config?.icon}
				iconPosition={config?.iconPosition}
				onClick={click.mutate}
				analyticsKey={analyticsKey}
				analytics={translationOptions?.data}
			/>
		</FooterSection>
	);
});

ActionConnect.propTypes = {
	type: PropTypes.string,
	onAction: PropTypes.func,
	translationOptions: PropTypes.object,
	state: PropTypes.object,
	config: PropTypes.object,
};

export default withConfig(ActionConnect, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.footer?.connect, version);
});
