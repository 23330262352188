import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

import CardLoading from '../../../components/loading';

import '../styles.scss';

const Loading = React.memo(function Loading(props) {
	const { translationOptions, importing } = props;

	if (!importing) {
		return null;
	}

	return (
		<div className="p-2 w-full">
			<CardLoading {...props} buttonProps={{ size: 'sm' }}>
				<Translation
					translationKey="card.content.chart.loading"
					translationOptions={translationOptions}
					Component={Text}
					size="sm"
				/>
			</CardLoading>
		</div>
	);
});

Loading.displayName = 'Loading';

Loading.propTypes = {
	loading: PropTypes.bool,
	importing: PropTypes.bool,
	query: PropTypes.object,
	translationOptions: PropTypes.object,
};

export default Loading;
