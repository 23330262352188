import React from 'react';

import { Bar } from 'recharts';

export function useUniqueBarCount(children) {
	return React.useMemo(() => {
		const ids = [];
		let count = 0;

		React.Children.forEach(children, (child) => {
			if (React.isValidElement(child) && child.type === Bar) {
				const id = child?.props?.stackId;

				if (id && !ids?.includes(id)) {
					ids.push(id);
					count++;
				} else if (!id) {
					count++;
				}
			}
		});

		return count;
	}, [children]);
}

export function useTogglePlaceholder({ initial, toggle }) {
	const INITIAL_VALUE = initial ?? 90_000;
	const TOGGLE_VALUE = toggle ?? 30_000;

	const [placeholderData, setPlaceholderData] = React.useState([
		{ loading: INITIAL_VALUE },
	]);

	React.useEffect(() => {
		const interval = setInterval(() => {
			setPlaceholderData((prev) => [
				{
					loading:
						prev?.[0]?.loading === INITIAL_VALUE
							? TOGGLE_VALUE
							: INITIAL_VALUE,
				},
			]);
		}, 500);

		return () => clearInterval(interval);
	}, [INITIAL_VALUE, TOGGLE_VALUE]);

	return placeholderData;
}
