import { PartnerService } from '@asteria/backend-utils-services';

/**
 * @param { { accessToken: string, dispatch?: unknown, partnerId: string } } options
 */
export async function fetch(options = {}) {
	const { accessToken, partnerId } = options;

	const partner = await PartnerService.partner
		.fetchOne(
			{ isBulk: true, id: partnerId, fields: `id name` },
			{ token: accessToken },
		)
		.catch(() => {});

	return partner;
}
