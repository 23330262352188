import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import FlexItem from './flex-item';

import './styles.scss';

const FlexBox = React.memo((props) => {
	const {
		className,
		direction: $direction,
		breakpointWidth,
		size = 1,
		breakpointSize = size,
		children,
	} = props;

	const ref = React.useRef(null);

	const [windowWidth, setWindowWidth] = React.useState(9999);

	const style = React.useMemo(
		() => ({
			'--size': breakpointWidth > windowWidth ? size : breakpointSize,
		}),
		[breakpointSize, breakpointWidth, size, windowWidth],
	);

	const handleResize = React.useCallback(() => {
		setWindowWidth(window?.innerWidth);
	}, []);

	React.useLayoutEffect(() => {
		window.addEventListener('resize', handleResize);
		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, [handleResize]);

	const direction = React.useMemo(() => {
		if (breakpointWidth && breakpointWidth > windowWidth) {
			return 'vertical';
		}

		return $direction;
	}, [$direction, breakpointWidth, windowWidth]);

	return (
		<div
			className={cn('asteria-component__flex-line', className, {
				[`asteria--direction-${direction}`]: direction,
			})}
			style={style}
			ref={ref}
		>
			{children}
		</div>
	);
});

FlexBox.displayName = 'FlexBox';

FlexBox.propTypes = {
	className: PropTypes.string,
	direction: PropTypes.string,
	removed: PropTypes.arrayOf(PropTypes.children),
	breakpointWidth: PropTypes.number,
	breakpointSize: PropTypes.number,
	size: PropTypes.number,
	children: PropTypes.node,
};

export default FlexBox;
export { FlexItem };
