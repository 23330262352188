import React from 'react';

import PropTypes from 'prop-types';

import Tooltip from '@asteria/component-core/tooltip';
import { Text, Title } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import TrendIcon from '../../../components/trends';

function Summary(props) {
	const { translationOptions, clientType, query } = props;

	const trendDirection = React.useMemo(() => {
		const source = clientType
			? query?.data?.source?.[clientType]?.summary?.count ?? 0
			: query?.data?.source?.[clientType]?.summary?.count ?? 0;
		const target = clientType
			? query?.data?.target?.summary?.count ?? 0
			: query?.data?.source?.summary?.count ?? 0;

		if (source > target) {
			return 'up';
		}

		return 'down';
	}, [query?.data, clientType]);

	return (
		<div
			className={cn(
				'flex flex-col gap-1 py-4',
				'asteria-component__card-title',
			)}
		>
			<Text size="sm">
				{TranslationService.getV2('card.content.summary.count', {
					...translationOptions,
					postfix: {
						...translationOptions?.postfix,
						client_type: clientType,
					},
				})}
			</Text>
			<div className="flex items-center">
				<Tooltip>
					<Title size="sm">
						{TranslationService.getV2(
							'card.content.summary.total',
							{
								...translationOptions,
								postfix: {
									...translationOptions?.postfix,
									client_type: clientType,
								},
							},
						)}
					</Title>
					<div className="asteria--variant-invoices__total-tooltip flex flex-col">
						{clientType === 'customers' || !clientType ? (
							<div className="flex p-2">
								<Text className="grow">
									{TranslationService.getV2(
										'card.content.summary.total.tooltip.label',
										{
											...translationOptions,
											postfix: {
												...translationOptions?.postfix,
												client_type: 'customers',
											},
										},
									)}
								</Text>
								<Text>
									{TranslationService.getV2(
										'card.content.summary.total.tooltip.total',
										{
											...translationOptions,
											postfix: {
												...translationOptions?.postfix,
												client_type: 'customers',
											},
										},
									)}
								</Text>
							</div>
						) : null}
						{clientType === 'suppliers' || !clientType ? (
							<div className="flex p-2">
								<Text className="grow">
									{TranslationService.getV2(
										'card.content.summary.total.tooltip.label',
										{
											...translationOptions,
											postfix: {
												...translationOptions?.postfix,
												client_type: 'suppliers',
											},
										},
									)}
								</Text>
								<Text>
									{TranslationService.getV2(
										'card.content.summary.total.tooltip.total',
										{
											...translationOptions,
											postfix: {
												...translationOptions?.postfix,
												client_type: 'suppliers',
											},
										},
									)}
								</Text>
							</div>
						) : null}
					</div>
				</Tooltip>

				<TrendIcon
					className="mx-2"
					direction={trendDirection}
					query={query}
					postfix={translationOptions.postfix}
				/>
			</div>

			{/*<Text>
				{TranslationService.getV2(
					'card.content.summary.accuracy',
					{
						...translationOptions,
						postfix: {
							...translationOptions?.postfix,
							client_type: clientType,
						},
					},
				)}
			</Text>*/}
		</div>
	);
}

Summary.propTypes = {
	translationOptions: PropTypes.shape({
		postfix: PropTypes.object,
		data: PropTypes.shape({
			startDate: PropTypes.date,
		}),
	}),
	clientType: PropTypes.string,
	query: PropTypes.object,
};

export default Summary;
