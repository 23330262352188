import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Tooltip from '@asteria/component-core/tooltip';
import { Text, Title } from '@asteria/component-core/typography';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import TrendIcon from '../../../components/trends';
import { getTrendDirection } from '../utils';

/**
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const ClientPerformanceContent = function ClientPerformanceContent(props) {
	const {
		query,
		translationOptions,
		clientType = 'customer',
		onAction,
	} = props;

	const showClient = React.useCallback(
		(client) =>
			onAction?.('card:action', {
				type: `${clientType}-details`,
				client: client,
			}),
		[clientType, onAction],
	);

	const sourceDetails = (
		query?.data?.source?.statistics?.details ?? []
	).reduce((acc, { clientId, count }) => ({ ...acc, [clientId]: count }), {});

	const targetDetails = (
		query?.data?.target?.statistics?.details ?? []
	).reduce((acc, { clientId, count }) => ({ ...acc, [clientId]: count }), {});

	const hasAccuracy = (query?.data?.source?.statistics?.details ?? []).some(
		(object) => (object?.accuracy ?? null) !== null,
	);

	return (
		<div className="asteria-component__client-statistics">
			<div className="grid grid-cols-2">
				{query?.data?.source?.statistics?.best?.total?.actual ? (
					<div className="asteria-component__client-box">
						<Translation
							translationKey="card.content.client.best"
							translationOptions={translationOptions}
							Component={Text}
						/>
						<Button
							size="sm"
							tooltip={TranslationService.getV2(['card.action'], {
								postfix: {
									type: 'client-details',
									variant: `${clientType}-performance`,
									action: 'open-best',
								},
								data: query?.data?.source?.statistics?.best,
							})}
							variant="link"
							label={TranslationService.getV2(['card.action'], {
								postfix: {
									type: 'client-details',
									variant: `${clientType}-performance`,
									action: 'open-best',
								},
								data: query?.data?.source?.statistics?.best,
							})}
							onClick={() =>
								showClient(
									query?.data?.source?.statistics?.best,
								)
							}
						/>
						<Translation
							translationKey="card.content.client.best.total"
							translationOptions={translationOptions}
							Component={Title}
							size="xs"
						/>
					</div>
				) : null}
				{query?.data?.source?.statistics?.worst?.total?.actual ? (
					<div className="asteria-component__client-box">
						<Translation
							translationKey="card.content.client.worst"
							translationOptions={translationOptions}
							Component={Text}
						/>
						<Button
							size="sm"
							variant="link"
							tooltip={TranslationService.getV2(['card.action'], {
								postfix: {
									type: 'client-details',
									variant: `${clientType}-performance`,
									action: 'open-worst',
								},
								data: query?.data?.source?.statistics?.worst,
							})}
							label={TranslationService.getV2(['card.action'], {
								postfix: {
									type: 'client-details',
									variant: `${clientType}-performance`,
									action: 'open-worst',
								},
								data: query?.data?.source?.statistics?.worst,
							})}
							onClick={() =>
								showClient(
									query?.data?.source?.statistics?.worst,
								)
							}
						/>
						<Translation
							translationKey="card.content.client.worst.total"
							translationOptions={translationOptions}
							Component={Title}
							size="xs"
						/>
					</div>
				) : null}
			</div>
			<div
				className={cn('asteria-component__client-performance-table', {
					'asteria--state-accuracy': hasAccuracy,
				})}
			>
				{query?.data?.source?.statistics?.details
					?.slice(0, 5)
					?.map((object, index) => {
						const sourceCount = sourceDetails?.[object?.clientId];
						const targetCount = targetDetails?.[object?.clientId];

						const trends = getTrendDirection(
							{
								source: sourceDetails,
								target: targetDetails,
							},
							object?.clientId,
						);

						return (
							<>
								<Translation
									translationKey="card.content.client.table"
									translationOptions={{
										data: { value: index + 1 },
										postfix: {
											value: 'index',
											variant: `${clientType}-performance`,
										},
									}}
									Component={Text}
									size="sm"
								/>
								<TrendIcon
									size="xs"
									direction={trends}
									query={query}
									postfix={{
										...translationOptions?.postfix,
										type: 'client-table',
										trends: trends,
									}}
									data={{
										...translationOptions?.data,
										trends: {
											count: {
												source: sourceCount,
												target: targetCount,
											},
											percentage: targetCount
												? (Math.abs(
														sourceCount -
															targetCount,
												  ) /
														targetCount) *
												  100
												: 100,
										},
									}}
								/>
								<Button
									size="sm"
									variant="link"
									label={TranslationService.getV2(
										['card.action'],
										{
											postfix: {
												type: 'client-details',
												variant: `${clientType}-performance`,
												action: 'open-client',
											},
											data: object,
										},
									)}
									tooltip={TranslationService.getV2(
										['card.action'],
										{
											postfix: {
												type: 'client-details',
												variant: `${clientType}-performance`,
												action: 'open-client',
											},
											data: object,
										},
									)}
									onClick={() => showClient(object)}
								/>
								<Tooltip>
									<Text className="text-end" size="sm">
										{TranslationService.getV2(
											['card.content.client.table'],
											{
												postfix: {
													value: 'impact',
													variant: `${clientType}-performance`,
												},
												data: object,
											},
										)}
									</Text>
									<Translation
										translationKey="card.content.client.table"
										translationOptions={{
											data: object,
											postfix: {
												value: 'impact',
												type: 'tooltip',
												variant: `${clientType}-performance`,
											},
										}}
										Component={Text}
										size="sm"
									/>
								</Tooltip>
								{hasAccuracy ? (
									<Tooltip>
										<Text className="text-end" size="sm">
											{TranslationService.getV2(
												['card.content.client.table'],
												{
													postfix: {
														value: 'accuracy',
														variant: `${clientType}-performance`,
													},
													data: object,
												},
											)}
										</Text>
										<Translation
											className="text-end"
											translationKey="card.content.client.table"
											translationOptions={{
												data: object,
												postfix: {
													value: 'accuracy',
													variant: `${clientType}-performance`,
													type: 'tooltip',
												},
											}}
											Component={Text}
											size="sm"
										/>
									</Tooltip>
								) : null}

								<div className="flex flex-col items-end">
									<Translation
										translationKey="card.content.client.table"
										translationOptions={{
											data: object,
											postfix: {
												value: 'actual',
												variant: `${clientType}-performance`,
											},
										}}
										Component={Title}
										size="xxs"
									/>
									{(object?.total?.forecasted ?? null) !==
									null ? (
										<Translation
											translationKey="card.content.client.table"
											translationOptions={{
												data: object,
												postfix: {
													value: 'forecasted',
													variant: `${clientType}-performance`,
												},
											}}
											Component={Text}
											size="sm"
										/>
									) : null}
								</div>
							</>
						);
					})}
			</div>
		</div>
	);
};

ClientPerformanceContent.propTypes = {
	query: PropTypes.object,
	translationOptions: PropTypes.object,
	clientType: PropTypes.string,
	onAction: PropTypes.func,
};

export default ClientPerformanceContent;
