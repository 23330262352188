import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { TranslationService } from '@asteria/language';

import './styles.scss';

/**
 * @typedef ContentButtonProps
 * @property { string } status
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { { postfix: unknown, data: unknown } } translationOptions
 */

/** @type { React.FC<ContentButtonProps> } */
const InvoicesContentTextButton = React.memo(
	function InvoicesContentTextButton({
		status,
		onAction,
		translationOptions,
	}) {
		const onClick = React.useCallback(
			() =>
				onAction?.('card:action', {
					type: 'invoices',
					section: 'both',
					status: status,
					startDate: translationOptions?.data?.startDate,
				}),
			[onAction, status, translationOptions?.data?.startDate],
		);

		return (
			<Button
				variant="link"
				label={TranslationService.getV2(['card.content.text.link'], {
					...translationOptions,
					postfix: {
						...translationOptions?.postfix,
						link_type: status,
					},
				})}
				onClick={onClick}
			/>
		);
	},
);

InvoicesContentTextButton.propTypes = {
	status: PropTypes.string,
	onAction: PropTypes.func,
	translationOptions: PropTypes.object,
};

export default InvoicesContentTextButton;
