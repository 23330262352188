import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import { cloneDeep } from 'lodash-es';
import PropTypes from 'prop-types';

import * as AppStore from '@asteria/datalayer/stores/app';

import { useQueryDoneCallback } from '@asteria/utils-hooks/useQueryDoneCallback';

import * as ConfigAPI from '../api/config';

/** @type { React.FC<React.PropsWithChildren<{ partnerId: string, enabled: boolean }>> } */
const ConfigurationWrapper = React.memo(function ConfigurationWrapper(props) {
	const { children, partnerId, enabled } = props;

	const dispatch = useDispatch();

	const accessToken = useSelector(AppStore.selectors.accessToken);

	const query = useQuery({
		queryKey: [
			'widget',
			'configuration',
			partnerId ?? 'NONE',
			accessToken ?? 'NONE',
		],
		queryFn: async () => ConfigAPI.fetch({ accessToken }),
		enabled: !!enabled && !!accessToken,
	});

	useQueryDoneCallback(
		query,
		React.useCallback(
			($data) => {
				const data = cloneDeep($data);

				dispatch?.(AppStore.setConfig(data));
			},
			[dispatch],
		),
	);

	return <>{children}</>;
});

ConfigurationWrapper.propTypes = {
	children: PropTypes.node,
	partnerId: PropTypes.string,
	enabled: PropTypes.bool,
};

export default ConfigurationWrapper;
