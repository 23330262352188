import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { TranslationService } from '@asteria/language';

import { useOnboardingState } from '../../../../hooks';
import { getVersion } from '../../utils';
import withConfig from '../../withConfig';

/**
 * @typedef { import('../../types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const RedirectButton = React.memo(function RedirectButton(props) {
	const { onAction, type, startDate, endDate, translationOptions } = props;

	const onboarding = useOnboardingState();

	const onRedirect = React.useCallback(
		() =>
			onAction?.('card:redirect', {
				title: TranslationService.getV2(
					['card.title'],
					translationOptions,
				),
				type,
				startDate,
				endDate,
				onboarding,
			}),
		[endDate, onAction, onboarding, startDate, translationOptions, type],
	);

	const analytics = React.useMemo(
		() => ({ startDate, endDate }),
		[endDate, startDate],
	);

	return (
		<Button
			size="sm"
			icon="chevron-right"
			onClick={onRedirect}
			analyticsKey={`card.${type}.redirect`}
			analytics={analytics}
		/>
	);
});

RedirectButton.propTypes = {
	onAction: PropTypes.func,
	type: PropTypes.string,
	translationOptions: PropTypes.object,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
};

export default withConfig(RedirectButton, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.header?.postfix?.redirect, version);
});
