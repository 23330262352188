import React from 'react';

export const DateContext = React.createContext({
	value: null,
	startDate: null,
	endDate: null,

	setValue: () => null,
	setRange: () => null,
	reset: () => null,
});
