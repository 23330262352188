import React from 'react';

import { useSelector } from 'react-redux';

import { useMutation } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';

const Loading = React.memo(function Loading(props) {
	const { translationOptions, onSubmit, children, buttonProps } = props;

	const integration = useSelector(
		(store) =>
			IntegrationStore.selectors.integrations(store, {
				status: 'INITIATING',
			})[0],
		isEqual,
	);

	const cancel = useMutation({
		mutationFn: async () =>
			onSubmit('integrations:delete', {
				id: integration?._id ?? integration?.id,
			}),
	});

	return (
		<div className="flex flex-col items-center justify-center">
			{typeof children === 'function'
				? children({ ...props, integration })
				: children}
			<Button
				variant="link"
				label={TranslationService.getV2(
					['card.content.loading.action'],
					{
						...translationOptions,
						postfix: {
							...translationOptions?.postfix,
							action: 'cancel',
						},
					},
				)}
				onClick={cancel.mutate}
				{...buttonProps}
			/>
		</div>
	);
});

Loading.propTypes = {
	translationOptions: PropTypes.object,
	onSubmit: PropTypes.func,
	children: PropTypes.node,
	buttonProps: PropTypes.object,
};

export default Loading;
