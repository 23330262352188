import Title from './components/title';
import Trends from './components/trends';
import View, {
	DefaultViewContent,
	FeedbackViewContent,
	PromotionViewContent,
} from './view';

/**
 * @typedef { import('../types').ChildrenProps } ChildrenProps
 *
 * @typedef Extension
 * @property { typeof Title } Title
 * @property { typeof Trends } Trends
 * @property { typeof DefaultViewContent } DefaultViewContent
 * @property { typeof PromotionViewContent } PromotionViewContent
 * @property { typeof FeedbackViewContent } FeedbackViewContent
 */

/** @type { React.FC<ChildrenProps> & Extension } */
export default Object.assign(View, {
	Title,
	Trends,

	DefaultViewContent,
	PromotionViewContent,
	FeedbackViewContent,
});
