import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

const ResponsiveContainer = React.memo(function ResponsiveContainer(props) {
	const { minWidth, minHeight, children, className, responsiveStyles } =
		props;

	const ref = React.useRef(null);

	const [isHorizontalResponsive, setIsHorizontalResponsive] =
		React.useState(false);
	const [isVerticalResponsive, setIsVerticalResponsive] =
		React.useState(false);

	React.useEffect(() => {
		const handleResize = () => {
			if (ref.current) {
				const { offsetWidth, offsetHeight } = ref.current;
				setIsHorizontalResponsive(offsetWidth < minWidth);

				setIsVerticalResponsive(offsetHeight < minHeight);
			}
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [minWidth, minHeight]);

	return (
		<div
			ref={ref}
			className={cn(className, {
				[`${responsiveStyles}`]:
					(isHorizontalResponsive || isVerticalResponsive) &&
					responsiveStyles,
				'asteria--state-responsive':
					isHorizontalResponsive || isVerticalResponsive,
				'asteria--state-responsive-horizontal': isHorizontalResponsive,
				'asteria--state-responsive-vertical': isVerticalResponsive,
			})}
		>
			{children}
		</div>
	);
});

ResponsiveContainer.displayName = 'ResponsiveContainer';

ResponsiveContainer.propTypes = {
	minWidth: PropTypes.string,
	minHeight: PropTypes.string,
	responsiveStyles: PropTypes.string,
	children: PropTypes.string,
	className: PropTypes.string,
};

export default ResponsiveContainer;
