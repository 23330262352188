import React from 'react';

import PropTypes from 'prop-types';

/** @type { React.FC<{ className: string }> } */
const SpreadDefs = React.memo(function SpreadDefs({ className }) {
	return (
		<defs className={className}>
			<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
				<stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
				<stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
			</linearGradient>
			<filter
				id="spread-blur"
				x="0"
				y="0"
				xmlns="http://www.w3.org/2000/svg"
			>
				<feGaussianBlur in="SourceGraphic" stdDeviation="12" />
			</filter>
			<pattern
				id="spread-normal-pattern"
				width="8"
				height="10"
				patternUnits="userSpaceOnUse"
				patternTransform="rotate(45)"
			>
				<rect
					height="10"
					width="8"
					className="asteria-color__spread-normal"
				/>
				<line
					y2="10"
					x1="1"
					x2="1"
					className="asteria-color__spread-normal-stripe"
				/>
				<line
					y2="10"
					x1="5"
					x2="5"
					className="asteria-color__spread-normal-stripe"
				/>
			</pattern>
			<pattern
				id="spread-negative-pattern"
				width="8"
				height="10"
				patternUnits="userSpaceOnUse"
				patternTransform="rotate(45)"
			>
				<rect
					height="10"
					width="8"
					className="asteria-color__spread-negative"
				/>
				<line
					y2="10"
					x1="1"
					x2="1"
					className="asteria-color__spread-negative-stripe"
				/>
				<line
					y2="10"
					x1="5"
					x2="5"
					className="asteria-color__spread-negative-stripe"
				/>
			</pattern>
		</defs>
	);
});

SpreadDefs.propTypes = { className: PropTypes.string };

export default SpreadDefs;
