import { useContext, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { isPast, isThisMonth } from 'date-fns';
import { isEqual } from 'lodash-es';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { parseDate } from '@asteria/utils-funcs/normalize';

import { DateContext } from './context';
import { formatDate } from './utils';

export function useOnboardingState(connect) {
	const { erp, bank } = useSelector(
		(store) => {
			const erp =
				!!IntegrationStore.selectors
					.integrations(store, {
						type: 'erp',
					})
					.filter(({ config: { connected } }) => connected).length >
				0 || connect?.onboarding === 'erp';

			const bank =
				!!IntegrationStore.selectors
					.integrations(store, {
						type: 'bank',
					})
					.filter(({ config: { connected } }) => connected).length >
				0 || connect?.onboarding === 'bank';

			return { erp, bank };
		},
		(a, b) => isEqual(a, b),
	);

	if (!erp && !bank) {
		return 'none';
	}

	if (erp && bank) {
		return 'both';
	}

	if (erp) {
		return 'erp';
	}

	return 'bank';
}

export function useDateVariant($date) {
	const date = parseDate($date);

	if (isThisMonth(date)) {
		return 'today';
	}

	if (isPast(date)) {
		return 'past';
	}

	return 'future';
}

export function useHealthState(score) {
	let state = 'bad';

	if (score > 20) {
		state = 'acceptable';
	}

	if (score > 40) {
		state = 'good';
	}

	if (score > 60) {
		state = 'great';
	}

	if (score > 80) {
		state = 'perfect';
	}

	return state;
}

export function useTranslationOptions({ query, date, notconnected }) {
	const dateVariant = useDateVariant(date);
	const healthState = useHealthState(query?.data?.health?.score);

	return useMemo(
		() => ({
			data: {
				balance: query?.data?.balance,
				health: query?.data?.health,
				date: date,
			},
			postfix: {
				past: dateVariant === 'past',
				today: dateVariant === 'today',
				future: dateVariant === 'future',

				health: healthState,
				direction: query?.data?.trends?.direction,
				notconnected: notconnected,
			},
		}),
		[
			date,
			dateVariant,
			healthState,
			query?.data?.balance,
			query?.data?.health,
			query?.data?.trends?.direction,
			notconnected,
		],
	);
}

export function useActiveDate() {
	const { value, startDate, endDate } = useContext(DateContext);

	if (value) {
		return formatDate(value);
	}

	if (startDate) {
		return {
			startDate: formatDate(startDate),
			endDate: formatDate(endDate),
		};
	}

	return formatDate(new Date());
}
