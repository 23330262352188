import React from 'react';

import { endOfMonth, formatISO, startOfMonth } from 'date-fns';
import PropTypes from 'prop-types';

import {
	AccountCard,
	ClientPerformanceCard,
	ForecastActionsCard,
	ForecastCard,
	ForecastStatusCard,
	HealthCard,
	IncomingCard,
	InvoicesCard,
	OnboardingCard,
	OutgoingCard,
	ProfitCard,
	SalaryCard,
	UnderConstructionCard,
} from '@asteria/component-card';
import FlexBox, { FlexItem } from '@asteria/component-flex-box';
import { AddIntegrationActionbar } from '@asteria/component-integrations-v2';
import { SupportFloatButton } from '@asteria/component-support';
import TimeSelector from '@asteria/component-time-selector';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import { cn } from '@asteria/utils-funcs/classes';
import { parseDate } from '@asteria/utils-funcs/normalize';

import StreamlinedViewFeedback from './feedback';
import StreamlineTitle from './title';

import './styles.scss';

/**
 * @typedef Props
 * @property { string } className
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 */

/** @type { React.FC<{ children: React.ReactNode, fit?: boolean }> } */
const CardWrapper = function CardWrapper(props) {
	const { children, fit } = props;

	if (fit) {
		return (
			<FlexItem {...props}>
				<div className="w-full">{children}</div>
			</FlexItem>
		);
	}

	return <FlexItem {...props} />;
};

CardWrapper.propTypes = { children: PropTypes.node, fit: PropTypes.bool };

function useRange(date) {
	if (typeof date === 'string') {
		return {
			startDate: formatISO(startOfMonth(parseDate(date)), {
				representation: 'date',
			}),
			endDate: formatISO(endOfMonth(parseDate(date)), {
				representation: 'date',
			}),
		};
	}

	return {
		startDate: date?.startDate,
		endDate: date?.endDate,
	};
}

/** @type { React.FC<Props> } */
const RenderGridItem = React.memo(function RenderGridItem({
	itemId,
	range,
	onAction,
	onSubmit,
}) {
	if (itemId === 'health') {
		return (
			<HealthCard
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true },
				}}
			/>
		);
	}

	if (itemId === 'forecast-actions') {
		return (
			<ForecastActionsCard
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true },
				}}
			/>
		);
	}

	if (itemId === 'account') {
		return (
			<AccountCard
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true },
				}}
			/>
		);
	}

	if (itemId === 'incoming') {
		return (
			<IncomingCard
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true },
				}}
			/>
		);
	}

	if (itemId === 'invoices') {
		return (
			<InvoicesCard
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true },
				}}
			/>
		);
	}

	if (itemId === 'client-performance') {
		return (
			<ClientPerformanceCard
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true },
				}}
			/>
		);
	}

	if (itemId === 'profit') {
		return (
			<ProfitCard
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true },
				}}
			/>
		);
	}

	if (itemId === 'under-construction') {
		return (
			<UnderConstructionCard
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true },
				}}
				modal
				experimental
			/>
		);
	}

	if (itemId === 'outgoing') {
		return (
			<OutgoingCard
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true },
				}}
			/>
		);
	}

	if (itemId === 'onboarding') {
		return (
			<OnboardingCard
				version={2}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true },
				}}
			/>
		);
	}

	if (itemId === 'salaries') {
		return (
			<SalaryCard
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true },
				}}
			/>
		);
	}

	if (itemId === 'forecast-status') {
		return (
			<ForecastStatusCard
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true },
				}}
			/>
		);
	}

	if (itemId === 'forecast') {
		return (
			<ForecastCard
				version={2}
				startDate={range.startDate}
				endDate={range.endDate}
				onAction={onAction}
				onSubmit={onSubmit}
				wrapper={{
					as: CardWrapper,
					props: { animated: true, fit: true },
				}}
			/>
		);
	}

	return null;
});

RenderGridItem.propTypes = {
	itemId: PropTypes.string,
	range: PropTypes.shape({
		startDate: PropTypes.string,
		endDate: PropTypes.string,
	}),
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

const DEFAULT = {
	1: 'health',
	2: 'forecast-actions',
	3: 'customer-performance',
	4: 'account',
	5: 'incoming',
	6: 'invoices',
	7: 'supplier-performance',
	8: 'profit',
	9: 'under-construction',
	10: null,
	11: 'outgoing',
	12: 'onboarding',
	13: null,
	14: 'client-performance',
};

/** @type { React.FC<Props> } */
const StreamlineView = React.memo(function StreamlineView(props) {
	const { className, onAction, onSubmit } = props;
	const slotItems = DEFAULT;

	const [date, setDate] = React.useState(new Date().toISOString());
	const range = useRange(date);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'time:selector:select') {
				setDate(data?.date);
			}

			if (action === 'time:selector:range') {
				if (data?.startDate && data?.endDate) {
					setDate(data);
				}
			}

			return onAction?.(action, data);
		},
		[onAction],
	);

	return (
		<div className={cn('asteria-view__streamline', className)}>
			<StreamlineTitle
				onAction={handleAction}
				onSubmit={onSubmit}
				startDate={range.startDate}
				endDate={range.endDate}
			/>
			<StreamlinedViewFeedback onAction={onAction} />
			<TimeSelector
				onAction={handleAction}
				onSubmit={onSubmit}
				className="sticky -top-8 bg-white py-4"
			/>

			<FlexBox
				direction="horizontal"
				breakpointWidth={1280}
				className="asteria-grid-swap"
			>
				<FlexBox direction="vertical">
					<RenderGridItem
						itemId={slotItems['1']}
						range={range}
						onAction={handleAction}
						onSubmit={onSubmit}
					/>
					<RenderGridItem
						itemId={slotItems['5']}
						range={range}
						onAction={handleAction}
						onSubmit={onSubmit}
					/>
					<RenderGridItem
						itemId={slotItems['2']}
						range={range}
						onAction={handleAction}
						onSubmit={onSubmit}
					/>
					<RenderGridItem
						itemId={slotItems['6']}
						range={range}
						onAction={handleAction}
						onSubmit={onSubmit}
					/>
					<RenderGridItem
						itemId={slotItems['9']}
						range={range}
						onAction={handleAction}
						onSubmit={onSubmit}
					/>
					<RenderGridItem
						itemId={slotItems['13']}
						range={range}
						onAction={handleAction}
						onSubmit={onSubmit}
					/>
				</FlexBox>
				<FlexBox direction="vertical">
					<RenderGridItem
						itemId={slotItems['4']}
						range={range}
						onAction={handleAction}
						onSubmit={onSubmit}
					/>
					<RenderGridItem
						itemId={slotItems['11']}
						range={range}
						onAction={handleAction}
						onSubmit={onSubmit}
					/>
					<RenderGridItem
						itemId={slotItems['8']}
						range={range}
						onAction={handleAction}
						onSubmit={onSubmit}
					/>
					<RenderGridItem
						itemId={slotItems['14']}
						range={range}
						onAction={handleAction}
						onSubmit={onSubmit}
					/>
				</FlexBox>
			</FlexBox>
			<div className="sticky bottom-0 flex items-end flex-col -mt-5">
				<FeatureFlag feature="streamlined.floating.integrations">
					<SupportFloatButton
						className="static"
						onAction={onAction}
						onSubmit={onSubmit}
					/>
				</FeatureFlag>
				<AddIntegrationActionbar
					onAction={handleAction}
					onSubmit={onSubmit}
				/>
			</div>
		</div>
	);
});

StreamlineView.displayName = 'StreamlineView';

StreamlineView.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default StreamlineView;
