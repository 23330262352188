import { AuthService } from '@asteria/backend-utils-services';

/**
 * @param { { accessToken: string, dispatch?: unknown } } options
 */
export async function fetch(options = {}) {
	const { accessToken } = options;

	const config = await AuthService.auth
		.me({ isBulk: true, fields: `config` }, { token: accessToken })
		.then((response) => response?.config ?? {})
		.catch(() => ({}));

	return config;
}
