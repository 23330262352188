import { useEffect, useState } from 'react';

/**
 * @param { import('@tanstack/react-query').UseQueryResult } query
 * @param { (data: unknown) => void } callback
 */
export function useQueryDoneCallback(query, callback, defaultValue) {
	const [ready, setReady] = useState(defaultValue ?? false);

	useEffect(() => {
		if (defaultValue !== undefined) {
			setReady(defaultValue);
		}
	}, [defaultValue]);

	const done = query?.isFetched && !query?.isFetching && !query?.isError;

	useEffect(() => {
		async function execute() {
			if (done) {
				await callback?.(query?.data);

				setReady(true);
			}
		}

		execute();
	}, [callback, query?.data, done]);

	return ready;
}
