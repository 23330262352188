import React from 'react';

import BasicCard from '../basic';

import { useRequest } from './hooks';
import CardContent from './v1/content';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { ChildrenProps['config'] } */
const CONFIG = {
	onboarding: { required: 'bank', optional: 'erp', loading: 'bank' },
	card: {
		content: {
			default: {
				view: (props) => <CardContent {...props} />,
			},
		},
		footer: {
			connect: {
				button: {
					connect: { required: 'bank' },
					postfix: { required: { onboarding: 'erp' } },
				},
			},
		},
	},
};

const ProfitCard = React.memo(function ProfitCard(props) {
	const query = useRequest(props);

	return <BasicCard {...props} type="profit" query={query} config={CONFIG} />;
});

export default ProfitCard;
