import React from 'react';

import { useFormContext } from 'react-hook-form';

import { get } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { Input, useFormValues } from '@asteria/component-form';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useTrendsDirection } from '../../hooks';
import * as utils from '../../utils';
import { getPath } from '../../utils/form/utils';
import Satisfaction from '../satisfaction';
import Trends from '../trends';

/**
 * @typedef { import('../../types').ChildrenOptions } ChildrenOptions
 * @typedef { import('../../types').Props } Props
 */

/** @type { React.FC<Pick<ChildrenOptions, 'translationOptions' | 'onAction' | 'onSubmit'> & { type: 'deposit' | 'withdraw' }> } */
const ForecastTotalSection = React.memo(function ForecastTotalSection(props) {
	const { type, translationOptions: $translationOptions, onAction } = props;
	const { getValues, setValue, reset } = useFormContext();

	const path = getPath({ type });

	const onLabelClick = React.useCallback(
		() =>
			onAction?.('card:action', {
				type: 'forecast',
				data: {
					section: 'total',
					type: type,
				},
			}),
		[onAction, type],
	);

	const onValueChange = React.useCallback(
		({ value }) => {
			const roundedValue = value ? Math.round(value) : value;

			const changes = utils.form.type.setValue(
				{ type, value: roundedValue, recalculate: true },
				{ getValues, setValue, reset },
			);

			utils.form.changes.apply(changes, { getValues, setValue, reset });
		},
		[getValues, reset, setValue, type],
	);

	const onProbabilityChange = React.useCallback(
		(value) => {
			const changes = utils.form.type.setProbability(
				{ type, value, recalculate: true },
				{ getValues, setValue, reset },
			);

			utils.form.changes.apply(changes, { getValues, setValue, reset });
		},
		[getValues, reset, setValue, type],
	);

	const source = get($translationOptions?.data?.source, path);

	const [total, probability] = useFormValues({
		name: [
			path.concat('total').join('.'),
			path.concat('probability').join('.'),
		],
	});

	const trends = useTrendsDirection(total, source?.total);

	const translationOptions = React.useMemo(() => {
		const data = $translationOptions?.data;

		return {
			...$translationOptions,
			postfix: {
				...$translationOptions?.postfix,
				section: 'total',
				'section-type': type,
				trends: trends,
			},
			data: {
				...data,

				source: utils.getDataForm(data?.source, { type }),
				target: utils.getDataForm(data?.target, { type }),
				form: { ...data?.form, value: { total, probability } },

				diff: {
					...data?.diff,
					value: utils.getDiffValue({
						sourceTotal: source?.total ?? 0,
						targetTotal: total ?? 0,
					}),
				},
			},
		};
	}, [$translationOptions, probability, source?.total, total, trends, type]);

	return (
		<div
			className={cn(
				'asteria-component__card-section',
				'asteria--variant-total',
				{ [`asteria--type-${type}`]: type },
			)}
		>
			{source?.total ? (
				<Trends
					direction={trends}
					translationOptions={translationOptions}
				/>
			) : null}

			<Button
				variant="link"
				label={TranslationService.getV2(
					['card.extra.section.label'],
					translationOptions,
				)}
				onClick={onLabelClick}
			/>
			<div className="flex gap-1 items-baseline">
				<Input
					name={getPath({ type, field: 'total' }).join('.')}
					type="number"
					format={{ valueAsNumber: true }}
					size="lg"
					value={0}
					defaultValue={0}
					min={0}
					onChange={onValueChange}
					helpText={utils.getHelpText(translationOptions)}
				/>
				<Satisfaction
					value={probability ?? 1}
					onChange={onProbabilityChange}
				/>
			</div>
		</div>
	);
});

ForecastTotalSection.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	translationOptions: PropTypes.object,
	type: PropTypes.oneOf(['deposit', 'withdraw']),
};

export default ForecastTotalSection;
