import { get } from 'lodash-es';

import store from '@asteria/datalayer';

function formatLink(link) {
	delete link.invoiceDetails;

	return link;
}

function formatTransaction(node, ctx) {
	const defaultCurrency =
		store.getState()?.app?.company?.settings?.currency ?? 'SEK';

	let links;

	if (node?.descriptionClient) {
		links = Array.from(node?.links ?? []);

		const index = links.findIndex(({ type }) => type === 'CLIENT');

		if (index === -1) {
			links.push({ type: 'CLIENT', id: node?.descriptionClient });
		} else {
			links[index] = { type: 'CLIENT', id: node?.descriptionClient };
		}

		links = links.map((object) => formatLink(object));
	}

	return {
		_id: node?.id,
		description: node?.description,
		probability: node?.probability ?? 1,
		total: node?.total ?? 0,
		currency: node?.currency ?? defaultCurrency,
		paymentDate: node?.paymentDate ?? ctx?.endDate,
		links: links,
	};
}

function fetchTransactions(data, ctx) {
	return Object.entries(data?.transactions ?? {})
		.map(([id, object]) => ({ ...object, id: object?.$new ? null : id }))
		.filter((object) => !(object?.$deleted && object?.$new))
		.reduce(
			(acc, object) => {
				const fmt = formatTransaction(object, ctx);

				if (!object?.total || object?.$deleted) {
					acc.DELETE.push(fmt);
				} else if (object.id) {
					acc.UPDATE.push(fmt);
				} else {
					acc.CREATE.push(fmt);
				}

				return acc;
			},
			{ CREATE: [], UPDATE: [], DELETE: [] },
		);
}

function fetchChanges(input, ctx) {
	const changes = [];

	for (const type of ['deposit', 'withdraw']) {
		const categories = get(input, [type, 'FORECAST', 'categories']) ?? {};

		for (const category in categories) {
			const tags =
				get(input, [
					type,
					'FORECAST',
					'categories',
					category,
					'tags',
				]) ?? {};

			for (const tag in tags) {
				const data =
					get(input, [
						type,
						'FORECAST',
						'categories',
						category,
						'tags',
						tag,
					]) ?? {};

				changes.push({
					type: type.toUpperCase(),
					categoryName: category,
					tagName: tag,
					total: data?.total ?? 0,
					probability: data?.probability ?? 1,
					transactions: fetchTransactions(data, ctx),
				});
			}
		}
	}

	return changes;
}

function format(input, ctx) {
	const { endDate, scenarioName } = ctx;

	return {
		date: endDate,
		changes: fetchChanges(input, ctx),
		targetScenarioName: scenarioName,
	};
}

export default format;
