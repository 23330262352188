import { get } from 'lodash-es';

import { normalizeProbability } from '@asteria/component-core/utils/normalize';

import * as Category from './category';
import * as Changes from './changes';
import * as Transaction from './transaction';
import { getPath, validateFormContext } from './utils';

/**
 *
 * @param { { type: string, category: string, tag: string } } options
 * @param { unknown } ctx
 * @returns { { value: number, min: number } }
 */
function getTransactionValue({ type, category, tag }, ctx) {
	const context = validateFormContext(ctx);
	const path = getPath({ type, category, tag });

	const transactions = get(context.form, path.concat('transactions')) ?? {};

	return Object.values(transactions).reduce(
		(acc, { total, probability }) => ({
			value: acc.value + (total || 0),
			min: acc.min + (total || 0) * (probability || 1),
		}),
		{ value: 0, min: 0 },
	);
}

function shouldRecalculateParent(value) {
	return value === true || ['type', 'category'].includes(value);
}

export function setProbability(
	{ type, category, tag, value, recalculate },
	ctx,
) {
	const context = validateFormContext(ctx);
	const path = getPath({ type, category, tag });

	const changes = [];

	changes.push(
		Changes.create(
			{
				type: 'set',
				name: path.concat('probability').join('.'),
				value: value,
			},
			context,
		),
	);

	const transactions = get(context.form, path.concat('transactions')) ?? {};

	for (const id in transactions) {
		changes.push(
			...Transaction.setProbability(
				{ type, category, tag, id, value },
				context,
			),
		);
	}

	if (shouldRecalculateParent(recalculate)) {
		changes.push(
			...Category.recalculate({ type, category, recalculate }, context),
		);
	}

	return changes;
}

export function setValue({ type, category, tag, value, recalculate }, ctx) {
	const context = validateFormContext(ctx);
	const path = getPath({ type, category, tag });

	const changes = [];

	const current = getTransactionValue({ type, category, tag }, context);

	changes.push(
		Changes.create(
			{
				type: 'set',
				name: path.concat('total').join('.'),
				value: value,
			},
			context,
		),
	);

	if (current.value) {
		const diff = value / current.value;

		const transactions =
			get(context.form, path.concat('transactions')) ?? {};

		for (const id in transactions) {
			const path = getPath({ type, category, tag, id });

			const previous = get(context.form, path.concat('total')) || 0;
			const next = previous * diff;

			changes.push(
				...Transaction.setValue(
					{ type, category, tag, id, value: Math.round(next) },
					context,
				),
			);
		}
	} else {
		changes.push(
			...Transaction.create(
				{
					type: type,
					category: category,
					tag: tag,
					data: { total: value },
					recalculate: 'tag',
				},
				context,
			),
		);

		const tags = get(context.form, getPath({ type }).concat('tags')) ?? [];

		if (
			!tags.some(
				(object) =>
					object?.category === category && object?.tag === tag,
			)
		) {
			changes.push(
				Changes.create(
					{
						type: 'ADD_TAG',
						name: getPath({ type }).concat('tags').join('.'),
						value: { category, tag, $new: true },
					},
					context,
				),
			);
		}
	}

	if (shouldRecalculateParent(recalculate)) {
		changes.push(
			...Category.recalculate({ type, category, recalculate }, context),
		);
	}

	return changes;
}

export function remove({ type, category, tag, recalculate: refresh }, ctx) {
	const context = validateFormContext(ctx);
	const path = getPath({ type, category, tag });

	const changes = [];

	const transactions = get(context.form, path.concat('transactions')) ?? {};

	for (const id in transactions) {
		changes.push(
			...Transaction.remove({ type, category, tag, id }, context),
		);
	}

	changes.push(...recalculate({ type, category, tag }, context));

	if (shouldRecalculateParent(refresh)) {
		changes.push(
			...Category.recalculate(
				{ type, category, recalculate: refresh },
				context,
			),
		);
	}

	changes.push(
		Changes.create(
			{
				type: 'REMOVE_TAG',
				name: getPath({ type }).concat('tags').join('.'),
				value: { category, tag },
			},
			context,
		),
	);

	return changes;
}

export function recalculate({ type, category, tag, recalculate }, ctx) {
	const context = validateFormContext(ctx);
	const path = getPath({ type, category, tag });

	const changes = [];

	const current = getTransactionValue({ type, category, tag }, context);

	const probability = normalizeProbability(
		current.value ? current.min / current.value : 1,
	);

	changes.push(
		Changes.create(
			{
				type: 'set',
				name: path.concat('total').join('.'),
				value: Math.round(current.value),
			},
			context,
		),
		Changes.create(
			{
				type: 'set',
				name: path.concat('probability').join('.'),
				value: probability,
			},
			context,
		),
	);

	if (shouldRecalculateParent(recalculate)) {
		changes.push(
			...Category.recalculate({ type, category, recalculate }, context),
		);
	}

	return changes;
}

export function move({ type, source, target, recalculate: refresh }, ctx) {
	const context = validateFormContext(ctx);

	const changes = [];

	const transactions =
		get(
			context.form,
			getPath({
				type,
				category: source?.category,
				tag: source?.tag,
				field: 'transactions',
			}),
		) ?? {};

	for (const id in transactions) {
		changes.push(
			...Transaction.move(
				{ type, source: { ...source, id }, target: target },
				context,
			),
		);
	}

	changes.push(
		...recalculate(
			{ type, category: source?.category, tag: source?.tag },
			context,
		),
		...recalculate(
			{ type, category: target?.category, tag: target?.tag },
			context,
		),
	);

	if (shouldRecalculateParent(refresh)) {
		changes.push(
			...Category.recalculate(
				{ type, category: source?.category, recalculate: refresh },
				context,
			),
			...Category.recalculate(
				{ type, category: target?.category, recalculate: refresh },
				context,
			),
		);
	}

	return changes;
}
