import React, { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import PageHeader, {
	PageContent,
	PagePostfix,
	PagePrefix,
	PageTitle,
} from '@asteria/component-core/pageHeader';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';
import Notifications from '@asteria/component-notifications';
import { FeatureFlag } from '@asteria/component-tools/featureflag';
import { TourButton } from '@asteria/component-tour';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import ClientDetailsOverview from './Details';
import ClientListOverview from './List';

const selectors = {
	client: createSelector(
		(store) => store?.app?.clients ?? [],
		(_, ID) => ID,
		(clients, ID) =>
			clients.find((object) => (object?.id ?? object._id) === ID),
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const ListClientWrapper = (props) => {
	const {
		className,
		onClose,
		onAction,
		onSubmit,
		headerType = null,
		...rest
	} = props;

	return (
		<Wrapper className={cn('asteria-component__client', className)}>
			{headerType === 'wrapper' ? (
				<Header onClose={onClose}>
					{TranslationService.get('client.overview.title.list')}
				</Header>
			) : null}
			{headerType === 'page' ? (
				<PageHeader>
					<PageContent>
						<PageTitle
							title={TranslationService.get(
								'client.overview.title.list',
							)}
						/>
					</PageContent>
					<PagePostfix>
						<FeatureFlag feature="widget-notifications">
							<FeatureFlag feature="widget-notifications-client">
								<Notifications onAction={onAction} />
							</FeatureFlag>
						</FeatureFlag>
						<FeatureFlag feature="page-header-tour">
							<TourButton />
						</FeatureFlag>
					</PagePostfix>
				</PageHeader>
			) : null}
			<Content scroll>
				<ClientListOverview
					onAction={onAction}
					onSubmit={onSubmit}
					{...rest}
				/>
			</Content>
			{headerType !== 'page' ? (
				<Footer>
					<FooterSection position="last">
						<Button
							variant="tertiary"
							label={TranslationService.get([
								'button.close',
								'action.close',
							])}
							onClick={onClose}
						/>
					</FooterSection>
				</Footer>
			) : null}
		</Wrapper>
	);
};

ListClientWrapper.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	headerType: PropTypes.string,
};

const SelectedClientWrapper = (props) => {
	const {
		className,
		_id,
		onClose,
		onAction,
		onSubmit,
		headerType = null,
		...rest
	} = props;

	const client = useSelector(
		(store) => selectors.client(store, _id),
		(a, b) => isEqual(a, b),
	);

	React.useEffect(() => {
		onSubmit?.('clients:details', _id);
	}, [_id, onSubmit]);

	const onResetClient = useCallback(
		() => onAction?.('clients:select', null),
		[onAction],
	);

	return (
		<Wrapper className="asteria-component__client">
			{headerType === 'wrapper' ? (
				<Header onClose={onClose} onBack={onResetClient}>
					{TranslationService.get(
						[
							'client.overview.title.details',
							`client.overview.title.details.${client?.type}`,
						],
						'client.overview.title.details',
						{ client: client },
					)}
				</Header>
			) : null}
			{headerType === 'page' ? (
				<PageHeader>
					<PagePrefix>
						<Button icon="chevron-left" onClick={onResetClient} />
					</PagePrefix>
					<PageContent>
						<PageTitle
							title={TranslationService.get(
								[
									'client.overview.title.details',
									`client.overview.title.details.${client?.type}`,
								],
								'client.overview.title.details',
								{ client: client },
							)}
						/>
					</PageContent>
					<PagePostfix>
						<FeatureFlag feature="widget-notifications">
							<FeatureFlag feature="widget-notifications-client">
								<Notifications onAction={onAction} />
							</FeatureFlag>
						</FeatureFlag>
						<FeatureFlag feature="page-header-tour">
							<TourButton />
						</FeatureFlag>
					</PagePostfix>
				</PageHeader>
			) : null}
			<Content scroll>
				<div
					className={cn(
						'asteria-component__client-overview',
						className,
					)}
				>
					<ClientDetailsOverview
						_id={client?.id ?? client?._id}
						onAction={onAction}
						onSubmit={onSubmit}
						{...rest}
					/>
				</div>
			</Content>
			{headerType !== 'page' ? (
				<Footer>
					<FooterSection position="last">
						<Button
							variant="tertiary"
							label={TranslationService.get([
								'button.close',
								'action.close',
							])}
							onClick={onClose}
						/>
					</FooterSection>
				</Footer>
			) : null}
		</Wrapper>
	);
};

SelectedClientWrapper.propTypes = {
	className: PropTypes.string,
	_id: PropTypes.string,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	headerType: PropTypes.bool,
};

const ClientOverviewContent = (props) => {
	const { _id, onAction } = props;

	const [selectedClientId, selectClient] = useState(_id);

	useEffect(() => {
		selectClient(_id);
	}, [_id]);

	const handleAction = useCallback(
		(action, data) => {
			if (action === 'clients:select') {
				selectClient(data);
				return;
			}

			return onAction?.(action, data);
		},
		[onAction],
	);

	if (selectedClientId) {
		return (
			<SelectedClientWrapper
				{...props}
				_id={selectedClientId}
				onAction={handleAction}
			/>
		);
	}

	return <ListClientWrapper {...props} onAction={handleAction} />;
};

ClientOverviewContent.propTypes = {
	className: PropTypes.string,
	_id: PropTypes.string,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	headerType: PropTypes.oneOf(['page', 'wrapper']),
};

ClientOverviewContent.defaultProps = {};

const ClientOverviewWrapper = (props) => {
	const { open = true, onClose } = props;

	return (
		<Modal open={open} onClose={onClose} size="sm">
			<ClientOverviewContent {...props} headerType="wrapper" />
		</Modal>
	);
};

ClientOverviewWrapper.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,

	className: PropTypes.string,
	_id: PropTypes.string,
	onAction: PropTypes.func,
};

ClientOverviewWrapper.defaultProps = {};

export default ClientOverviewWrapper;
export { ClientOverviewContent };
