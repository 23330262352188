import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { TranslationService } from '@asteria/language';

import './styles.scss';

/**
 * @typedef ExtraSectionButtonProps
 * @property { 'customers' | 'suppliers' } section
 * @property { string } status
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { string } startDate
 * @property { string } endDate
 */

/** @type { React.FC<ExtraSectionButtonProps> } */
const InvoiceCardExtraSectionButton = React.memo(
	function InvoiceCardExtraSectionButton({
		section,
		status,
		startDate,
		endDate,
		onAction,
		translationOptions,
	}) {
		const onClick = React.useCallback(
			() =>
				onAction?.('card:action', {
					type: 'invoices',
					section: section,
					status: status,
					startDate: startDate,
					endDate: endDate,
				}),
			[endDate, onAction, section, startDate, status],
		);

		return (
			<Button
				variant="link"
				label={TranslationService.getV2(['card.section.link'], {
					...translationOptions,
					postfix: {
						...translationOptions?.postfix,
						section: section,
						link_type: status,
					},
				})}
				onClick={onClick}
			/>
		);
	},
);

InvoiceCardExtraSectionButton.propTypes = {
	section: PropTypes.string,
	status: PropTypes.string,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	onAction: PropTypes.func,
	translationOptions: PropTypes.object,
};

export default InvoiceCardExtraSectionButton;
