import { FeatureService } from '@asteria/backend-utils-services';

/**
 * @param { { partnerId: string, dispatch?: unknown } } options
 */
export async function fetchPartnerFeatures(options = {}) {
	const { partnerId } = options;

	const response = await FeatureService.feature
		.fetchByPartnerId({ id: partnerId })
		.catch(() => ({}));

	return response;
}

/**
 * @param { { accessToken: string, partnerId: string, dispatch?: unknown } } options
 */
export async function fetchActiveFeatures(options = {}) {
	const { accessToken, partnerId } = options;

	const response = await FeatureService.feature
		.fetchActive(
			{ partnerId: partnerId, isBulk: true },
			{ token: accessToken },
		)
		.catch(() => {
			if (accessToken === '__OFFLINE__') {
				return JSON.parse(
					localStorage.getItem('asteriaFeatures') ?? '[]',
				).reduce(
					(acc, value) => ({ ...acc, [value]: { name: value } }),
					{},
				);
			}
		});

	return response;
}
