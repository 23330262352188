import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Title } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

const Selector = React.memo(function Selector(props) {
	const { onAction, visibleDate } = props;

	const next = React.useCallback(
		() => onAction?.('time:selector:visible:next'),
		[onAction],
	);

	const back = React.useCallback(
		() => onAction?.('time:selector:visible:back'),
		[onAction],
	);

	const data = React.useMemo(() => ({ date: visibleDate }), [visibleDate]);

	return (
		<div className="flex items-center justify-center gap-2">
			<Button size="sm" icon="chevron-left" onClick={back} />
			<Translation
				defaultText='{{ date | date:"yyyy" }}'
				data={data}
				Component={Title}
				size="xxs"
			/>
			<Button size="sm" icon="chevron-right" onClick={next} />
		</div>
	);
});

Selector.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	visibleDate: PropTypes.string,
};

export default Selector;
