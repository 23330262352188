import React from 'react';

import { DateContext } from '../../context';

export function useDateRange() {
	const { startDate, endDate } = React.useContext(DateContext);

	if (endDate) {
		return 'custom';
	}

	if (startDate) {
		return 'start';
	}

	return null;
}
