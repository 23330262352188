import { useCallback, useState } from 'react';

function useStateToggle(defaultValue) {
	const [value, setValue] = useState(defaultValue);

	const toggle = useCallback((newValuen) => {
		setValue((oldValue) => {
			if (oldValue === newValuen) {
				setValue(null);
			} else {
				setValue(newValuen);
			}
		});
	}, []);

	return [value, toggle];
}

export default useStateToggle;
