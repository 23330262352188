import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { Text } from '@asteria/component-core/typography';

import Prefix from '@asteria/component-prefix';

import { Translation } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { getKeyPermutations } from '../utils';

import '../styles.scss';

const GraphTooltip = React.memo(function GraphTooltip(props) {
	const { translationOptions, payload, coordinate, activeGroup } = props;

	const tooltipItems = React.useMemo(() => {
		if (!payload?.length) {
			return [];
		}

		if (activeGroup === 'outgoing') {
			return payload.filter((data) => data?.dataKey !== 'forecast');
		}

		return payload.filter((data) => data?.dataKey === 'forecast');
	}, [activeGroup, payload]);

	const total = React.useMemo(() => {
		return tooltipItems.reduce((acc, object) => acc + object?.value, 0);
	}, [tooltipItems]);

	if (!total || !activeGroup) {
		return null;
	}

	return (
		<div
			className={cn(
				'bg-white flex flex-col shadow-md rounded-sm w-80 p-4 gap-4 absolute',
			)}
			style={{
				transform: 'translate(-50%, calc(-100% - 10px))',
				top: coordinate?.y,
				left: coordinate?.x,
			}}
		>
			<Translation
				className="asteria--type-title text-center"
				translationKey="cashflow.tooltip.withdraw.title"
				translationOptions={{
					...translationOptions,
					postfix: {
						...translationOptions.postfix,
						group: activeGroup,
					},
				}}
				Component={Text}
				size="sm"
			/>

			<Group direction="vertical">
				{tooltipItems
					.filter((data) => data?.value)
					.map((object) => (
						<Group
							key={object.name}
							direction="horizontal"
							verticalAlign="center"
							horizontalAlign="space-between"
						>
							<Group
								direction="horizontal"
								verticalAlign="center"
								horizontalAlign="left"
							>
								<svg width="12" height="12">
									<circle
										cx="6"
										cy="6"
										r="6"
										style={{
											fill: `var(--color, ${object?.color})`,
										}}
									/>
								</svg>
								<Translation
									translationKey={[
										...getKeyPermutations(
											`withdraw.${object?.dataKey}`,
											'.',
											['cashflow.tooltip'],
										).map((key) => `${key}.label`),
									]}
									translationOptions={{
										...translationOptions,
										data: {
											...translationOptions?.data,
											name: object?.name,
											display: { total: object?.value },
										},
									}}
									Component={Text}
									size="sm"
								/>
							</Group>
							<Translation
								translationKey={[
									...getKeyPermutations(
										`withdraw.${object?.dataKey}`,
										'.',
										['cashflow.tooltip'],
									).map((key) => `${key}.value`),
								]}
								translationOptions={{
									data: {
										name: object?.name,
										value: {
											display: { total: object?.value },
										},
									},
								}}
								Component={Text}
								size="sm"
							/>
						</Group>
					))}
			</Group>
			<Group
				direction="horizontal"
				verticalAlign="center"
				horizontalAlign="space-between"
			>
				<Group
					direction="horizontal"
					verticalAlign="center"
					horizontalAlign="left"
				>
					<Prefix />
					<Translation
						className="asteria--type-total"
						translationKey="cashflow.tooltip.total.label"
						translationOptions={translationOptions}
						Component={Text}
						size="sm"
					/>
				</Group>
				<Translation
					className="asteria--type-total"
					translationKey="cashflow.tooltip.value"
					translationOptions={{
						...translationOptions,
						data: {
							...translationOptions?.data,
							value: { display: { total: total } },
						},
					}}
					Component={Text}
					size="sm"
				/>
			</Group>
		</div>
	);
});

GraphTooltip.displayName = 'GraphTooltip';

GraphTooltip.propTypes = {
	translationOptions: PropTypes.object,
	payload: PropTypes.array,
	coordinate: PropTypes.object,
	active: PropTypes.bool,
	activeGroup: PropTypes.string,
};

export default GraphTooltip;
