import React from 'react';

import PropTypes from 'prop-types';

import ResponsiveContainer from '../../../components/responsive-container';
import { Content } from '../../basic';
import { ClientPerformanceContext } from '../context';

import ChartProvider from './chart';
import HighlightClient from './highlight';
import Selector from './selector';
import ClientPerformanceTable from './table';
import ClientPerformanceText from './text';

/**
 * @typedef { import('../../basic/types').ChildrenProps } ChildrenProps
 */

const Highlight = React.memo(function Highlight(props) {
	return (
		<ResponsiveContainer
			className="grid grid-cols-2 gap-4"
			responsiveStyles="grid-cols-1"
			minWidth={500}
		>
			<HighlightClient {...props} name="best" />
			<HighlightClient {...props} name="worst" />
		</ResponsiveContainer>
	);
});

/** @type { React.FC<ChildrenProps> } */
const ClientContent = React.memo(function ClientContent(props) {
	const { loading, importing, state } = props;

	const [clientType, setClientType] = React.useState('CUSTOMER');
	const [active, setActive] = React.useState(null);

	const postfix = props?.translationOptions?.postfix;
	const data = props?.translationOptions?.data;

	const translationOptions = {
		...props?.translationOptions,
		postfix: {
			...postfix,
			variant: `${clientType}-performance`.toLowerCase(),
		},
		data: {
			...data,
			data: data?.data?.[clientType.toLowerCase()],
			source: data?.source?.[clientType.toLowerCase()],
			target: data?.target?.[clientType.toLowerCase()],
		},
	};

	const next = {
		...props,
		translationOptions: translationOptions,
		clientType: clientType,
	};

	const statistics =
		translationOptions?.data?.data?.statistics?.details ?? [];

	const ctx = React.useMemo(
		() => ({ active: { value: active, set: setActive } }),
		[active],
	);

	const placeholder = loading || importing || state?.promotion?.visible;

	return (
		<ClientPerformanceContext.Provider value={ctx}>
			<div className="flex flex-col gap-4">
				{!importing && !state?.promotion?.visible ? (
					<Selector active={clientType} onSelect={setClientType} />
				) : null}

				<ClientPerformanceText {...next} />

				{statistics.length || placeholder ? (
					<>
						{!placeholder ? <Highlight {...next} /> : null}
						<ChartProvider {...next} />
						<ClientPerformanceTable {...next} />
					</>
				) : null}

				<Content.Loading {...next} />
			</div>
		</ClientPerformanceContext.Provider>
	);
});

ClientContent.propTypes = {
	translationOptions: PropTypes.object,
	loading: PropTypes.bool,
	importing: PropTypes.bool,
	state: PropTypes.object,
};

export default ClientContent;
