import React from 'react';

import PropTypes from 'prop-types';

import { TextGroup } from '@asteria/component-core/typography';

import BaseCard from '../../../../base';
import { useChildren } from '../../hooks';
import { getVersion } from '../../utils';
import withConfig from '../../withConfig';
import Loading from '../components/loading';
import Subtitle from '../components/subtitle';
import Text from '../components/text';
import Title from '../components/title';

/**
 * @typedef { import('../../types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const ViewContent = React.memo(function ViewContent(props) {
	return (
		<TextGroup className="flex flex-col gap-2">
			<Subtitle {...props} />
			<Title {...props} />
			<Loading {...props} />
			<Text {...props} />
		</TextGroup>
	);
});

const ViewContentConfig = withConfig(ViewContent, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.content?.default?.view, version);
});

/** @type { React.FC<ChildrenProps> } */
const DefaultView = React.memo(function DefaultView({ children, ...props }) {
	const content = useChildren({ children, ...props }, (props) => (
		<ViewContentConfig {...props} />
	));

	return <BaseCard.Content>{content}</BaseCard.Content>;
});

DefaultView.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default withConfig(DefaultView, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.content?.default, version);
});

export { ViewContentConfig as ViewContent };
