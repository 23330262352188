import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

import { STATUSES } from './constants';
import InvoicesContentSummaryButton from './textButton';

import './styles.scss';

/**
 * @typedef ChildrenOptions
 * @property { boolean } loading
 * @property { 'paid' | 'today' | 'future' } variant
 * @property { import('@tanstack/react-query').UseQueryResult } query
 * @property { 'none' | 'erp' | 'bank' | 'both' } onboarding
 * @property { 'up' | 'down' } direction
 * @property { string } startDate
 * @property { string } endDate
 * @property { { postfix: unknown, data: unknown } } translationOptions
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 */

/** @type { React.FC<ChildrenOptions> } */
const InvoicesContentSummary = React.memo(function InvoicesContentSummary(
	props,
) {
	const { query, translationOptions, onAction } = props;

	if (STATUSES.every((key) => !query?.data?.source?.[key]?.count)) {
		return (
			<Translation
				translationKey="card.content.text"
				translationOptions={{
					...translationOptions,
					postfix: { ...translationOptions.postfix, zero: true },
				}}
				Component={Text}
			/>
		);
	}

	return (
		<>
			<div className="flex flex-wrap gap-x-1">
				{STATUSES.filter(
					(key) => !!query?.data?.source?.[key]?.count,
				).map((status, index, arr) => (
					<div key={status} className="flex items-baseline">
						<InvoicesContentSummaryButton
							status={status}
							translationOptions={translationOptions}
							onAction={onAction}
						/>
						<Translation
							translationKey="card.content.text.link.separator"
							defaultText=","
							translationOptions={{
								...translationOptions,
								postfix: {
									...translationOptions?.postfix,
									link_type: status,
								},
							}}
							Component={Text}
							show={index !== arr.length - 1}
						/>
					</div>
				))}
			</div>
			{/* <Translation
				translationKey="card.content.text"
				translationOptions={translationOptions}
				showWhenEmpty={false}
				Component={Text}
				loading={loading}
			/> */}
		</>
	);
});

InvoicesContentSummary.propTypes = {
	query: PropTypes.object,
	translationOptions: PropTypes.object,
	onAction: PropTypes.func,
	loading: PropTypes.bool,
};

export default InvoicesContentSummary;
