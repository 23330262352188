import React from 'react';

import PropTypes from 'prop-types';

import Chip from '@asteria/component-chip';
import { Datepicker } from '@asteria/component-form';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { DateContext } from '../../../context';
import { useDateRange } from '../hooks';

const Custom = React.memo(function Custom(props) {
	const { onAction } = props;

	const { startDate, endDate } = React.useContext(DateContext);

	const dateRange = useDateRange();

	const onChange = React.useCallback(
		({ value }) => onAction?.('time:selector:range', value),
		[onAction],
	);

	const onDismiss = React.useCallback(
		() => onAction?.('time:selector:reset'),
		[onAction],
	);

	const value = React.useMemo(
		() => ({ startDate, endDate }),
		[endDate, startDate],
	);

	return (
		<div
			className={cn(
				'flex items-center justify-center gap-2',
				'asteria-component__time-selector-popup-custom',
			)}
		>
			<Datepicker
				iconOnly
				skipVisibleValue
				uncontrolled
				name="date"
				size="sm"
				variant="range"
				onChange={onChange}
				value={value}
				types={['day', 'week', 'month', 'year']}
				type="month"
			>
				{({ open, onOpen, onClose, toggleRef }) => (
					<Chip
						onClick={open ? onClose : onOpen}
						ref={toggleRef}
						label={TranslationService.getV2(
							['time.selector.popup.custom.label'],
							{
								postfix: { 'date-range': dateRange },
								data: { startDate, endDate },
							},
						)}
						icon="date"
						iconPosition="last"
						dismiss={!!startDate}
						onDismiss={onDismiss}
					/>
				)}
			</Datepicker>
		</div>
	);
});

Custom.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	visibleDate: PropTypes.string,
};

export default Custom;
