import { useMemo } from 'react';

import { useOnboardingState, useOnboardingStatus } from '../../hooks';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

/** @param { Props } props */
export function useCardState(props) {
	const { useDefaultValue } = props;
	const defaultValue = useDefaultValue?.(props);

	const onboarding = useOnboardingState();
	const status = useOnboardingStatus({ type: null });

	const visible = !(onboarding === 'both' && status === 'connected');

	return useMemo(() => {
		if (!visible) {
			return { visible: false };
		}

		return defaultValue;
	}, [defaultValue, visible]);
}
