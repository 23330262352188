import React from 'react';

import PropTypes from 'prop-types';
import { PolarAngleAxis, RadialBar, RadialBarChart } from 'recharts';

import { useRadialBarData } from '../../hooks';

import '../../styles.scss';

const HealthRadialBar = React.memo(function HealthRadialBar(props) {
	const { query } = props;

	const graphData = useRadialBarData(query);

	return (
		<RadialBarChart
			width={170}
			height={90}
			cy={80}
			innerRadius={55}
			outerRadius={80}
			data={graphData}
			startAngle={180}
			endAngle={0}
		>
			<PolarAngleAxis
				type="number"
				domain={[0, 100]}
				tick={false}
				axisLine={false}
			/>
			<RadialBar
				dataKey="value"
				cornerRadius="10"
				background={{ fill: 'var(--color-graph-radial-background)' }}
			/>
		</RadialBarChart>
	);
});

HealthRadialBar.displayName = 'HealthRadialBar';

HealthRadialBar.propTypes = {
	query: PropTypes.object,
	translationOptions: PropTypes.object,
};

export default HealthRadialBar;
