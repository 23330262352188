import React from 'react';

import PropTypes from 'prop-types';

/** @type { React.FC<{ timeout: number }> } */
const Slideshow = React.memo(function Slideshow(props) {
	const { children, timeout = 3_000 } = props;

	const count = React.Children.count(children);

	const [index, setIndex] = React.useState(0);

	React.useEffect(() => {
		const timeoutRef = setInterval(() => {
			setIndex((value) => {
				if (value + 1 >= count) {
					return 0;
				}

				return value + 1;
			});
		}, timeout);

		return () => {
			clearInterval(timeoutRef);
		};
	}, [count, timeout]);

	React.useEffect(() => {
		setIndex(0);
	}, [count]);

	if (!count) {
		return null;
	}

	return children[index];
});

Slideshow.propTypes = { children: PropTypes.node, timeout: PropTypes.number };

export default Slideshow;
