import React from 'react';

import PropTypes from 'prop-types';

import HighlightClientView from './highlight.view';

/**
 * @typedef { import('../../../basic/types').ChildrenProps } ChildrenProps
 *
 * @typedef Props
 * @property { 'CUSTOMER' | 'SUPPLIER' } clientType
 * @property { string } name
 */

/** @type { React.FC<ChildrenProps & Props> } */
const HighlightClientProvider = React.memo(function HighlightClientProvider(
	props,
) {
	const { name, translationOptions, onAction, clientType, loading } = props;

	const statistics = translationOptions?.data?.source?.statistics?.[name];

	const showClient = React.useCallback(
		() =>
			onAction?.('card:action', {
				type: `${clientType}-details`.toLowerCase(),
				client: statistics,
			}),
		[clientType, onAction, statistics],
	);

	return (
		<HighlightClientView
			section={name}
			translationOptions={translationOptions}
			loading={loading}
			onClick={showClient}
		/>
	);
});

HighlightClientProvider.propTypes = {
	name: PropTypes.string,
	translationOptions: PropTypes.object,
	onAction: PropTypes.func,
	clientType: PropTypes.string,
	loading: PropTypes.bool,
};

export default HighlightClientProvider;
