import React from 'react';

import { addYears, formatISO, startOfMonth, subYears } from 'date-fns';
import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';
import { parseDate } from '@asteria/utils-funcs/normalize';

import Custom from './components/custom';
import Months from './components/months';
import Selector from './components/selector';

import './styles.scss';

const Popup = function Popup(props) {
	const { className, onAction } = props;

	const [visibleDate, setVisibleDate] = React.useState(
		formatISO(startOfMonth(new Date()), { representation: 'date' }),
	);

	const next = React.useCallback(() => {
		setVisibleDate((value) => addYears(parseDate(value), 1).toISOString());
	}, []);

	const back = React.useCallback(() => {
		setVisibleDate((value) => subYears(parseDate(value), 1).toISOString());
	}, []);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'time:selector:visible:next') {
				return next(data);
			}

			if (action === 'time:selector:visible:back') {
				return back(data);
			}

			return onAction?.(action, data);
		},
		[back, next, onAction],
	);

	return (
		<div
			className={cn(
				'flex flex-col gap-4',
				'asteria-component__time-selector-popup',
				className,
			)}
		>
			<Selector
				{...props}
				visibleDate={visibleDate}
				onAction={handleAction}
			/>
			<Custom
				{...props}
				visibleDate={visibleDate}
				onAction={handleAction}
			/>
			<Months
				{...props}
				visibleDate={visibleDate}
				onAction={handleAction}
			/>
		</div>
	);
};

Popup.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default Popup;
