import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';

import BaseCard from '../../../../base';
import { useChildren } from '../../hooks';
import { getVersion } from '../../utils';
import withConfig from '../../withConfig';
import Postfix from '../components/postfix';
import Title from '../components/title';
import Trends from '../components/trends';

/**
 * @typedef { import('../../types').ChildrenProps } ChildrenProps
 */

/** @param { ChildrenProps } props  */
const ViewContent = React.memo(function ViewContent(props) {
	return (
		<Group direction="horizontal" verticalAlign="center">
			<Title {...props} />
			<Trends {...props} />
		</Group>
	);
});

const ViewContentConfig = withConfig(ViewContent, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.header?.default?.view, version);
});

/** @type { React.FC<ChildrenProps> } */
const DefaultView = React.memo(function DefaultView({
	children,
	onClose,
	...props
}) {
	const postfix = React.useMemo(
		() => ({ children: { after: <Postfix {...props} /> } }),
		[props],
	);

	const content = useChildren({ children, ...props }, () => (
		<ViewContentConfig {...props} />
	));

	return (
		<BaseCard.Header postfix={postfix} onClose={onClose}>
			{content}
		</BaseCard.Header>
	);
});

DefaultView.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	onClose: PropTypes.func,
};

export default withConfig(DefaultView, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.header?.default, version);
});

export { ViewContentConfig as ViewContent };
