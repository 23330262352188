import React from 'react';

/**
 * @template TComponent
 * @param { TComponent } Component
 * @param { (props: React.ComponentProps<TComponent>) => import('./types').ConfigValue<React.ComponentProps<TComponent>> } getter
 * @returns { TComponent }
 */
function withConfig(Component, getter) {
	const Wrapper = React.memo(function Wrapper(props) {
		const config = getter?.(props);

		if (config === false) {
			return null;
		}

		if (config) {
			if (typeof config === 'function') {
				return config({
					...props,
					DefaultComponent: (props) => <Component {...props} />,
				});
			}

			if (React.isValidElement(config)) {
				return config;
			}
		}

		return <Component {...props} />;
	});

	return Wrapper;
}

export default withConfig;
