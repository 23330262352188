const STATUSES = [
	'summary',
	'paid',
	'overdue',
	'unpaid',
	'unsent',
	'credit',
	'forecast',
	'forecast_match',
];

export { STATUSES };
