import React from 'react';

import PropTypes from 'prop-types';

import BasicCard from '../basic';

import { useCardState } from './hooks';
import OnboardingTitle from './v1/title';

import './styles.scss';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { Props['config'] } */
const CONFIG = {
	onboarding: { required: ['erp', 'bank'], optional: 'both' },
	card: {
		content: {
			title: (props) => <OnboardingTitle {...props} />,
		},
	},
};

/** @type { React.FC<Props> } */
const OnboardingCard = React.memo(function OnboardingCard(props) {
	return (
		<BasicCard
			{...props}
			type="onboarding"
			config={CONFIG}
			useCardState={useCardState}
		/>
	);
});

OnboardingCard.displayName = 'OnboardingCard';

OnboardingCard.propTypes = { className: PropTypes.string };

export default OnboardingCard;
