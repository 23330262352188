import React from 'react';

import PropTypes from 'prop-types';

import { getVersion } from '../../utils';
import withConfig from '../../withConfig';

import PinButton from './pin';
import RedirectButton from './redirect';

/**
 * @typedef { import('../../types').ChildrenProps } ChildrenProps
 */

/** @type { React.FC<ChildrenProps> } */
const View = React.memo(function Postfix(props) {
	return (
		<div className="flex gap-1 items-center">
			{/* <PinButton {...props} /> */}
			<RedirectButton {...props} />
		</div>
	);
});

View.propTypes = {
	type: PropTypes.string,

	startDate: PropTypes.string,
	endDate: PropTypes.string,
	translationOptions: PropTypes.object,

	onAction: PropTypes.func,
};

const Provider = withConfig(View, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.header?.postfix, version);
});

/** @type { React.FC<ChildrenProps> & { Pin: typeof PinButton, Redirect: typeof RedirectButton } } */
export default Object.assign(Provider, {
	Pin: PinButton,
	Redirect: RedirectButton,
});
