import React from 'react';

import PropTypes from 'prop-types';

import { ClientPerformanceContext } from '../../context';

import ChartView from './chart.view';

/**
 * @typedef { import('../../../basic/types').ChildrenProps } ChildrenProps
 *
 * @typedef Props
 * @property { 'CUSTOMER' | 'SUPPLIER' } clientType
 */

/** @type { React.FC<ChildrenProps & Props> } */
const ChartProvider = React.memo(function ChartProvider(props) {
	const { translationOptions, loading, importing, state } = props;

	const { active } = React.useContext(ClientPerformanceContext);

	const selected = React.useMemo(() => {
		const details =
			translationOptions?.data?.source?.statistics?.details ?? [];

		return details.slice(0, 5);
	}, [translationOptions?.data?.source?.statistics?.details]);

	const animated = loading || importing;
	const placeholder = animated || state?.promotion?.visible;

	return (
		<ChartView
			placeholder={placeholder}
			animated={animated}
			active={active?.value}
			values={selected}
		/>
	);
});

ChartProvider.propTypes = {
	translationOptions: PropTypes.object,
	loading: PropTypes.bool,
	importing: PropTypes.bool,
	state: PropTypes.object,
};

export default ChartProvider;
