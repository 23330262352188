import React from 'react';

import PropTypes from 'prop-types';

import BaseCard from '../../../../../base';
import { getVersion } from '../../../utils';
import withConfig from '../../../withConfig';

import FeedbackDismissForm from './step-dismiss';
import FeedbackRateForm from './step-rate';
import FeedbackSuccess from './step-success';

/**
 * @typedef { import('../../../types').ChildrenProps } ChildrenProps
 */

const STEPS = [
	{
		dismiss: (props) => <FeedbackDismissForm {...props} />,
		rate: (props) => <FeedbackRateForm {...props} />,
	},
	{
		dismiss: (props) => <FeedbackSuccess {...props} />,
		rate: (props) => <FeedbackSuccess {...props} />,
	},
];

/** @type { React.FC<ChildrenProps> } */
const ViewContent = React.memo(function ViewContent(props) {
	const { state } = props;

	const step = state?.feedback?.step ?? 0;
	const variant = state?.feedback?.variant;

	const Step = STEPS[step][variant];

	return <Step {...props} />;
});

ViewContent.propTypes = {
	state: PropTypes.object,
};

const ViewContentConfig = withConfig(ViewContent, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.content?.feedback?.view, version);
});

/** @type { React.FC<ChildrenProps> } */
const FeedbackView = React.memo(function FeedbackView(props) {
	return (
		<BaseCard.Content>
			<ViewContentConfig {...props} />
		</BaseCard.Content>
	);
});

export default withConfig(FeedbackView, (props) => {
	const { version } = props;

	return getVersion(props?.config?.card?.content?.feedback, version);
});

export { ViewContentConfig as ViewContent };
