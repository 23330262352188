import React from 'react';

import { addMonths, formatISO, startOfMonth, subMonths } from 'date-fns';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { cn } from '@asteria/utils-funcs/classes';
import { parseDate } from '@asteria/utils-funcs/normalize';

import { DateContext } from '../../context';
import Input from '../input';

/**
 * @typedef { import('./types').Props } Props
 */

/** @type { React.FC<Props> } */
const Wrapper = React.memo(function Wrapper(props) {
	const {
		className,

		date: initialDate = formatISO(startOfMonth(new Date()), {
			representation: 'date',
		}),

		onAction,
		onSubmit,
	} = props;

	const [{ value, startDate, endDate }, dispatch] = React.useReducer(
		(state, action) => {
			switch (action?.type) {
				case 'RESET':
					return {
						...state,
						value: initialDate,
						startDate: null,
						endDate: null,
					};

				case 'SET_VALUE':
					return { ...state, value: action?.payload };

				case 'SET_RANGE':
					return {
						...state,
						value: null,
						startDate: action?.payload?.startDate,
						endDate: action?.payload?.endDate,
					};

				case 'BACK': {
					if (state?.value) {
						const next = formatISO(
							subMonths(parseDate(state.value), 1),
							{ representation: 'date' },
						);

						onAction?.('time:selector:select', { date: next });

						return { ...state, value: next };
					}

					return state;
				}

				case 'NEXT': {
					if (state?.value) {
						const next = formatISO(
							addMonths(parseDate(state.value), 1),
							{ representation: 'date' },
						);

						onAction?.('time:selector:select', { date: next });

						return { ...state, value: next };
					}

					return state;
				}

				default:
					return state;
			}
		},
		{ value: initialDate, startDate: null, endDate: null },
	);

	const onValueReset = React.useCallback(() => {
		dispatch({ type: 'RESET' });
	}, []);

	const onValueUpdate = React.useCallback((payload) => {
		dispatch({ type: 'SET_VALUE', payload: payload });
	}, []);

	const onRangeUpdate = React.useCallback((payload) => {
		dispatch({ type: 'SET_RANGE', payload: payload });
	}, []);

	const back = React.useCallback(() => {
		dispatch({ type: 'BACK' });
	}, []);

	const next = React.useCallback(() => {
		dispatch({ type: 'NEXT' });
	}, []);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'time:selector:select') {
				onValueUpdate(data?.date);
			}

			if (action === 'time:selector:range') {
				onRangeUpdate?.(data);
			}

			if (action === 'time:selector:reset') {
				onValueReset?.(data);
			}

			return onAction?.(action, data);
		},
		[onAction, onRangeUpdate, onValueReset, onValueUpdate],
	);

	const ctx = React.useMemo(
		() => ({
			value: value,
			startDate: startDate,
			endDate: endDate,

			setValue: onValueUpdate,
			setRange: onRangeUpdate,
			reset: onValueReset,
		}),
		[endDate, onRangeUpdate, onValueReset, onValueUpdate, startDate, value],
	);

	return (
		<DateContext.Provider value={ctx}>
			<div
				className={cn(
					'flex items-center gap-4',
					'asteria-component__time-selector',
					className,
				)}
			>
				<Button icon="chevron-left" onClick={back} />
				<Input
					className="flex-1"
					onAction={handleAction}
					onSubmit={onSubmit}
				/>
				<Button icon="chevron-right" onClick={next} />
			</div>
		</DateContext.Provider>
	);
});

Wrapper.displayName = 'Wrapper';

Wrapper.propTypes = {
	className: PropTypes.string,
	date: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default Wrapper;
