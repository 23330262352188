import React from 'react';

import PropTypes from 'prop-types';

import { IntegrationsTeaser } from '@asteria/component-integrations-v2';

import { cn } from '@asteria/utils-funcs/classes';

import './../../styles.scss';

const SectionTeaser = React.memo((props) => {
	const { onAction, onSubmit } = props;

	return (
		<IntegrationsTeaser
			className={cn(
				'asteria-component__financial-page-section',
				'asteria--variant-teaser',
			)}
			onAction={onAction}
			onSubmit={onSubmit}
		/>
	);
});

SectionTeaser.displayName = 'SectionTeaser';
SectionTeaser.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default SectionTeaser;
