import React from 'react';

import PropTypes from 'prop-types';

import Icon from '@asteria/component-core/icon';
import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

/**
 * @typedef { import('../../../types').ChildrenOptions } ChildrenOptions
 * @typedef { import('../../../types').Props } Props
 */

/** @type { React.FC<Pick<ChildrenOptions, 'translationOptions'>> } */
const Status = React.memo(function Status({ translationOptions }) {
	const performance = translationOptions?.data?.performance?.state;

	return (
		<TooltipWrapper
			tooltip={
				<Translation
					translationKey="card.content.extra.tooltip"
					translationOptions={{
						...translationOptions,
						postfix: {
							...translationOptions?.postfix,
							'value-type': 'status',
						},
					}}
					Component={Text}
				/>
			}
		>
			{performance === 'WARNING' ? (
				<Icon icon={['alert', 'performance-warning']} />
			) : null}
			{performance === 'NEEDS_ATTENTION' ? (
				<Icon icon={['errorCircle', 'performance-needs-attention']} />
			) : null}
			{performance === 'NEUTRAL' ? (
				<Icon icon={['arrow-straight', 'performance-neutral']} />
			) : null}
			{performance === 'GOOD' ? (
				<Icon icon={['check', 'performance-good']} size="sm" />
			) : null}
		</TooltipWrapper>
	);
});

Status.propTypes = {
	translationOptions: PropTypes.object,
};

export default Status;
