import { formatISO, startOfMonth } from 'date-fns';

import { parseDate } from '@asteria/utils-funcs/normalize';

export function formatDate($date) {
	const date = parseDate($date);

	if (!date) {
		return null;
	}

	return formatISO(startOfMonth(date), { representation: 'date' });
}
